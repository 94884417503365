<template>
  <div>
    <GlanceSection />
    <div class="main-app" ref="mainApp">
      <sensor-view v-if="showSensorView" :sensorId="sensorId" :sensor="sensor"
        @handle-go-back="handleGoBack"></sensor-view>
      <map-view v-if="showMapView">
        <layoutButton :showGrid="showGridView" :showBackButton="showBackButton" @handle-show-grid="handleShowGrid"
          @handle-show-map="handleShowMap" @handle-go-back="handleGoBack"></layoutButton>
      </map-view>
      <grid-view :show-sensor-view="showSensorView" :show-grid-view="showGridView" :main-app-width="width"
        :grid-height="height">
        <sub-nav ref="subNav">
          <div class="nav-left">
            <group-filter></group-filter>
            <sensor-search></sensor-search>
            <chart-limit-selector></chart-limit-selector>
          </div>
          <layout-button :show-grid="showGridView" :show-back-button="showBackButton && !showSensorView"
            @handle-show-grid="handleShowGrid" @handle-show-map="handleShowMap" @handle-go-back="handleGoBack"
            v-if="showGridView"></layout-button>
        </sub-nav>
      </grid-view>
    </div>
  </div>
</template>

<script>
import Vue, { ref } from "vue";
import { mapStores } from "pinia";
import { useElementSize } from "@vueuse/core";

import { useGroupsStore, useLocationsStore, useBaseStore } from "../../js/store";

import subNav from "../subNav";
import mapView from "../mapView";
import gridView from "../gridView";
import sensorView from "../sensorView/";
import groupFilter from "../groupFilter";
import sensorSearch from "../sensorSearch";
import layoutButton from "../layoutButton";
import chartLimitSelector from "../chartLimitSelector";
import GlanceSection from "../glanceSection.vue";

import app from "../../js/app";

export default {
  name: "dashboard-page",
  setup() {
    const mainApp = ref(null);
    const subNav = ref(null);
    const { width, height } = useElementSize(mainApp);
    const { width: subNavWidth, height: subNavHeight } = useElementSize(subNav);
    return {
      mainApp,
      width,
      height,
      subNav,
      subNavWidth,
      subNavHeight,
    };
  },
  components: {
    subNav,
    mapView,
    gridView,
    sensorView,
    groupFilter,
    sensorSearch,
    layoutButton,
    chartLimitSelector,
    GlanceSection,
  },
  props: {
    sensorId: {
      type: String,
      default: null,
    },
    groupId: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      gridView: false,
      imageRefreshTimer: null,
      refreshTimer: null,
    };
  },
  methods: {
    handleGoBack() {
      this.$router.push({
        query: {
          ...this.$route.query,
          sensorId: undefined,
          gridView: this.gridView,
        },
      });
    },
    handleShowMap() {
      this.gridView = false;
      this.$router.push({
        query: {
          ...this.$route.query,
          sensorId: undefined,
          gridView: undefined,
        },
      });
    },
    handleShowGrid() {
      this.gridView = true;
      this.$router.push({
        query: {
          ...this.$route.query,
          sensorId: undefined,
          gridView: true,
        },
      });
    },
    createImageRefreshTimer() {
      clearInterval(this.imageRefreshTimer);
      this.imageRefreshTimer = setInterval(() => {
        this.locationsStore.getMostRecentTransmissions();
      }, 1000 * app.LATEST_IMAGE_REFRESH_INTERVAL_SECONDS);
    },
    createRefreshTimer() {
      clearInterval(this.refreshTimer);
      this.refreshTimer = setInterval(() => {
        this.locationsStore.getLocations(true);
        this.locationsStore.getDataTransmissions([], [this.sensorId]);
      }, 1000 * app.DASHBOARD_REFRESH_INTERVAL_SECONDS);
    },
  },
  computed: {
    ...mapStores(useGroupsStore, useLocationsStore, useBaseStore),
    sensor() {
      return this.locationsStore.devicesByIds[this.sensorId];
    },
    showMapView() {
      return !this.gridView && !this.showSensorView;
    },
    showGridView() {
      return this.gridView === true;
    },
    showSensorView() {
      return this.sensorId !== null;
    },
    showBackButton() {
      return this.showSensorView;
    },
    gridHeight() {
      let gridH = this.height + 20;
      if (this.width > 500) {
        gridH = this.height - this.subNavHeight;
      }
      if (this.showMapView && this.width < 500) {
        gridH = gridH - 300; // The height of the map
      }
      return parseInt(gridH);
    },
  },
  mounted() {
    this.groupsStore.selectGroupFromPreferences(this.$route.query.groupId);
    this.createImageRefreshTimer();
    this.createRefreshTimer();
    // On load, if we are mobile, change to grid view
    if (this.gridView !== true && window.innerWidth < 700) {
      this.gridView = true;
      this.$router
        .push({
          query: {
            ...this.$route.query,
            sensorId: this.sensorId,
            gridView: true,
          },
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.gridView =
        this.$route.query.gridView === "true" ||
        this.$route.query.gridView === true;
    }
  },
  beforeDestroy() {
    clearInterval(this.imageRefreshTimer);
    clearInterval(this.refreshTimer);
  },
  watch: {
    $route(to, from) {
      if (to.query.gridView !== undefined) {
        this.gridView =
          to.query.gridView === "true" || to.query.gridView === true;
      }
    },
    sensorId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.createRefreshTimer();
      }
    },
    // This is a hack because the group filter is not updating the query params on mobile
    groupId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$router
          .push({
            query: {
              ...this.$route.query,
              groupId: newVal,
              sensorId: undefined,
            },
          })
          .catch(() => {
            // Might be redundant, but just in case
          });
      }
    },
    width(newVal, oldVal) {
      if (this.gridView !== true && window.innerWidth < 700) {
        this.gridView = true;
        this.$router
          .push({
            query: {
              ...this.$route.query,
              sensorId: this.sensorId,
              gridView: true,
            },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
