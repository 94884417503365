<template>
  <div>
    <h3 class="dm-margin-left-sm text-lg">Historical</h3>
    <sensor-history-bar :device-id="deviceId"></sensor-history-bar>
    <sensor-history-charts :history="history"></sensor-history-charts>
    <sensor-history-table :history="history" :road-conditions-history="roadConditionsHistory"
      :temperature-unit="temperatureUnit"></sensor-history-table>
    <sensor-history-photos-table :device-id="deviceId"
      :temperature-unit="temperatureUnit"></sensor-history-photos-table>
    <sensor-requests-table :requests="requests" :device-id="deviceId"></sensor-requests-table>
  </div>
</template>

<script>
import sensorHistoryBar from './sensorHistoryBar';
import sensorHistoryCharts from './sensorHistoryCharts';
import sensorHistoryTable from './sensorHistoryTable';
import sensorRequestsTable from './sensorRequestsTable';
import sensorHistoryPhotosTable from './sensorHistoryPhotosTable';

export default {
  name: 'sensors-history-section',
  props: [
    "deviceId",
    "history",
    "requests",
    'roadConditionsHistory',
    "temperatureUnit",
  ],
  components: {
    sensorHistoryBar,
    sensorHistoryCharts,
    sensorHistoryTable,
    sensorRequestsTable,
    sensorHistoryPhotosTable,
  },
  data: function () {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style>
.e-btn-icon.e-image {
  background: url(../../images/svg/export-photo.svg);
  background-repeat: repeat-y;
  width: 10px;
  height: 10px;
}

.export-style.e-dropdown-popup {
  border: 1px solid #EFF2F5;
  box-shadow: 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.history-tabs {
  background: none !important;
}

.export-style.e-dropdown-popup ul {
  min-width: 57px !important;
}

.export-style li.e-menu-item {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6D6E71;
}

.export-style li.e-menu-item.e-selected {
  background: #EFF2F5 !important;
}

.custom-date .e-float-input.e-control-wrapper {
  background: #FFF !important;
}
</style>