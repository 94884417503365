import { useBaseStore } from '../../src/js/store'

export default {
  utils: {
    requests: 0,
    http(config, hideLoader, hideAlert) {
      const store = useBaseStore()
      const appServer = process.env.VUE_APP_API_ROOT

      if (!hideLoader) {
        DM.addRequest()
      }

      if (config.url) {
        config.url = appServer + config.url
      } else {
        config.url = appServer
      }

      if (store.authToken !== null) {
        config.headers = config.headers || {}
        config.headers.Authorization = 'Bearer ' + store.authToken
      }

      return window
        .axios(config)
        .then(
          (response) => {
            return response.data
          },
          (data) => DM.handleServerError(data, hideAlert)
        )
        .finally(() => {
          if (!hideLoader) {
            DM.removeRequest()
          }
        })
    },
    sendErrorToDelMarMessaging(data) {
      return window
        .axios({
          method: 'POST',
          url: process.env.VUE_APP_API_ERROR_URL,
          data: data
        })
        .then((response) => {
          return response.data
        })
    },
    handleServerError(data, hide) {
      if (!window.navigator.onLine) {
        // if browser is offline, do not show an error message
        return Promise.reject()
      }

      let response = data.response
      let unexpectedErrorTitle = 'System Error:'
      let unexpectedErrorMessage =
        'An unexpected error has occurred on the server.'

      if (response) {
        if (response.status >= 400 && response.status < 500) {
          let errorString = ''

          if (hide && response.status !== 401) {
            return Promise.resolve(response.data)
          }

          if (response.config.responseType === 'arraybuffer') {
            try {
              response.data = JSON.parse(
                String.fromCharCode.apply(null, new Uint8Array(response.data))
              )
            } catch (err) {
              DM.alertShow(unexpectedErrorMessage, unexpectedErrorTitle)
            }
          }

          const store = useBaseStore()
          if (response.status === 401 && store.authToken) {
            eventBus.$emit('resetUserToSignIn')
            return Promise.reject(response)
          }

          // errorString = response.data.Message;
          errorString = response.data.message
          DM.alertShow(errorString, 'Error')
        } else {
          if (!hide) {
            DM.alertShow(unexpectedErrorMessage, unexpectedErrorTitle)
          }
        }
      } else {
        if (!hide) {
          DM.alertShow(unexpectedErrorMessage, unexpectedErrorTitle)
        }
      }
      return Promise.reject(response)
    },

    confirmShow(message, title, confirm, deny, confirmText, closeText) {
      eventBus.$emit('showAppModal', this, 'dmConfirmModal', {
        title: title,
        message: message,
        confirm: confirm,
        deny: deny,
        confirmText: confirmText,
        closeText: closeText
      })
    },

    alertShow(message, title, callback) {
      eventBus.$emit('showAppModal', this, 'dmAlertModal', {
        title: title,
        message: message,
        callback: callback
      })
    },

    addRequest() {
      this.requests++
      this.spinnerToggleForRequest(true)
    },

    removeRequest() {
      this.requests--
      this.spinnerToggleForRequest(false)
    },

    spinnerToggleForRequest(show) {
      if (show && this.requests === 1) {
        this.spinnerShow()
      }

      if (this.requests === 0) {
        this.spinnerHide()
      }
    },

    spinnerShow() {
      let loading = document.createElement('div')
      loading.id = 'dm-spinner-active'
      loading.innerHTML = '<div class="flex-column"><div></div>Loading...</div>'
      loading.classList.add('dm-spinner')
      loading.style.display = 'flex'
      document.querySelector('body').appendChild(loading)
    },

    spinnerHide() {
      document.getElementById('dm-spinner-active').remove()
    },

    localStorageSet: function (key, value) {
      let type = typeof value
      value =
        type === 'Object' || type === 'Array' ? JSON.stringify(value) : value
      localStorage.setItem(key, value)
    },

    localStorageGet: function (key) {
      return localStorage.getItem(key) || null
    },

    localStorageRemove: function (key) {
      localStorage.removeItem(key)
    },

    getCboOptions(options, value, text, noSort) {
      if (value) {
        options = options.map((r) => {
          return { text: r[text], value: r[value] }
        })
      } else {
        options = options.map((r) => {
          return { text: r, value: r }
        })
      }

      if (noSort) {
        return options
      } else {
        return this.sortObjectArray(options, 'text')
      }
    },

    sortObjectArray(array, property) {
      let compare = (a, b) => {
        if (a[property] < b[property]) return -1
        if (a[property] > b[property]) return 1
        return 0
      }

      return array.sort(compare)
    },

    sortArray(array) {
      let compare = (a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
      }

      return array.sort(compare)
    },

    getRandomGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          let r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    },

    objectAreNotEqual(obj1, obj2, props) {
      let isNotEqual = false

      props.forEach((p) => {
        if (JSON.stringify(obj1[p]) !== JSON.stringify(obj2[p])) {
          // console.log(p);
          // console.log(JSON.stringify(obj1[p]), JSON.stringify(obj2[p]));
          isNotEqual = true
        }
      })
      return isNotEqual
    },

    checkForChanges(scope, propsToCheck, callback) {
      if (DM.objectAreNotEqual(scope.savedInitialData, scope, propsToCheck)) {
        this.confirmClose(callback)
      } else {
        callback()
      }
    },

    confirmClose(closeCallback) {
      this.confirmShow(
        'You have unsaved changes. Are you sure you want to close?',
        'Confirm',
        closeCallback
      )
    },

    objectToQueryString(params) {
      return Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&')
    },

    downloadAjaxResponseAsFile: function (blob, fileName) {
      //let blob = new Blob(response.FileData, { type: response.ContentType });
      let objectUrl = URL.createObjectURL(blob)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.href = objectUrl
      a.download = fileName
      a.click()
      document.body.removeChild(a)
    },

    copy(data) {
      return JSON.parse(JSON.stringify(data))
    },

    round(number, decimalPlaces) {
      let precision = 10
      for (let x = precision; x >= decimalPlaces; x--) {
        number = number.toFixed(x)
        number = parseFloat(number)
      }
      return number
    },

    displayNumber(number, decimalPlaces) {
      let roundedNumber = this.round(number, decimalPlaces)
      return roundedNumber.toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      })
    },

    formatDateDisplay(dateString) {
      if (!dateString) {
        return null
      }
      return moment.utc(dateString).format('M/D/YYYY')
    },

    formatTimeDisplay(dateString) {
      if (!dateString) {
        return null
      }
      return moment.utc(dateString).format('h:mma')
    },

    formatDateInput(dateString) {
      if (!dateString) {
        return null
      }
      return moment.utc(dateString).format('YYYY-MM-DD')
    },

    formatTimeInput(dateString) {
      if (!dateString) {
        return null
      }
      return moment.utc(dateString).format('HH:mm')
    },

    getCurrentYear() {
      return moment().year()
    },

    isNumTruthy(number) {
      return number !== undefined && number !== '' && !isNaN(parseFloat(number))
    },

    addSearchIconDropDown: function (dropdownRef) {
      const dropObj = dropdownRef.ej2Instances
      const inputElement = dropObj.popupObj.element.querySelector(
        '.e-filter-parent .e-input-filter'
      )
      const iconElement = dropObj.popupObj.element.querySelector(
        '.e-filter-parent .e-input-group-icon'
      )
      iconElement.classList.add('e-search')
      iconElement.style.visibility = 'visible'

      iconElement.addEventListener('click', function () {
        iconElement.classList.add('e-search')
        iconElement.style.visibility = 'visible'
      })

      inputElement.addEventListener('input', function () {
        iconElement.classList.remove('e-search')
      })
    }
  }
}
