<template>
  <div class="drop-down-filter">
    <div class="group-drop-down-filter">
      <div class="group-drop-down-label">Group</div>
      <v-select id="group-filter" class="rounded" :clearable="false" :components="{ OpenIndicator }"
        :options="groupsStore.groupOptions" :value="groupsStore.selectedGroupOption" :filter="handleFuzzySearch"
        @input="handleChangeGroup" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapStores } from "pinia";

import { useGroupsStore } from "../js/store";

export default {
  name: 'group-filter',
  data: () => {
    return {
      // replace the dropdown svg icon with an empty span element
      OpenIndicator: {
        render: (createElement) => createElement('span'),
      },
    }
  },
  methods: {
    handleFuzzySearch(options, search) {
      const results = this.groupsStore.searchIndex.search(search);
      return results.map((result) => result.item);
    },
    handleChangeGroup(option) {
      const groupId = option.id;
      if (window.innerWidth < 850) {
        this.$router.push({ query: { groupId, gridView: true } });
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            groupId,
            sensorId: undefined,
          }
        });
      }
      this.groupsStore.selectGroup(groupId);

      // Note: make sure the input field isn't focused after selecting a group (only required if using v-select)
      setTimeout(() => {
        if (document.activeElement) {
          document.activeElement?.blur();
        }
      });
    }
  },
  computed: {
    ...mapStores(useGroupsStore),
  }
}
</script>
