<template>
    <portal to="modals">
        <div v-if="isOpen" class="modal-overlay" @click.self="close">
            <div class="modal-content">
                <div class="flex-row space-between">
                    <h2 class="inlne-flex m-0">{{ title }}</h2>
                    <button class="modal-close" @click="close" aria-label="close modal"><i
                            class="fa fa-close color-gray"></i></button>
                </div>
                <slot></slot>
            </div>
        </div>
    </portal>
</template>

<script setup>
import { computed } from 'vue';
import { useModalStore } from '../../js/store';

const modalStore = useModalStore();

const isOpen = computed(() => modalStore.activeModal !== null);
const close = () => modalStore.closeModal();
const title = computed(() => modalStore.modalProps.title);

</script>
