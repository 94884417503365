<template>
    <div class="flex-col" ref="sensorWindow">
        <div class="dm-flex-row dm-padding-md sensor-header" :class="{ 'sensor-header-stacked': stacked }">
            <div class="dm-flex-column dm-padding-right-md sensor-meta-and-tools">
                <div class="desktop-sensor-tools">
                    <sensor-tools :sensor-id="sensorId" :status="status" @handle-status-update="handleStatusUpdate"
                        :user-has-full-access="userHasFullAccess"
                        :wrapper-classes="{ 'dm-flex-column': true }"></sensor-tools>
                </div>
            </div>
            <carousel :image-transmissions="imageTransmissions" :temperature-unit="temperatureUnit"
                :sensor-id="sensorId" :is-metric="isMetric" :hide-readings="true">
            </carousel>
        </div>
        <sensor-history-bar :device-id="sensorId"></sensor-history-bar>
        <frost-camera-history-table :device-id="sensorId"
            :temperature-unit="temperatureUnit"></frost-camera-history-table>
        <sensor-requests-table :requests="requests" :temperature-unit="temperatureUnit"></sensor-requests-table>
    </div>
</template>

<script>
import Vue, { ref } from 'vue';
import moment from 'moment-timezone';
import { mapStores } from 'pinia';
import { useElementSize } from '@vueuse/core';

import { useBaseStore, useForecastsStore, useGroupsStore, useHistoryStore, useLocationsStore } from '../../js/store';
import app from '../../js/app';

import sensorTools from './sensorTools';
import carousel from './carousel';
import forecastSection from './forecastSection'
import sensorChartComponent from '../sensorChartComponent.vue';
import frostCameraHistoryTable from './frostCameraHistoryTable.vue';
import sensorRequestsTable from './sensorRequestsTable.vue';
import sensorHistoryBar from './sensorHistoryBar.vue';

const DEFAULT_STATUS = {
    defrost: false,
    work: false,
    photo: false
}

export default {
    name: 'rwis-sensor-view',
    props: ['sensorId'],
    components: {
        sensorTools,
        carousel,
        sensorChartComponent,
        frostCameraHistoryTable,
        sensorRequestsTable,
        sensorHistoryBar,
    },
    setup: () => {
        const sensorWindow = ref(null);
        const { width, height } = useElementSize(sensorWindow);
        return {
            width,
            height,
            sensorWindow,
        };
    },
    data: function () {
        return {
            stacked: false,
            refreshTimer: null,
            status: { ...DEFAULT_STATUS },
        };
    },
    methods: {
        async getSensorById() {
            // If we don't have a sensor ID, we can't do anything, redirect to dashboard
            if (!this.sensorId) {
                this.$router.push({
                    name: 'sensors',
                    query: {
                        ...this.$route.query,
                        sensorId: undefined
                    }
                });
                return;
            }
            if (this.groupsStore.groups.length === 0 || !this.groupsStore.selectedGroup) {
                const groupId = this.$route.query.groupId;
                await this.groupsStore.selectGroupFromPreferences(groupId);
            }
            // Get the image transmissions for the sensor
            await this.locationsStore.getImageTransmissions(this.sensorId);
            // We should retrieve images if we have none
            // Current images gets images for all sensors in the group
            if (!this.locationsStore.mostRecent[this.sensorId]) {
                await this.locationsStore.getMostRecentTransmissions();
            }
            // Once we have a way to include images in the data transmissions
            await this.locationsStore.getDataTransmissions([this.sensorId]);

            await this.getHistory();
            await this.getPhotos();
            await this.getRequests();
        },
        async getHistory() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getHistory(this.sensorId);
        },
        async getPhotos() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getPhotosMetadata(this.sensorId);
            await this.historyStore.getPhotosPage(this.sensorId);
        },
        async getRequests() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getRequests(this.sensorId);
        },

        handleStatusUpdate(key, status) {
            this.status[key] = status;
        },
    },
    computed: {
        ...mapStores(useBaseStore, useGroupsStore, useLocationsStore, useHistoryStore,),
        userHasFullAccess() {
            return true; // Add logic to determine user access
        },
        sensor() {
            const found = this.locationsStore.filteredLocations.find(l => {
                return parseInt(l.ID, 10) === parseInt(this.sensorId, 10)
            });
            if (!found) {
                return {};
            }
            return found
        },
        isMetric() {
            return this.sensor?.GroupEmbedded?.IsMetric ? true : false;
        },
        temperatureUnit() {
            return this.sensor?.GroupEmbedded?.TemperatureUnits === "Fahrenheit" ? "°F" : "°C";
        },
        dataTransmissions() {
            // These should already be in the correct order
            return this.locationsStore.dataTransmissions[this.sensorId] || [];
        },
        mostRecent() {
            return this.locationsStore.mostRecentTransmissions[this.sensorId] || {};
        },
        mostRecentTransmission() {
            return this.mostRecent.MostRecentDataTransmission[this.sensorId] || {};
        },
        snowDepth() {
            const mostRecentSnowDepth = this.mostRecent?.MostRecentSnowDepthReading;
            if (!mostRecentSnowDepth || Object.keys(mostRecentSnowDepth).length === 0) {
                return null;
            }
            const sd = this.isMetric ? mostRecentSnowDepth?.MeasuredHeightMm + ' mm' : mostRecentSnowDepth?.MeasuredHeightInches + ' in';
            const timeSinceRead = moment(mostRecentSnowDepth?.TransmissionDateTimeUTC).fromNow();
            return {
                snowDepthText: sd,
                timeSinceRead,
            }
        },
        imageTransmissions() {
            // Server is returning some images that are not near transmission datetime
            const images = this.locationsStore.imageTransmissions[parseInt(this.sensorId, 10)] || [];
            return images.map(i => {
                return {
                    ...i.TransmissionData,
                    ComputerVision: i.ComputerVision,
                    chartDate: i.chartDate,
                    TransmissionDateTimeUTC: i.TransmissionDateTimeUTC,
                }
            });
        },
        history() {
            return {
                transmissions: this.historyStore.transmissions[this.sensorId] || [],
                roadConditions: this.historyStore.roadConditionsHistory[this.sensorId] || []
            };
        },
        requests() {
            return this.historyStore.requests[this.sensorId] || [];
        },
    },
    watch: {
        sensorId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.getSensorById();
                this.transmissionIndex = 0;
                this.status = { ...DEFAULT_STATUS };
            }
        },
        width(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.stacked = newValue < 700;
            }
        },
        'historyStore.end'() {
            this.getPhotos();
        },
    },
    mounted() {
        this.getSensorById();
        this.refreshTimer = setInterval(() => {
            this.getSensorById();
        }, 1000 * app.DASHBOARD_REFRESH_INTERVAL_SECONDS);

    },
    beforeDestroy() {
        clearInterval(this.refreshTimer);
    }
};
</script>