<template>
	<div class="image-fullscreen" :class="{ expanded }" @click="expanded = true">
		<i v-if="expanded" class="close-button">
			<svg style="width:24px;height:24px" viewBox="0 0 24 24">
				<path
					fill="#666666"
					d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
				/>
			</svg>
		</i>
		<i v-else class="expand-button">
			<svg style="width:24px;height:24px" viewBox="0 0 24 24">
				<path
					fill="#000000"
					d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z"
				/>
			</svg>
		</i>
		<img v-bind="$attrs" />
	</div>
</template>

<script>
export default {
	name: "image-fullscreen",
	data: function() {
		return {
			expanded: false,
		};
	},
	methods: {
		closeImage(event) {
			this.expanded = false;
			event.stopPropagation();
		},
        handleClickOutside(evt) {
            if (evt.target.classList.contains("expanded")) {
                this.closeImage(evt);
            }
        },
        handleEscKeypress(evt) {
            if (evt.keyCode === 27) {
                this.closeImage(evt);
            }
        },
	},
	watch: {
		expanded(expanded) {
			this.$nextTick(() => {
				if (expanded) {
					this.cloned = this.$el.cloneNode(true);
					this.closeButtonRef = this.cloned.querySelector(".close-button");
					this.closeButtonRef.addEventListener("click", this.closeImage);
					document.body.appendChild(this.cloned);
					document.body.style.overflow = "hidden";

                    document.body.addEventListener("click", this.handleClickOutside);
                    document.body.addEventListener("keydown", this.handleEscKeypress);
                    
					setTimeout(() => {
						this.cloned.style.opacity = 1;
					}, 0);
				} else {
					this.cloned.style.opacity = 0;
					setTimeout(() => {
                        document.body.removeEventListener("click", this.handleClickOutside);
                        document.body.removeEventListener("keydown", this.handleEscKeypress);
						this.closeButtonRef.removeEventListener("click", this.closeImage);
						this.cloned.remove();
						this.cloned = null;
						this.closeButtonRef = null;
						document.body.style.overflow = "auto";
					}, 250);
				}
			});
		},
	},
};
</script>

<style>
.image-fullscreen {
	position: relative;
	transition: 0.25s opacity;
	cursor: zoom-in;
}

body > .image-fullscreen.expanded {
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	display: flex;
	align-items: center;
	opacity: 0;
	padding-bottom: 0 !important;
	cursor: default;
}

body > .image-fullscreen.expanded > img {
	width: 100%;
	max-width: 1200px;
	max-height: 100%;
	object-fit: contain;
	margin: 0 auto;
    border-radius: 5px;
}

body > .image-fullscreen.expanded > .close-button {
	display: block;
}

.image-fullscreen .close-button {
	position: fixed;
	top: 10px;
	right: 10px;
	display: none;
	cursor: pointer;
}

.image-fullscreen svg {
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}

.image-fullscreen svg path {
	fill: #fff;
}

.image-fullscreen .expand-button {
	position: absolute;
	z-index: 99;
	right: 50%;
	top: 50%;
	padding: 0px;
	align-items: center;
	justify-content: center;
	padding: 3px;
	opacity: 0;
	transition: 0.2s opacity;
	transform: translate(50%, -50%);
}

.image-fullscreen:hover .expand-button {
	opacity: 1;
}

.image-fullscreen .expand-button svg {
	width: 20px;
	height: 20px;
}

.image-fullscreen .expand-button path {
	fill: #fff;
}

.image-fullscreen .image-fullscreen img {
	width: 100%;
    border-radius: 5px;
}
</style>
