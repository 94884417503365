import moment from "moment";
import * as Yup from "yup";

const emptyObjectSchema = Yup.object().shape({}).nullable(true);

export const isoTransform = (dateTime, origValue) => {
  // We can't use the dateTime variable directly because it's already transformed by Yup
  return moment(origValue, true).utc(true).tz("GMT").toDate();
};

const allowEmptyOrSchema = (schema) =>
  Yup.lazy((value) => {
    if (
      (typeof value === "object" && value && Object.keys(value).length === 0) ||
      value === null ||
      value === undefined
    ) {
      return emptyObjectSchema; // Allow empty object
    }
    return schema; // Validate using the provided schema if not empty
  });

export const transmissionSchema = Yup.object({
  TransmissionDateTimeUTC: Yup.date().required().transform(isoTransform),
  ModelVersion: Yup.string().nullable(),
  // LocationID: Yup.number().nullable(),
  TransmissionType: Yup.number().nullable(),
  GroupID: Yup.string().nullable(),
});

export const weatherSchema = Yup.object({
  SurfaceTemp: Yup.number().required(),
  AirTemp: Yup.number().required(),
  DewPoint: Yup.number().required(),
  Humidity: Yup.number().required(),
});

export const computerVisionSchema = Yup.object({
  Night: Yup.number().nullable(),
  Sunny: Yup.number().nullable(),
  Cloudy: Yup.number().nullable(),
  ClearPavement: Yup.number().nullable(),
  WetPavement: Yup.number().nullable(),
  SnowOnRoad: Yup.number().nullable(),
  PartialSnowOnRoad: Yup.number().nullable(),
  Snowing: Yup.number().nullable(),
  Raining: Yup.number().nullable(),
  NightSnowing: Yup.number().nullable(),
  DaySnowing: Yup.number().nullable(),
  IcedLens: Yup.number().nullable(),
});

export const imageSchema = Yup.object({
  TransmissionDateTimeUTC: Yup.date().nullable().transform(isoTransform),
  ImageCaptureDateTimeUTC: Yup.string().nullable(),
  ProcessedCameraImageURL: Yup.string().nullable(),
  // ImageUrlUpscale: Yup.string().nullable(),
  DeviceImageID: Yup.number().nullable(),
});

export const snowDepthReadingSchema = Yup.object({
  DistanceMm: Yup.number().nullable(),
  BatteryMv: Yup.number().nullable(),
  RssiPower: Yup.number().nullable(),
  ReferenceDepthMm: Yup.number().required(),
  Reserved1: Yup.number().nullable(),
  Reserved2: Yup.number().nullable(),
  Error: Yup.number().nullable(),
  TransmissionDateTimeUTC: Yup.date().nullable().transform(isoTransform),
  LocationID: Yup.number().nullable(),
  UploadedByRWIS: Yup.number().nullable(),
});

export const weatherReadingSchema = transmissionSchema
  .concat(weatherSchema)
  .concat(imageSchema);
export const mostRecentComputerVisionSchema = transmissionSchema
  .concat(computerVisionSchema)
  .concat(imageSchema);

// Schema for most recent data transmission and computer vision readings
export const mostRecentDataSchema = Yup.object({
  MostRecentDataTransmission: allowEmptyOrSchema(weatherReadingSchema),
  MostRecentComputerVision: allowEmptyOrSchema(mostRecentComputerVisionSchema),
  MostRecentSnowDepthReading: allowEmptyOrSchema(snowDepthReadingSchema),
});

// Schema for transmission data
export const dataTransmissionItemSchema = Yup.object({
  SnowDepthReading: allowEmptyOrSchema(snowDepthReadingSchema),
  ComputerVision: allowEmptyOrSchema(computerVisionSchema),
  TransmissionData: allowEmptyOrSchema(weatherReadingSchema),
  TransmissionDateTimeUTC: Yup.date().required().transform(isoTransform),
});

export const forecastPrecipSummary = Yup.object({
  ForecastHours: Yup.number().required(),
  IsMetric: Yup.boolean().required(),
  // LocationID: Yup.number().required(),
  PrecipRate: Yup.number().required(),
  PrecipType: Yup.number().required(),
  SnowRate: Yup.number().required(),
});

// Schema for forecast data
export const forecastDataSchema = transmissionSchema
  .concat(weatherSchema)
  .shape({
    TransmissionDateTimeUTC: Yup.date().nullable().transform(isoTransform), // Optional
    TransmissionDateTimeLocal: Yup.date().nullable().transform(isoTransform), // Included in road conditions
    ID: Yup.number().required(),
    ForecastDateTimeUTC: Yup.date().nullable().transform(isoTransform),
    SurfaceGrip: Yup.number().nullable(),
    RoadCondition: Yup.number().nullable(),
    SnowRate: Yup.number().nullable(),
    PrecipType: Yup.number().nullable(),
    PrecipRate: Yup.number().nullable(),
    RainRate: Yup.number().nullable(),
    MixedRate: Yup.number().nullable(),
    ForecastMinutes: Yup.number().nullable(),
    // Change: Yup.string().nullable(),
    WindDirection: Yup.number().nullable(),
    WindSpeed: Yup.number().nullable(),
    CloudCover: Yup.number().nullable(),
    IsMetric: Yup.boolean().nullable(),
    IsForecast: Yup.boolean().nullable(),
  });

export const forecastItemSchema = Yup.object({
  CurrentCondition: allowEmptyOrSchema(forecastDataSchema),
  CurrentWeather: Yup.number().nullable(),
  ForecastData: Yup.array().of(forecastDataSchema),
  ForecastPrecipSummary: allowEmptyOrSchema(forecastPrecipSummary),
  ForecastWeather: Yup.number().nullable(),
  ID: Yup.number().required(),
  RoadCondition: allowEmptyOrSchema(forecastDataSchema),
  RoadConditionsHistory: Yup.array().of(forecastDataSchema),
  WeatherCondition: allowEmptyOrSchema(forecastDataSchema),
});

export const dataTransmissionsSchema = Yup.array().of(
  dataTransmissionItemSchema
);
