<template>
    <div class="dm-flex-row sensor-wrapper">Test</div>
</template>

<script>
import Vue, { ref } from 'vue';
import moment from 'moment-timezone';
import { mapStores } from 'pinia';
import { useElementSize } from '@vueuse/core';

import { useBaseStore, useForecastsStore, useGroupsStore, useHistoryStore, useLocationsStore } from '../../js/store';
import app from '../../js/app';
import { convertWindSpeed, formatWindDirection, parseWindSpeedUnit, isTabletScreen } from '../../js/utils';

import sensorTools from './sensorTools';
import sensorDetails from '../sensorDetails';
import sensorHistorySection from './sensorHistorySection';
import fallbackImage from '../fallbackImageComponent';
import ForecastTiles from "../forecastTiles";
import imageWithOverlay from '../imageWithOverlay';
import forecastIcons from '../forecastIcons';
import carousel from './carousel';

const DEFAULT_STATUS = {
    defrost: false,
    event: false,
    work: false,
    photo: false
}

export default {
    name: 'sensors-view',
    props: ['sensorId'],
    emits: [
        'handle-go-back'
        ,],
    components: {
        sensorTools,
        sensorDetails,
        sensorHistorySection,
        fallbackImage,
        ForecastTiles,
        imageWithOverlay,
        forecastIcons,
        carousel,
    },
    setup: () => {
        const sensorWindow = ref(null);
        const { width, height } = useElementSize(sensorWindow);
        return {
            width,
            height,
            sensorWindow,
        };
    },
    data: function () {
        return {
            stacked: false,
            refreshTimer: null,
            status: { ...DEFAULT_STATUS },
        };
    },
    methods: {
        convertWindSpeed,
        formatWindDirection,
        parseWindSpeedUnit,

        async getSensorById() {
            // If we don't have a sensor ID, we can't do anything, redirect to dashboard
            if (!this.sensorId) {
                this.$router.push({
                    name: 'sensors',
                    query: {
                        ...this.$route.query,
                        sensorId: undefined
                    }
                });
                return;
            }
            if (this.groupsStore.groups.length === 0 || !this.groupsStore.selectedGroup) {
                const groupId = this.$route.query.groupId;
                await this.groupsStore.selectGroupFromPreferences(groupId);
            }
            // Get the image transmissions for the sensor
            await this.locationsStore.getImageTransmissions(this.sensorId);
            // We should retrieve images if we have none
            // Current images gets images for all sensors in the group
            if (!this.locationsStore.mostRecent[this.sensorId]) {
                await this.locationsStore.getMostRecentTransmissions();
            }
            // Once we have a way to include images in the data transmissions
            await this.locationsStore.getDataTransmissions([this.sensorId]);
            // We should also retrieve forecasts either way so they are fresh
            await this.forecastsStore.getForecasts([this.sensorId]);

            await this.getHistory();
            await this.getPhotos();
            await this.getRequests();
        },
        async getHistory() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getHistory(this.sensorId);
        },
        async getRequests() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getRequests(this.sensorId);
        },

        handleStatusUpdate(key, status) {
            this.status[key] = status;
        },
    },
    computed: {
        ...mapStores(useBaseStore, useGroupsStore, useLocationsStore, useHistoryStore, useForecastsStore),
        userHasFullAccess() {
            return true; // Add logic to determine user access
        },
        sensor() {
            const found = this.locationsStore.filteredLocations.find(l => {
                return parseInt(l.ID, 10) === parseInt(this.sensorId, 10)
            });
            if (!found) {
                return {};
            }
            return found
        },
        isMetric() {
            return this.sensor?.GroupEmbedded?.IsMetric ? true : false;
        },
        temparatureUnit() {
            return this.sensor?.GroupEmbedded?.TemperatureUnits === "Fahrenheit" ? "°F" : "°C";
        },
        dataTransmissions() {
            // These should already be in the correct order
            return this.locationsStore.dataTransmissions[this.sensorId] || [];
        },
        mostRecentTransmission() {
            return this.locationsStore.mostRecentTransmissions[this.sensorId] || {};
        },
        forecasts() {
            return this.forecastsStore.forecasts[this.sensorId] || {};
        },
        imageTransmissions() {
            // Server is returning some images that are not near transmission datetime
            const iT = this.locationsStore.imageTransmissions[this.sensorId] || [];
            return iT.filter(r => {
                if (r.ImageCaptureDateTimeUTC && r.TransmissionDateTimeUTC) {
                    // Check to make sure there isn't more than an hour between them
                    const imageDate = moment.utc(r.ImageCaptureDateTimeUTC)
                    const transmissionDate = moment.utc(r.TransmissionDateTimeUTC)
                    const diff = imageDate.diff(transmissionDate, 'minutes')
                    if (diff > 60) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            })
        },
        history() {
            return {
                transmissions: this.historyStore.transmissions[this.sensorId] || [],
                roadConditions: this.historyStore.roadConditionsHistory[this.sensorId] || []
            };
        },
        requests() {
            return this.historyStore.requests[this.sensorId] || [];
        },
        isTablet() {
            return isTabletScreen(true)
        },
    },
    watch: {
        sensorId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.getSensorById();
                this.transmissionIndex = 0;
                this.status = { ...DEFAULT_STATUS };
            }
        },
        width(newValue) {
            this.stacked = newValue < 700;
        },
    },
    mounted() {
        this.getSensorById();
        this.refreshTimer = setInterval(() => {
            this.getSensorById();
        }, 1000 * app.DASHBOARD_REFRESH_INTERVAL_SECONDS);

    },
    beforeDestroy() {
        clearInterval(this.refreshTimer);
    }
};
</script>