// Need to be cancellable
// The catch for when the call is cancelled should swallow the cancellation error
// and not show the alert
// Any grouped together calls should use the same cancel controller
// Loading should be handled differently than we are currently doing it
// I want to be able to start showing a spinner and only hide it on successful load or error
// All calls should return promises

export default class API {
  getUserById(userId, cancelSignal) {
    return DM.http({
      method: "GET",
      url: `/users/${userId}`,
      signal: cancelSignal,
    });
  }
  getGroups(cancelSignal) {
    return DM.http({
      method: "GET",
      url: "/groups",
      signal: cancelSignal,
    });
  }
  // Locations
  getLocation(deviceId, params, cancelSignal) {
    return DM.http(
      {
        method: "GET",
        url: `/locations/${deviceId}`,
        params,
        signal: cancelSignal,
      },
      true,
      true
    );
  }

  getLocations(
    searchText,
    selectedGroupId,
    isRefresh,
    hideAlert,
    cancelSignal
  ) {
    return DM.http(
      {
        method: "GET",
        url: `/locations`,
        signal: cancelSignal,
        params: {
          search: searchText,
          groupId: selectedGroupId,
          includeTransmissions: false,
          includeForecasts: false,
        },
      },
      isRefresh,
      hideAlert
    );
  }

  getPhotosMetadata(deviceId, params, cancelSignal) {
    return DM.http(
      {
        method: "GET",
        url: `/locations/${deviceId}/dataTransmissions/metadata`,
        params,
        signal: cancelSignal,
      },
      true,
      true
    );
  }

  getPhotos(deviceId, params, cancelSignal) {
    // params example:
    // start=2024-03-10T19%3A12%3A59.203Z
    // end=2024-03-11T19%3A12%3A59.203Z
    // limit=10
    // offset=0
    return DM.http(
      {
        method: "GET",
        url: `/locations/${deviceId}/dataTransmissions/imageIncluded`,
        params,
        signal: cancelSignal,
      },
      true,
      true
    );
  }

  getMostRecent(deviceIds, cancelSignal) {
    return DM.http(
      {
        method: "GET",
        url: `/locations/dataTransmissions/mostRecent`,
        signal: cancelSignal,
        params: {
          locationIDs: deviceIds.join(","),
        },
      },
      true,
      true
    );
  }

  getRequests(deviceId, cancelSignal) {
    return DM.http(
      {
        method: "GET",
        url: `/devices/${deviceId}/requests`,
        signal: cancelSignal,
      },
      true,
      true
    );
  }
  // Data transmissions
  // includesPhotosOnly=true is a boolean that will only return transmissions with photos and is limited to 4 days
  // includesPhotosOnly=false or missing, will return all transmissions within the last 4 days
  getTransmissions({ ids, includesPhotosOnly, start, end }, cancelSignal) {
    let locationIDs = ids;
    if (!Array.isArray(locationIDs)) {
      locationIDs = [locationIDs];
    }
    const params = {
      locationIDs: locationIDs.join(","),
    };
    if (includesPhotosOnly) {
      params.includesPhotosOnly = includesPhotosOnly;
    }
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }
    params.apiVersion = 2;
    return DM.http(
      {
        method: "GET",
        url: `/locations/dataTransmissions`,
        signal: cancelSignal,
        params,
      },
      true,
      true
    );
  }

  // At a glance items
  getPriorityItems(groupID, type = "frostVision", cancelSignal) {
    if (!groupID) {
      return Promise.reject("No group ID provided");
    }
    return DM.http(
      {
        method: "GET",
        url: `/locations/priority`,
        signal: cancelSignal,
        params: {
          groupId: groupID,
          type,
        },
      },
      true,
      true
    );
  }

  // Forecasts
  getForecasts(locationIDs, groupID, cancelSignal) {
    if (!Array.isArray(locationIDs)) {
      locationIDs = [locationIDs];
    }
    return DM.http(
      {
        method: "GET",
        url: `/locations/forecasts`,
        signal: cancelSignal,
        params: {
          locationIDs: locationIDs.join(","),
          groupID,
        },
      },
      true,
      true
    );
  }

  // Alerts
  getAlerts(selectedGroupId, cancelSignal) {
    return DM.http(
      {
        method: "GET",
        url: `/locations/activeAlerts`,
        signal: cancelSignal,
        params: {
          groupId: selectedGroupId,
        },
      },
      true
    );
  }

  triggerDefroster(deviceId, cancelSignal) {
    return DM.http({
      method: "POST",
      url: `/devices/${deviceId}/defrost`,
      signal: cancelSignal,
    });
  }

  startEvent(deviceId, cancelSignal) {
    return DM.http({
      method: "POST",
      url: `/devices/${deviceId}/startEvent`,
      signal: cancelSignal,
    });
  }

  requestPhoto(deviceId, cancelSignal) {
    return DM.http({
      method: "POST",
      url: `/devices/${deviceId}/updatePhoto`,
      signal: cancelSignal,
    });
  }

  startWork(deviceId, cancelSignal) {
    return DM.http({
      method: "POST",
      url: `/devices/${deviceId}/startWork`,
      signal: cancelSignal,
    });
  }

  endWork(deviceId, cancelSignal) {
    return DM.http({
      method: "POST",
      url: `/devices/${deviceId}/endWork`,
      signal: cancelSignal,
    });
  }
}
