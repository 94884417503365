<template>
  <div class="dm-page-container sensor-view" ref="sensorWindow" :class="{ 'stacked': stacked }">
    <div class="flex-row dm-padding-left-md dm-padding-right-md mt-3 mb-3 align-center">
      <h2 class="text-xl m-0 sensor-view-title">{{ sensor?.Name }}</h2>
      <ejs-menu class="hide-caret sensor-settings-menu" :items="menuItems" :select="handleMenuClick"
        showItemOnClick="true">
      </ejs-menu>
      <span v-if="copiedMessage" class="ml-2 text-light">{{ copiedMessage }}</span>
      <button class="e-btn back-btn btn-sm btn-secondary rounded-sm flex ml-auto" @click="$emit('handle-go-back')">
        <i class="fa fa-arrow-left"></i>
        <span>Back</span>
      </button>
    </div>
    <template v-if="sensor?.DeviceType === 'Mini RWIS'">
      <rwis-sensor-view :sensor-id="sensorId"></rwis-sensor-view>
    </template>
    <template v-if="sensor?.DeviceType === 'Snow Depth Sensor'">
      <snow-depth-sensor-view :sensor-id="sensorId"></snow-depth-sensor-view>
    </template>
    <template v-if="sensor?.DeviceType === 'FrostVision Camera'">
      <frost-camera-sensor-view :sensor-id="sensorId"></frost-camera-sensor-view>
    </template>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useElementSize } from '@vueuse/core';
import { ref } from 'vue';

import rwisSensorView from './rwisSensorView';
import snowDepthSensorView from './snowDepthSensorView';
import frostCameraSensorView from './frostCameraSensorView';

import { useLocationsStore } from '../../js/store';

export default {
  name: 'sensors-view',
  props: ['sensorId', 'sensor'],
  emits: [
    'handle-go-back'
    ,],
  components: {
    rwisSensorView,
    snowDepthSensorView,
    frostCameraSensorView,
  },
  setup: () => {
    const sensorWindow = ref(null);
    const { width, height } = useElementSize(sensorWindow);
    return {
      width,
      height,
      sensorWindow,
    };
  },
  data: function () {
    return {
      copiedMessage: '',
      stacked: false,
    };
  },
  methods: {
    handleMenuClick(e) {
      if (typeof e.item.click === 'function') {
        e.item.click(e);
      }
    },
    copySerialNumber(e) {
      const el = document.createElement('textarea');
      el.value = this.sensor.SensorSerialNumber;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copiedMessage = 'Serial number copied!';
      setTimeout(() => {
        this.copiedMessage = '';
      }, 2000);
    },
  },
  computed: {
    menuItems() {
      return [
        {
          text: '',
          iconCss: 'fa fa-gear fa-2x text-secondary ml-2',
          id: 'settings',
          items: [{
            text: "Serial # " + this.sensor?.SensorSerialNumber, id: 'copy', iconCss: 'fa fa-copy', click: this.copySerialNumber,
          }],
        },
      ]
    }
  },
  watch: {
    width(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.stacked = newValue < 700;
      }
    },
  },
};
</script>
