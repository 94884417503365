<template>
  <div class="flex-column at-a-glance">
    <div class="flex-row justify-space-between">
      <div class="flex-row align-items-center">
        <span class="">At a Glance</span>
        <span class="text-sm badge badge-primary ml-1"
          :class="{ 'badge-inactive': Object.keys(locationsStore.glanceItems).length === 0 }">{{
            Object.keys(locationsStore.glanceItems).length }}</span>
        <GlanceSelector :selected="selected" :options="options" :onChange="onChange" />
      </div>
      <i class="fa fa-expand-alt text-inactive" @click="toggleExpand()"></i>
    </div>
    <div class="flex-row glance-items" v-if="displayAtAGlance">
      <template v-for="(glanceItem, deviceId) in locationsStore.glanceItems">
        <GlanceItem :device="locationsStore.devicesByIds[deviceId]" :deviceId="deviceId" :glanceItem="glanceItem"
          :selectedKey="selected.value" />
      </template>
    </div>
    <div class="flex-row justify-center"
      v-if="displayAtAGlance === true && Object.keys(locationsStore.glanceItems).length === 0">
      <span class="text-sm mt-1 mb-1">Devices with alerts or adverse FrostVision conditions will
        show here.</span>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

import { useLocationsStore, useBaseStore, useGroupsStore } from '../js/store';
import GlanceItem from './glanceItem.vue';
import GlanceSelector from './glanceSelector.vue';

export default {
  components: {
    GlanceItem,
    GlanceSelector,
  },
  data() {
    return {
      displayAtAGlance: true,
      options: [
        { value: 'frostVision', label: 'Frost Vision' },
        { value: 'alerts', label: 'Alerts' },
      ],
    };
  },
  computed: {
    ...mapStores(useLocationsStore, useBaseStore, useGroupsStore),
    selected: {
      get() {
        const found = this.options.find((option) => option.value === this.baseStore.preferences.glanceSelectedKey);
        return found || this.options[0];
      },
      set(value) {
        this.baseStore.setPreferences({ glanceSelectedKey: value.value });
      },
    },
  },
  methods: {
    toggleExpand() {
      this.displayAtAGlance = !this.displayAtAGlance;
    },
    onChange(option) {
      this.selected = option;
    },
  },
  mounted() {
    if (Object.keys(this.locationsStore.glanceItems).length === 0) {
      this.displayAtAGlance = false;
    }
  },
  watch: {
    'groupsStore.selectedGroupId': {
      handler() {
        this.locationsStore.getGlanceData()

      },
    },
    'selected': {
      handler(newValue, oldValue) {
        if (newValue.value !== oldValue.value) {
          this.locationsStore.getGlanceData()
        }
      },
    },
    'locationsStore.glanceItems': {
      handler(newValue, oldValue) {
        if (Object.keys(oldValue).length === 0 && Object.keys(newValue).length > 0) {
          this.displayAtAGlance = true;
        } else if (Object.keys(oldValue).length > 0 && Object.keys(newValue).length === 0) {
          this.displayAtAGlance = false;
        }
      },
    },
  },
};

</script>