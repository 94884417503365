<template>
  <div class="forecast-limit">
    <div class="e-input-group">
      <ejs-dropdownlist :dataSource="intervalOptions" :fields="fields" :index="0" :change="handleChangeInterval"
        :value="baseStore.preferences.chartLimit || 72" floatLabelType="Auto" placeholder="Forecast"></ejs-dropdownlist>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useBaseStore } from "../js/store";

export default {
  name: 'chart-limit-selector',
  data() {
    return {
      intervalOptions: [
        { label: '72 hrs', value: 72 },
        { label: '48 hrs', value: 48 },
        { label: '24 hrs', value: 24 },
      ],
      fields: { label: 'label', value: 'value' },
    }
  },
  methods: {
    handleChangeInterval(val) {
      this.baseStore.setPreferences(
        {
          ...this.baseStore.preferences,
          chartLimit: val.value
        }
      )
    }
  },
  computed: {
    ...mapStores(useBaseStore),
  }
}
</script>
