import moment from "moment-timezone";
import { startCase, round } from "lodash";

// RoadCondition Icons
import dryIcon from "../images/svg/roadconditions/dry.svg";
import wetIcon from "../images/svg/roadconditions/wet.svg";
import slushIcon from "../images/svg/roadconditions/slush.svg";
import looseSnowIcon from "../images/svg/roadconditions/loose_snow.svg";
import packedSnowIcon from "../images/svg/roadconditions/packed_snow.svg";
import iceIcon from "../images/svg/roadconditions/ice.svg";
import unknownIcon from "../images/svg/grayScaleLogo.svg";

// Current Weather Icons
import NightIcon from "../images/svg/currentcondition/Night.svg";
import SunnyIcon from "../images/svg/currentcondition/Sunny.svg";
import LightRainIcon from "../images/svg/currentcondition/LightRain.svg";
import ModerateRainIcon from "../images/svg/currentcondition/ModerateRain.svg";
import HeavyRainIcon from "../images/svg/currentcondition/HeavyRain.svg";
import LightSnowIcon from "../images/svg/currentcondition/LightSnow.svg";
import ModerateSnowIcon from "../images/svg/currentcondition/ModerateSnow.svg";
import HeavySnowIcon from "../images/svg/currentcondition/HeavySnow.svg";
import PartlyCloudyIcon from "../images/svg/currentcondition/PartlyCloudy.svg";
import PartlyCloudyNightIcon from "../images/svg/currentcondition/PartlyCloudyNight.svg";
import CloudyIcon from "../images/svg/currentcondition/Cloudy.svg";
import WindyIcon from "../images/svg/currentcondition/Windy.svg";
import LightMixIcon from "../images/svg/currentcondition/LightMix.svg";
import ModerateMixIcon from "../images/svg/currentcondition/ModerateMix.svg";
import HeavyMixIcon from "../images/svg/currentcondition/HeavyMix.svg";

export function isNight(hours) {
  if (parseInt(hours) >= 18 || parseInt(hours) <= 6) {
    return true;
  } else {
    return false;
  }
}

function iconTextString(text, icon) {
  return `<center><p>${text}</p> <img src=${icon} /></center>`;
}

const CURRENT_WEATHER_ENUM = {
  Unknown: 0,
  Sunny: 1,
  LightRain: 2,
  ModerateRain: 3,
  HeavyRain: 4,
  LightSnow: 5,
  ModerateSnow: 6,
  HeavySnow: 7,
  PartlyCloudy: 8,
  Cloudy: 9,
  Windy: 10,
  LightMix: 11,
  ModerateMix: 12,
  HeavyMix: 13,
};

export function parseWeatherConditionText(currentWeatherEnum) {
  const currentWeather = Object.keys(CURRENT_WEATHER_ENUM).find(
    (key) => CURRENT_WEATHER_ENUM[key] === currentWeatherEnum
  );
  if (!currentWeather) return "-";
  return startCase(currentWeather);
}
export function parseRoadConditionText(condition) {
  switch (condition) {
    case 0:
      return "Dry";

    case 3:
    case 4:
      return "Wet";

    case 1:
    case 2:
    case 7:
      return "Slush";

    case 5:
      return "Mixed";

    case 6:
    case 8:
    case 9:
      return "Snow";

    case 10:
      return "Black";

    default:
      return "-";
  }
}

export function parseTextWeatherConditionIcon(
  currentWeatherEnum,
  hours,
  ForecastMinutes,
  isForecast
) {
  if (!currentWeatherEnum) {
    return iconTextString("-", unknownIcon);
  }
  let { hover } = parseHoursMinHover(ForecastMinutes);
  const hoverHours = isForecast ? hover : "";
  let currentWeather = iconTextString("Sunny " + hoverHours, SunnyIcon);

  const isNightTime = isNight(hours);
  const displayNight = iconTextString("Clear skies at night", NightIcon);

  switch (currentWeatherEnum) {
    case CURRENT_WEATHER_ENUM.Sunny:
      if (isNightTime) {
        currentWeather = displayNight;
      } else {
        currentWeather = iconTextString("Sunny " + hoverHours, SunnyIcon);
      }
      break;
    case CURRENT_WEATHER_ENUM.LightRain:
      currentWeather = iconTextString(
        "Light Rain " + hoverHours,
        LightRainIcon
      );
      break;
    case CURRENT_WEATHER_ENUM.ModerateRain:
      currentWeather = iconTextString(
        "Moderate Rain " + hoverHours,
        ModerateRainIcon
      );
      break;
    case CURRENT_WEATHER_ENUM.HeavyRain:
      currentWeather = iconTextString(
        "Heavy Rain " + hoverHours,
        HeavyRainIcon
      );
      break;
    case CURRENT_WEATHER_ENUM.LightSnow:
      currentWeather = iconTextString(
        "Light Snow " + hoverHours,
        LightSnowIcon
      );
      break;
    case CURRENT_WEATHER_ENUM.ModerateSnow:
      currentWeather = iconTextString(
        "Moderate Snow " + hoverHours,
        ModerateSnowIcon
      );
      break;
    case CURRENT_WEATHER_ENUM.HeavySnow:
      currentWeather = iconTextString(
        "Heavy Snow " + hoverHours,
        HeavySnowIcon
      );
      break;
    case CURRENT_WEATHER_ENUM.PartlyCloudy:
      if (isNightTime) {
        currentWeather = iconTextString(
          "Partly Cloudy " + hoverHours,
          PartlyCloudyNightIcon
        );
      } else {
        currentWeather = iconTextString(
          "Partly Cloudy " + hoverHours,
          PartlyCloudyIcon
        );
      }
      break;
    case CURRENT_WEATHER_ENUM.Cloudy:
      currentWeather = iconTextString("Cloudy " + hoverHours, CloudyIcon);
      break;
    case CURRENT_WEATHER_ENUM.Windy:
      currentWeather = iconTextString("Windy " + hoverHours, WindyIcon);
      break;
    case CURRENT_WEATHER_ENUM.LightMix:
      currentWeather = iconTextString("Light Mix " + hoverHours, LightMixIcon);
      break;
    case CURRENT_WEATHER_ENUM.ModerateMix:
      currentWeather = iconTextString(
        "Moderate Mix " + hoverHours,
        ModerateMixIcon
      );
      break;
    case CURRENT_WEATHER_ENUM.HeavyMix:
      currentWeather = iconTextString("Heavy Mix " + hoverHours, HeavyMixIcon);
      break;
    default:
      currentWeather = iconTextString("- " + hoverHours, unknownIcon);
      break;
  }
  return currentWeather;
}

export function parseWeatherConditionIcon(currentWeatherEnum, hours) {
  const isNightTime = isNight(hours);

  switch (currentWeatherEnum) {
    case CURRENT_WEATHER_ENUM.Sunny:
      return isNightTime ? NightIcon : SunnyIcon;
    case CURRENT_WEATHER_ENUM.LightRain:
      return LightRainIcon;
    case CURRENT_WEATHER_ENUM.ModerateRain:
      return ModerateRainIcon;
    case CURRENT_WEATHER_ENUM.HeavyRain:
      return HeavyRainIcon;
    case CURRENT_WEATHER_ENUM.LightSnow:
      return LightSnowIcon;
    case CURRENT_WEATHER_ENUM.ModerateSnow:
      return ModerateSnowIcon;
    case CURRENT_WEATHER_ENUM.HeavySnow:
      return HeavySnowIcon;
    case CURRENT_WEATHER_ENUM.PartlyCloudy:
      return isNightTime ? PartlyCloudyNightIcon : PartlyCloudyIcon;
    case CURRENT_WEATHER_ENUM.Cloudy:
      return CloudyIcon;
    case CURRENT_WEATHER_ENUM.Windy:
      return WindyIcon;
    case CURRENT_WEATHER_ENUM.LightMix:
      return LightMixIcon;
    case CURRENT_WEATHER_ENUM.ModerateMix:
      return ModerateMixIcon;
    case CURRENT_WEATHER_ENUM.HeavyMix:
      return HeavyMixIcon;
    default:
      return unknownIcon;
  }
}

// RoadCondition is a number
export function parseRoadConditionIcon(RoadCondition) {
  let roadCondition = unknownIcon;
  switch (RoadCondition) {
    case 0:
      roadCondition = dryIcon;
      break;
    case 3:
    case 4:
      roadCondition = wetIcon;
      break;
    case 1:
    case 2:
    case 7:
      roadCondition = slushIcon;
      break;
    case 5:
      roadCondition = iceIcon;
      break;
    case 6:
      roadCondition = looseSnowIcon;
      break;
    case 8:
    case 9:
      roadCondition = packedSnowIcon;
      break;
    case 10:
      roadCondition = iceIcon;
      break;
    default:
      roadCondition = unknownIcon;
      break;
  }
  return roadCondition;
}

// currentCondition is the same as the CurrentCondition object form the API
export function parseRoadTextConditionIcon(currentCondition) {
  if (!currentCondition) {
    return iconTextString("-", unknownIcon);
  }
  let roadCondition = iconTextString("Dry surface ", dryIcon);
  let { hover } = parseHoursMinHover(currentCondition?.ForecastMinutes);

  switch (currentCondition?.RoadCondition) {
    case 3:
    case 4:
      roadCondition = iconTextString("Wet surface " + hover, wetIcon);
      break;
    case 1:
    case 2:
    case 7:
      roadCondition = iconTextString("Slush road " + hover, slushIcon);
      break;
    case 5:
      roadCondition = iconTextString("Freezing rain " + hover, iceIcon);
      break;
    case 6:
      roadCondition = iconTextString("Snow " + hover, looseSnowIcon);
      break;
    case 8:
    case 9:
      roadCondition = iconTextString("Snow " + hover, packedSnowIcon);
      break;
    case 10:
      roadCondition = iconTextString("Ice road " + hover, iceIcon);
      break;
    default:
      roadCondition = iconTextString("Unknown " + hover, unknownIcon);
      break;
  }
  return roadCondition;
}

// Creates an object with text based on current time
export function parseHoursMinHover(ForecastMinutes) {
  if (!ForecastMinutes) {
    return {
      hover: "",
      text: "",
    };
  }
  const { hours, minutes } = toHoursAndMinutes(ForecastMinutes);
  const hourHoverText = hours > 1 ? "hours" : "hour";
  const hourText = hours > 1 ? "hrs" : "hr";
  const minText = minutes > 1 ? "mins" : "min";

  let foreCastMins = {
    hover: "",
    text: "",
  };

  if (ForecastMinutes == 0) {
    foreCastMins = {
      hover: "",
      text: "",
    };
  } else if (ForecastMinutes >= 60) {
    let hover = `in ${hours} ${hourHoverText} and ${minutes} ${minText}`;
    let text = `${hours} ${hourText} ${minutes} ${minText}`;
    if (minutes === 0) {
      hover = `in ${hours} ${hourHoverText}`;
      text = `${hours} ${hourText}`;
    }

    foreCastMins = {
      hover: hover,
      text: text,
    };
  } else {
    foreCastMins = {
      hover: `in ${minutes} ${minText}`,
      text: `${minutes} ${minText}`,
    };
  }

  // Show forecast day time only and tile weather tile only
  return foreCastMins;
}

export function parsePrecipAccumulation(precipSummary, isMetric) {
  const unit = isMetric ? "cm" : "in";

  let formattedPrecip = 0;
  if (precipSummary && precipSummary?.PrecipRate > 0) {
    formattedPrecip = Math.round(precipSummary?.PrecipRate * 100) / 100;
  }

  let { hover: accHover } = parseHoursMinHover(
    (precipSummary?.ForecastHours ?? 0) * 60
  );
  const accHours = accHover.replace("in ", "");

  if (formattedPrecip > 0) {
    if (precipSummary?.PrecipType === 1) {
      return `${formattedPrecip} ${unit} rain over next ${accHours}`;
    } else if (precipSummary?.PrecipType === 3) {
      return `${formattedPrecip} ${unit} snow over next ${accHours}`;
    } else if (precipSummary?.PrecipType === 2) {
      return `${formattedPrecip} ${unit} wintery mix over next ${accHours}`;
    }
  }

  return "";
}

export function showForecastTime(forecastMinutes, forecastWeather) {
  let isRainSnow = false;

  switch (forecastWeather) {
    case CURRENT_WEATHER_ENUM.LightRain:
    case CURRENT_WEATHER_ENUM.ModerateRain:
    case CURRENT_WEATHER_ENUM.HeavyRain:
    case CURRENT_WEATHER_ENUM.LightSnow:
    case CURRENT_WEATHER_ENUM.ModerateSnow:
    case CURRENT_WEATHER_ENUM.HeavySnow:
    case CURRENT_WEATHER_ENUM.LightMix:
    case CURRENT_WEATHER_ENUM.ModerateMix:
    case CURRENT_WEATHER_ENUM.HeavyMix:
      isRainSnow = true;
      break;
  }

  return forecastMinutes > 0 && !isRainSnow;
}

export function parseWindSpeedUnit(isMetric) {
  return isMetric ? "km/hr" : "mph";
}

export function getHours(utcTime, timeZone) {
  if (!utcTime) return null;
  return moment.utc(utcTime).tz(timeZone).format("HH");
}

export function formatWindDirection(degree) {
  if (degree === null || typeof degree === "undefined") return "";
  const val = Math.floor(degree / 22.5 + 0.5);
  const arr = [
    "N",
    "NNE",
    "NE",
    "ENE",
    "E",
    "ESE",
    "SE",
    "SSE",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW",
  ];
  return arr[val % 16];
}

export function convertMilimetersToInches(mm) {
  if (!mm) return mm;
  return mm / 25.4;
}

export function convertPrecipRate(inch, isMetric) {
  if (!inch) return inch;
  if (!isMetric) return formatPrecip(inch);
  return formatPrecip(inch / 0.3937);
}
export function convertWindSpeed(mile, isMetric) {
  if (!mile) return mile;
  if (!isMetric) return formatDecimal(mile);
  return formatDecimal(mile / 0.62137);
}
export function parseRoadConditionChart(chartData, condition) {
  let show = false;
  const chart = chartData.reduce((acc, curr, index) => {
    const data = { ...curr };

    if (data.ParsedRoadCondition !== condition) {
      data.value = null;
    } else if (data.value >= 0) {
      show = true;
    }

    // We need even the empty values to help space the chart
    acc.push(data);
    return acc;
  }, []);
  return {
    chart: chart,
    show: show,
  };
}
export function parseRoadCondition(condition) {
  let roadCondition = null;

  switch (condition) {
    case 0:
      roadCondition = "dry";
      break;
    case 3:
    case 4:
      roadCondition = "wet";
      break;
    case 1:
    case 2:
    case 7:
      roadCondition = "slush";
      break;
    case 5:
      roadCondition = "mixed";
      break;
    case 6:
    case 8:
    case 9:
      roadCondition = "snow";
      break;
    case 10:
      roadCondition = "black";
      break;
  }

  return roadCondition;
}
export function getDisplayTime(reading, isMostRecent, timezone) {
  if (isMostRecent) {
    if (reading.MinutesSinceReading > 60) {
      reading.displayTime = formatDate(
        reading.TransmissionDateTimeUTC,
        timezone
      );
    } else {
      reading.displayTime = "Last: " + reading.MinutesSinceReading + "m ago";
    }
  } else {
    reading.displayTime = formatDate(reading.TransmissionDateTimeUTC, timezone);
  }
}
export function camelPad(str) {
  if (!str) {
    return "";
  }
  return (
    str
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, "$1 $2")
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, "$1 $2")
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      // Remove any white space left around the word
      .trim()
  );
}
export function splitCamelCase(str) {
  if (!str) return "";

  return str.split(/([A-Z][a-z]+)/).join(" ");
}
export function formatDate(date, timezone) {
  if (!date) return null;
  let d = date;
  if (typeof d === "string") {
    d = moment(d, true).utc(true).tz("GMT").toDate();
  }
  if (timezone) {
    return moment.utc(d).tz(timezone).format("MMM DD, YY  LT  Z");
  }

  return moment.utc(d).format("MMM DD, YY LT Z");
}
export function formatChartDate(date, timezone) {
  let d = date;
  if (!d && !timezone) return null;
  // If date is a string, convert it to a date object
  if (typeof date === "string") {
    d = moment(date, true).utc(true).tz("GMT").toDate();
  }
  if (!timezone) return moment(d, true).format("MMM DD, YY LT");
  return moment.utc(d, true).tz(timezone).format("MMM DD, YY LT");
}
export function formatPrecip(precip) {
  if (!precip) return 0;
  if (precip > 0) {
    const rate = Math.round(precip * 10) / 10;
    return rate == 0 ? Number(precip.toFixed(2)) : rate;
  }
}
export function formatDecimal(number) {
  if (!number) return 0;
  return round(number, 1);
}
export function convertFahrenheitToCelcius(temp, unit) {
  if (temp === null) return null;
  if (unit === "Celsius") {
    temp = ((temp - 32) * 5) / 9;
  }

  return formatDecimal(temp);
}
export function convertCelciusToFahrenheit(temp, unit) {
  if (temp === null) return null;
  if (unit === "Fahrenheit") {
    temp = (temp * 9) / 5 + 32;
  }
  return formatDecimal(temp);
}
export function toHoursAndMinutes(foreCastMinutes) {
  const totalMinutes = Number(foreCastMinutes);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let hourString = "";

  if (totalMinutes == 0) {
    hourString = {};
  } else if (totalMinutes >= 60) {
    hourString = { hours, minutes };
  } else {
    hourString = { hours, minutes };
  }
  return hourString;
}
export function isTabletScreen(tablet) {
  if (tablet) {
    return window.innerWidth <= 1180;
  } else {
    return window.innerWidth < 666;
  }
}

export function exportToCSV(array, filename, columnNames) {
  let columns = columnNames;
  if (!columns) {
    columns = Object.keys(array[0]).map((e) => {
      e = e.charAt(0).toUpperCase() + e.slice(1);
      return e.match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g).join(" ");
    });
  }

  const data = [columns]
    .concat(array)
    .map((it) => {
      return Object.values(it)
        .map((e) => {
          if (e === null || e === undefined) e = "";
          return `"${e}"`;
        })
        .toString();
    })
    .join("\r\n");

  downloadAjaxResponseAsFile(data, filename);
}

export function downloadAjaxResponseAsFile(response, fileName) {
  let blob = new Blob([response], { type: "text/csv" });
  let objectUrl = URL.createObjectURL(blob);
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.href = objectUrl;
  a.download = fileName;
  a.click();
  document.body.removeChild(a);
}

export function addSpaceBeforeCapital(str) {
  return str.replace(/([A-Z])/g, " $1");
}

export function computerVisionValueGreaterThanFiftyPercent(cv) {
  return Object.values(cv).some((value) => value > 50);
}
