<template>
    <div class="dm-flex-column dm-flex-1 dm-padding-sm photos-table" :class="{ 'is-loading': isLoading }">
        <h3>Photos</h3>
        <dm-table ref="grdSensorPhotos" :table="{
            grdTableItems: grdSensorReadingsItems,
            grdTableFields: grdSensorReadingsFields,
        }" :allowPaging="false" />

        <!-- custom pager based on syncfusion's grid table -->
        <div class="e-gridpager e-control e-pager e-lib">
            <div class="e-pagercontainer" role="navigation">
                <div class="e-first e-icons e-icon-first" :class="{
                    'e-firstpagedisabled e-disable': currentPage <= 0,
                    'e-firstpage e-pager-default': currentPage > 0
                }" title="Go to first page" tabindex="-1" index="0" @click="goToPage(1)"></div>
                <div class="e-prev e-icons e-icon-prev" :class="{
                    'e-prevpagedisabled e-disable': currentPage <= 0,
                    'e-prevpage e-pager-default': currentPage > 0
                }" title="Go to previous page" tabindex="-1" :index="currentPage - 1" @click="goToPage(currentPage)">
                </div>

                <div v-if="previousPagerIndex">
                    <a class="e-link e-np e-spacing e-numericitem e-pager-default" title="Go to previous pager"
                        role="link" aria-label="Go to previous pager" tabindex="-1" name="Go to previous pager"
                        href="javascript:void(0);" :index="previousPagerIndex" @click="goToPage(previousPagerIndex)">
                        ...
                    </a>
                </div>

                <div class="e-numericcontainer">
                    <div v-for="page in displayedPages" :key="page">
                        <a class="e-link e-numericitem e-spacing" :class="{
                            'e-currentitem e-active': currentPage === (page - 1)
                        }" role="link" tabindex="-1" :aria-label="`Goto Page ${page}`" href="javascript:void(0);"
                            :name="`Goto page${page}`" :index="page" @click="goToPage(page)">
                            {{ page }}
                        </a>
                    </div>
                </div>

                <div v-if="nextPagerIndex">
                    <a class="e-link e-np e-spacing e-numericitem e-pager-default" title="Go to next pager" role="link"
                        aria-label="Go to next pager" tabindex="-1" name="Go to next pager" href="javascript:void(0);"
                        :index="nextPagerIndex" @click="goToPage(nextPagerIndex)">
                        ...
                    </a>
                </div>

                <div class="e-next e-icons e-icon-next" :class="{
                    'e-nextpagedisabled e-disable': currentPage + 1 >= pagesAvailable,
                    'e-nextpage e-pager-default': currentPage + 1 < pagesAvailable
                }" title="Go to next page" tabindex="-1" :index="currentPage + 2" @click="goToPage(currentPage + 2)">
                </div>
                <div class="e-last e-icons e-icon-last" :class="{
                    'e-lastpagedisabled e-disable': currentPage + 1 >= pagesAvailable,
                    'e-lastpage e-pager-default': currentPage + 1 < pagesAvailable
                }" title="Go to last page" tabindex="-1" :index="pagesAvailable" @click="goToPage(pagesAvailable)">
                </div>
            </div>
            <div class="e-parentmsgbar"><span class="e-pagenomsg" style="text-align:right">{{ currentPage + 1 }} of {{
                pagesAvailable }} pages </span><span class="e-pagecountmsg" style="text-align:right">({{ totalPhotos
                    }}
                    items)</span></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useGroupsStore, useHistoryStore } from '../../js/store';
import { chunk, range } from 'lodash';
import { formatDate } from '../../js/utils';

export default {
    name: 'sensors-history-photos-table',
    props: [
        'deviceId',
        'temperatureUnit',
    ],
    data: function () {
        return {
            currentPage: 0,
            photosPerPage: 10,
            itemsPerPager: 10,
            grdSensorReadingsFields: [],
        }
    },
    methods: {
        defineColumns() {
            this.grdSensorReadingsFields = [
                {
                    key: 'chartDate',
                    label: 'Time',
                    type: 'custom',
                    width: '170px'
                },
                {
                    key: 'Photo',
                    label: 'Photo',
                    allowSorting: false,
                    type: 'template',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<div v-if="this.showDownloadImageIcon" class="dm-flex-row dm-align-items-center">
                                <i @click="btnDownloadImageOnClick" class="fa fa-download dm-padding-left-sm dm-pointer"></i>
                            </div>`,
                                methods: {
                                    btnDownloadImageOnClick() {
                                        // Note: grid's data, not the main vue component
                                        const { ID, TransmissionDateTimeUTC, ProcessedCameraImageURL } = this.data;

                                        let fileName = `${formatDate(TransmissionDateTimeUTC)}.jpg`;
                                        fileName = fileName.replaceAll(' ', '-').replaceAll(',', '').replaceAll(':', '');
                                        axios({
                                            method: 'GET',
                                            url: ProcessedCameraImageURL,
                                            responseType: 'blob'
                                        }).then(response => {
                                            const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
                                            let a = document.createElement("a");
                                            document.body.appendChild(a);
                                            a.href = objectUrl;
                                            a.download = fileName;
                                            a.click();
                                            document.body.removeChild(a);
                                        });
                                    },
                                },
                                computed: {
                                    image: function () {
                                        return this.data.ProcessedCameraImageURL ? this.data.ProcessedCameraImageURL : null;
                                    },
                                    showDownloadImageIcon: function () {
                                        return this.data.ProcessedCameraImageURL ? true : false;
                                    }
                                }
                            }),
                        }
                    },
                    width: '90px',
                },
                {
                    key: 'ComputerVision',
                    label: 'Computer Vision',
                    allowSorting: false,
                    type: 'custom',
                    width: '200px',
                }
            ];

        },
        async goToPage(page) {
            // prevent going to pages that don't exist
            if (page < 1 || page > this.pagesAvailable) {
                return;
            }

            const pageIndex = page - 1;

            // visually change the page
            this.currentPage = pageIndex;

            // load page's data if needed
            const data = this.historyStore.photos?.pages[pageIndex]
            if (!data) {
                // load and wait for data
                await this.historyStore.getPhotosPage(this.deviceId, pageIndex);
            }
        }
    },
    computed: {
        ...mapStores(useGroupsStore, useHistoryStore),
        isLoading() {
            return this.historyStore.fetchingPhotos;
        },
        grdSensorReadingsItems() {
            if (this.isLoading) {
                return null;
            }
            const data = this.historyStore.photos?.pages[this.currentPage] || [];
            return data;
        },
        pagesAvailable() {
            return Math.ceil(this.totalPhotos / this.photosPerPage);
        },
        displayedPages() {
            const pagesArray = range(1, this.pagesAvailable + 1);
            const pagerChunks = chunk(pagesArray, this.itemsPerPager);
            let pagerChunk;

            pagerChunks.every((chunk) => {
                if (chunk.includes(this.currentPage + 1)) {
                    pagerChunk = chunk;
                    // break out of the loop
                    return false;
                }
                return true;
            });
            const start = pagerChunk?.at(0) || 1;
            const end = pagerChunk?.at(-1) || 1;

            return range(start, end + 1);
        },
        totalPhotos() {
            // calculate this value from store's metadata
            return this.historyStore.photos?.metadata.length;
        },
        previousPagerIndex() {
            const firstPagerItem = this.displayedPages.at(0);
            const previousIndex = firstPagerItem - 1;
            if (previousIndex <= 1) {
                return null;
            }
            return previousIndex;
        },
        nextPagerIndex() {
            const lastPagerItem = this.displayedPages.at(-1);
            const nextIndex = lastPagerItem + 1;
            if (nextIndex > this.pagesAvailable) {
                return null;
            }
            return nextIndex;
        },
    },
    created() {
        this.defineColumns();
    },
    mounted() {
        let defineColumns;
        window.onresize = () => {
            clearTimeout(defineColumns);
            defineColumns = setTimeout(this.defineColumns, 100);
        };
    },
}
</script>