<template>
  <img :src="currentImage" :alt="alt" @error="handleImageError" @click="click" v-bind="$attrs" />
</template>

<script>
import Vue from 'vue';
import missingImage from '../images/missing-image.png';
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
      required: false
    },
    src: {
      type: String,
      default: '',
      required: false
    },
    click: {
      type: Function,
      default: () => { },
      required: false
    },
    alt: {
      type: String,
      default: '',
      required: false
    },
  },
  data() {
    return {
      currentImageIndex: 0,
      currentImage: '',
    };
  },
  computed: {
    filteredImages() {
      return [this.src, ...this.images].filter(Boolean);
    },
  },
  created() {
    this.currentImage = this.filteredImages[0] || missingImage;
  },
  watch: {
    src: {
      handler() {
        this.currentImageIndex = 0;
        this.currentImage = this.filteredImages[0];
      },
      immediate: true,
    },
    images: {
      handler() {
        this.currentImageIndex = 0;
        this.currentImage = this.filteredImages[0];
      },
      deep: true,
    },
  },
  methods: {
    handleImageError() {
      this.currentImageIndex += 1;
      if (this.currentImageIndex < this.filteredImages.length) {
        this.currentImage = this.filteredImages[this.currentImageIndex];
      } else {
        this.currentImage = missingImage;
      }
    },
  },
};
</script>