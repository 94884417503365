<template>
    <div class="dm-flex-column image-carousel">
        <div class="relative max-h-500">
            <image-with-overlay :data-transmission="selectedReading" :temperature-unit="props.temperatureUnit"
                :key="selectedReading.chartDate" :hide-readings="hideReadings" />
        </div>
        <div class="dm-flex-row dm-margin-top-md additional-images relative">
            <button class="image-advance prev" aria-label="Previous Image" type="button"
                @click="previousTransmission()">
                <div class="arrow-wrapper">
                    <div class="circle">
                        <i class="fa fa-arrow-left"></i>
                    </div>
                </div>
            </button>
            <template v-for="(image, $index) in additionalImages">

                <ejs-tooltip :content="image.text" opensOn="Auto"
                    :class="{ active: image.index === transmissionIndex }">
                    <fallback-image :src="image.url" :alt="image.text" class="dm-flex"
                        :class="{ active: image.url === selectedReading?.ProcessedCameraImageURL }"
                        :click="handleImageClick" :data-index="image.index" />
                </ejs-tooltip>
            </template>
            <button class="image-advance next" aria-label="Next Image" :class="{ disabled: transmissionIndex === 0 }"
                type="button" @click="nextTransmission()">
                <div class="arrow-wrapper">
                    <div class="circle">
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';

import { useForecastsStore, useLocationsStore } from '../../js/store';
import { convertWindSpeed, formatWindDirection } from '../../js/utils';
import moment from 'moment-timezone';
import imageWithOverlay from '../imageWithOverlay.vue';
import fallbackImage from '../fallbackImageComponent.vue';

import missingImage from '../../images/missing-image.png';

const forecastsStore = useForecastsStore();

const props = defineProps({
    imageTransmissions: {
        type: Array,
        default: () => [],
    },
    temperatureUnit: {
        type: String,
        required: true,
    },
    isMetric: {
        type: Boolean,
        required: true,
    },
    sensorId: {
        type: String,
        required: true,
    },
    hideReadings: {
        type: Boolean,
        default: false,
    },
});
const additionalImageLimit = 15;
const transmissionIndex = ref(0);

function handleImageClick(e) {
    transmissionIndex.value = parseInt(e.target.dataset.index, 10)
}

function previousTransmission() {
    if (props.imageTransmissions && transmissionIndex.value < props.imageTransmissions.length - 1) {
        transmissionIndex.value++;
    } else {
        transmissionIndex.value = 0;
    }
}
function nextTransmission() {
    if (transmissionIndex.value > 0) {
        transmissionIndex.value--;
    } else {
        transmissionIndex.value = props.imageTransmissions.length - 1;
    }
}

const selectedReading = computed(function () {
    if (!props.imageTransmissions?.length > 0) {
        return {
            ProcessedCameraImageURL: missingImage,
        };
    }
    const selected = props.imageTransmissions[transmissionIndex.value];
    const rcHistory = forecastsStore.forecasts[props.sensorId]?.RoadConditionsHistory
    if (!rcHistory) {
        return {
            ...selected,
        }
    }
    const found = rcHistory.find(h => {
        const historyDate = moment.utc(h.TransmissionDateTimeUTC);
        if (historyDate.diff(moment.utc(selected.TransmissionDateTimeUTC), 'minutes') < 5) {
            return h;
        }
    });
    if (!found) {
        return {
            ...selected,
        }
    }
    return {
        ...selected,
        WindSpeed: convertWindSpeed(found.WindSpeed, props.isMetric),
        WindDirection: formatWindDirection(found.WindDirection),
    }
});

const additionalImages = computed(function () {
    if (!props.imageTransmissions.length) {
        return Array(additionalImageLimit).fill({});
    }
    const startIndex = transmissionIndex.value;
    const endIndex = transmissionIndex.value + additionalImageLimit > props.imageTransmissions.length ? props.imageTransmissions.length : transmissionIndex.value + additionalImageLimit;

    const formattedImages = props.imageTransmissions.map(r => ({
        index: props.imageTransmissions.findIndex((x) => x.ProcessedCameraImageURL === r.ProcessedCameraImageURL),
        url: r.ProcessedCameraImageURL,
        text: r.chartDate,
    }));

    let images = formattedImages.slice(startIndex, endIndex);

    // create a carousel effect for the thumbnails
    if (images.length < props.imageTransmissions.length) {
        // append images
        const remainingImages = formattedImages.slice(0, startIndex);
        images = images.concat(remainingImages);

        // limit images to 14
        images = images.slice(0, additionalImageLimit);
    }

    return images;
})

</script>