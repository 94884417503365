var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ejs-dialog',{ref:_vm.modalName,attrs:{"id":_vm.modalName,"target":_vm.sfTarget,"header":_vm.lblTitle,"isModal":"true","showCloseIcon":"true","width":'500px',"height":'630px',"visible":"false","allowDragging":"true","cssClass":"scrollable wizard"}},[_c('div',{staticClass:"dm-flex-column dm-overflow-hidden dm-padding-md dm-flex-1"},[_c('div',{staticClass:"dm-flex-row dm-justify-content-flex-end dm-margin-bottom-md"},[_c('ejs-button',{attrs:{"is-primary":"true","cssClass":"blue-gradient"},nativeOn:{"click":function($event){return _vm.btnAddFilesOnClick.apply(null, arguments)}}},[_c('span',{staticClass:"fa fa-plus-square"}),_vm._v(" "),_c('span',{staticClass:"dm-margin-left-sm app-mobile-hide"},[_vm._v("Add Files")])])],1),_c('div',{staticClass:"dm-flex-column dm-overflow-hidden dm-flex-1"},[_c('dm-table',{ref:"grdFiles",attrs:{"table":{
                    grdTableItems: _vm.grdFilesItems,
                    grdTableRowOnClick: _vm.grdFilesRowOnClick,
                    grdTableActionOnClick: _vm.grdFilesActionOnClick,
                    grdTableSortBy: _vm.grdFilesSortBy,
                    grdTableFields: _vm.grdFilesFields,
                    class: _vm.grdFilesClass
                }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }