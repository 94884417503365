var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-height-100"},[_c('dm-table',{ref:"grdLocations",attrs:{"table":{
        grdTableItems: _vm.grdLocationsItems,
        grdTableRowOnClick: _vm.grdLocationsRowOnClick,
        grdTableActionOnClick: _vm.grdLocationsActionOnClick,
        grdTableSortBy: _vm.grdLocationsSortBy,
        grdTableFields: _vm.grdLocationsFields,
    },"allowPaging":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }