<template>
  <div class="glance-item" v-if="glanceItem?.ProcessedCameraImageURL">
    <router-link :to="routerLink" exactActiveClass="active" class="cursor">
    </router-link>
    <ejs-tooltip :content="glanceItem?.AlertBody" :opensOn="glanceItem?.AlertBody ? 'Auto' : ''">
      <fallback-image :src="glanceItem?.ProcessedCameraImageURL" class="glance-item-image rounded" height="250"
        width="400" />
      <div class="overlay-and-title">
        <template v-if="selectedKey === 'frostVision'">
          <frost-vision-overlay :computerVision="glanceItem.ComputerVision" size="sm"
            :image-url="glanceItem?.ProcessedCameraImageURL" />
        </template>
        <h3 class="text-sm bg-charcoal transparent-80 text-white">{{ device?.Name }}</h3>
      </div>
    </ejs-tooltip>
  </div>
</template>
<script>
import { RouterLink } from 'vue-router'
import frostVisionOverlay from './frostVisionOverlay'
import fallbackImage from './fallbackImageComponent'

export default {
  components: {
    frostVisionOverlay,
    fallbackImage,
    RouterLink,
  },
  props: {
    device: {
      type: Object,
      default: null,
    },
    glanceItem: {
      type: Object,
      default: null,
    },
    deviceId: {
      type: String,
    },
    selectedKey: {
      type: String,
    },
  },
  computed: {
    routerLink() {
      const query = { ...this.$route.query, sensorId: this.deviceId };
      return {
        query,
      }
    },
  },
};

</script>