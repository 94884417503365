<template>
    <div id="main-notification" class="main-notification">
        <ejs-tooltip ref="tooltip" cssClass="page-notif-cont" opensOn="Custom" :showTipPointer="false"
            :position="position" :isSticky="false" :offsetX="offsetX" :offsetY="offsetY" :content="content"
            :created="created" :animation="animation">
            <div class="fa fa-bell text-charcoal" @click="handleBellClick">
                <div v-if="hasNotification" class="notif-counter"></div>
            </div>
        </ejs-tooltip>
    </div>
</template>

<script lang="js">
import Vue from "vue";
import { mapStores } from "pinia";
import moment from "moment-timezone";
import { useBaseStore } from "../../js/store";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import ImageFullScreen from "./ImageFullScreen.vue";

import refreshIcon from "../../images/svg/refresh.svg";
import app from "../../js/app";

export default {
    name: "page-notification",
    data: function () {
        return {
            notifPollTimer: null,
            groupId: null,
            animation: {
                open: { effect: "FadeIn", duration: 50, delay: 0 },
                close: { effect: "FadeOut", duration: 50, delay: 0 },
            },
            content: function () {
                return {
                    template: Vue.component("pageNotificationPopup", {
                        template: `
                            <div class="page-notifs" :class="loadingClass">
                                <div class="notif-header">
                                    <h3>Notifications</h3>
                                    <ejs-button cssClass='e-small e-flat e-refresh' @click.native="refresh" :disabled="isLoading">
                                        <img :src="refreshIcon" alt="Refresh" />
                                    </ejs-button>
                                </div>

                                <div class="loader" v-if="isLoading">
                                    <clip-loader :loading="true"></clip-loader>
                                </div>

                                <div class="empty-notif" v-if="!isLoading && !hasNotification">
                                    <h3 style="margin: 0;">No new notifications</h3>
                                    <p v-if="!groupId">Please select a Group in the Dashboard page first to view the notifications.</p>
                                </div>

                                <div class="notif-content" v-if="!isLoading && hasNotification">
                                    <div v-for="(notif, index) in notifications" :key="index">
                                        <div class="notif-detail">
                                            <image-full-screen
                                                v-if="notif.ProcessedCameraImageURL"
                                                :src="notif.ProcessedCameraImageURL"
                                            />
                                            <div>
                                                <h4>{{ notif.RequestTypeDisplayName }}</h4>
                                                <span style="color: #6D6E71; font-size: 14px;">{{ notif.description }}</span>
                                            </div>
                                        </div>
                                        <div class="device-detail dm-flex-row dm-align-items-flex-end dm-margin-top-sm">
                                            <div class="dm-flex-column dm-flex-1">
                                                <span class="dm-flex-1 dm-text-ellipsis" style="max-width: 185px;" demoSmart><b>Group: </b>{{ notif.GroupName }}</span>
                                                <span class="dm-flex-1 dm-text-ellipsis" style="max-width: 185px;"><b>Device: </b>{{ notif.DeviceName }}</span>
                                            </div>
                                            <span style="color: #B5B5B5; text-align: right;">{{ notif.dateTime }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `,
                        components: {
                            ClipLoader,
                            ImageFullScreen
                        },
                        data() {
                            return {
                                refreshIcon: refreshIcon,
                                fullscreen: false,
                            };
                        },
                        computed: {
                            ...mapStores(useBaseStore),
                            groupId() {
                                return this.baseStore.notifications.groupID;
                            },
                            notifications() {
                                return this.baseStore.notifications.items;
                            },
                            isLoading() {
                                return this.baseStore.notifications.isLoading;
                            },
                            loadingClass() {
                                return this.isLoading ? "loading" : "";
                            },
                            hasNotification() {
                                return this.notifications.length > 0;
                            },
                        },
                        methods: {
                            refresh() {
                                eventBus.$emit('refreshNotification');
                            }
                        },
                    }),
                };
            },
        };
    },
    computed: {
        ...mapStores(useBaseStore),
        hasNotification() {
            return Object.keys(this.baseStore.notifications.items).length > 0;
        },
        isMobile() {
            return window.innerWidth < 666;
        },
        position() {
            return this.isMobile ? "BottomCenter" : "BottomLeft";
        },
        offsetX() {
            return this.isMobile ? -50 : 50;
        },
        offsetY() {
            return this.isMobile ? 0 : 0;
        },
    },
    methods: {
        handleBellClick(args) {
            if (args.target.getAttribute('data-tooltip-id')) {
                this.$refs.tooltip.close();
            } else {
                this.$refs.tooltip.open(args.target);
            }
        },
        created: function () {
            const mainElement = document.getElementById('app');
            mainElement.addEventListener("click", this.onClick);
            mainElement.addEventListener("scroll", this.onScroll);

            if (this.isMobile) mainElement.addEventListener("touchmove", this.onScroll);
        },
        onClick: function (args) {
            if (!args) return;

            const isBell = args.target?.parentNode?.parentNode?.parentNode?.classList.contains("main-notification");
            if (!isBell) {
                if (this.$refs.tooltip && document.querySelector(".page-notif-cont")) {
                    this.$refs.tooltip.close();
                }
            }
        },
        onScroll: function () {
            if (this.$refs.tooltip && document.querySelector(".page-notif-cont")) {
                this.$refs.tooltip.close();
            }
        },
        parseDescription(notif) {
            switch (notif.NotificationType) {
                case "REQUEST_PHOTO_STARTED":
                    return "Photo has been requested.";
                case "REQUEST_PHOTO_COMPLETED":
                    return "Photo has been received.";
                case "REQUEST_STORM_STARTED":
                    return "Storm mode is on.";
                case "REQUEST_STORM_COMPLETED":
                    return "Storm mode is off.";
                case "REQUEST_DEFROST_STARTED":
                    return "Defrost has been initiated.";
                case "REQUEST_DEFROST_COMPLETED":
                    return "Defrost has been completed.";
                case "REQUEST_WORK_STARTED":
                    return "Proof of work has been started.";
                case "REQUEST_WORK_COMPLETED":
                    return "Proof of work report is complete.";
            }
        },
        fetchActiveRequests() {
            if (!this.groupId) return;

            this.baseStore.notifications.isLoading = true;
            return DM.http(
                {
                    method: "GET",
                    url: `/notifications?notifyDateTime=${moment.utc().format('yyyy-MM-DD')}&groupID=${this.groupId}`,
                },
                true,
                true,
            ).then((response) => {
                this.baseStore.notifications.isLoading = false;
                if (response.length > 0) {
                    this.baseStore.notifications.items = response
                        .sort((a, b) => moment(b.NotifyDateTimeUTC).diff(a.NotifyDateTimeUTC))
                        .filter((e) => {
                            const now = moment.utc();
                            const history = moment.utc(e.NotifyDateTimeUTC);
                            const hours = moment.duration(now.diff(history)).asHours();
                            return hours <= 24 && hours >= 0;
                        })
                        .map((e) => {
                            e.description = this.parseDescription(e);
                            e.dateTime = moment
                                .utc(e.NotifyDateTimeUTC)
                                .tz(e.TimeZone)
                                .fromNow();
                            return e;
                        });
                } else {
                    this.baseStore.notifications.items = [];
                }
            });
        },
    },
    created() {
        this.notifPollTimer = setInterval(() => {
            this.fetchActiveRequests();
        }, 1000 * app.DASHBOARD_REFRESH_INTERVAL_SECONDS);

        eventBus.$on("getGroupNotification", (groupId) => {
            this.groupId = groupId;
            this.baseStore.notifications.groupID = groupId;
            this.fetchActiveRequests();
        });

        eventBus.$on("refreshNotification", () => {
            this.fetchActiveRequests();
        });
    },
    beforeDestroy() {
        clearInterval(this.notifPollTimer);
        eventBus.$off('refreshNotification');

        const mainElement = document.getElementById('app');
        mainElement.removeEventListener("click", this.onClick);
        mainElement.removeEventListener("scroll", this.onScroll);

        if (this.isMobile) mainElement.removeEventListener("touchmove", this.onScroll);
    },
};
</script>

<style>
.main-notification .bell-container {
    position: relative;
    display: flex;
    cursor: pointer;
}

.main-notification .bell-container img {
    width: 25px;
    height: 25px;
}

.main-notification .notif-counter {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #03beca;
}

.page-notif-cont .e-btn.e-refresh>img {
    width: 15px;
    height: 15px;
    rotate: 270deg;
}


.page-notif-cont.e-tooltip-wrap.e-popup {
    filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.25));
    background: #d4d4d4;
    border: none;
    opacity: 1;
    z-index: 90 !important;
}

.page-notif-cont.e-tooltip-wrap .e-tip-content {
    color: black;
    font-size: 12px;
    line-height: 20px;
    padding: 0 !important;
}

.page-notif-cont.e-tooltip-wrap .e-tooltip-close {
    display: none !important;
}

.page-notif-cont .page-notifs {
    display: flex;
    flex-direction: column;
    gap: 1px;
    max-height: 400px;
    min-width: 300px;
    max-width: 320px;
}

.page-notif-cont .page-notifs h4 {
    margin: 0;
}

.page-notif-cont .notif-header {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
    background: #fff;
    overflow: hidden;
    height: 60px;
    align-items: center;
}

.page-notif-cont .e-btn.e-filter {
    text-transform: capitalize;
    text-decoration: underline;
    padding: 10px 5px;
}

.page-notif-cont .e-btn.e-refresh {
    display: flex;
    align-items: center;
    padding: 10px;
}

.page-notif-cont .notif-header h3 {
    margin: 0;
    flex: 1;
}

.page-notif-cont .page-notifs .notif-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    max-height: 349px;
    -webkit-overflow-scrolling: touch;
}

.page-notif-cont .notif-content>div {
    padding: 16px 24px;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-top: 1px solid #d4d4d4;
    max-height: 349px;
}

.page-notif-cont .notif-detail {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 10px;
}

.page-notif-cont .notif-detail img {
    width: 70px;
    height: 60px;
    cursor: pointer;
    border-radius: 5px;
}

.page-notif-cont .device-detail {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.page-notif-cont .device-detail span {
    color: #6d6e71;
    font-size: 12px;
}

.page-notif-cont .notif-image {
    border-radius: 6px;
    width: 42px;
    height: 42px;
}

.page-notif-cont .page-notifs.loading .loader {
    height: 349px;
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.page-notif-cont .page-notifs .empty-notif {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    background: #fff;
    overflow: hidden;
    height: 349px;
    justify-content: center;
    text-align: center;
}
</style>
