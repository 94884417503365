import { AwsRum } from "aws-rum-web";

const IDENTITY_POOL_ID = process.env.VUE_APP_CLOUDWATCH_POOL_ID;
const ENDPOINT = process.env.VUE_APP_CLOUDWATCH_ENDPOINT;
const APPLICATION_ID = process.env.VUE_APP_CLOUDWATCH_APPLICATION_ID;
const APPLICATION_VERSION = "1.0.0";
const APPLICATION_REGION = "us-east-2";

function initializeCloudWatch() {
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: IDENTITY_POOL_ID,
      endpoint: ENDPOINT,
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: true,
    };

    return new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    console.log("Error initializing CloudWatch", error);
  }
}
export default initializeCloudWatch;
