<template>
	<div
		class="login-page dm-page-container dm-flex-column dm-padding-10 dm-justify-content-center dm-align-items-center app-sign-in-bg">
		<div class="app-sign-in-container dm-flex-column dm-align-items-center dm-bg-color-white" style="width: 500px;">
			<div class="dm-flex-column dm-padding-right-20 e-control e-css dm-margin-top-lg dm-margin-bottom-lg"
				style="width: 250px;">
				<div class="dm-flex-column dm-align-items-center">
					<img class="app-header-logo" src="../images/frost-logo-horizontal-black-new.png" alt="frost-logo" />
				</div>

				<form class="dm-flex-column dm-margin-top-md" @submit="signIn">
					<div class="dm-margin-bottom-md">
						<ejs-textbox v-model="emailAddress" floatLabelType="Auto" placeholder="Email Address" required>
						</ejs-textbox>
					</div>

					<div class="dm-margin-bottom-md">
						<ejs-textbox v-model="password" type="password" floatLabelType="Auto" placeholder="Password" required>
						</ejs-textbox>
					</div>

					<div class="dm-flex-row dm-justify-content-center">
						<ejs-button type="submit" is-primary="true" :style="'width: 100px'">
							Login
						</ejs-button>
					</div>

					<div class="dm-flex-row dm-justify-content-center dm-margin-top-lg">
						<router-link to="/forgot-password">I forgot my password</router-link>
					</div>
				</form>

			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { event } from 'vue-gtag';
import { mapStores } from 'pinia';

import { useBaseStore } from '../js/store';

export default {
	name: 'sign-in-page',
	data: function () {
		return {
			emailAddress: '',
			password: '',
		}
	},
	computed: {
		...mapStores(useBaseStore)
	},
	methods: {
		signIn(e) {
			e.preventDefault();
			DM.http({
				method: "POST",
				url: "/users/auth-tokens",
				data: {
					Email: this.emailAddress.trim(),
					Password: this.password
				}
			}).then(response => {
				DM.localStorageSet(window.app.localStorageAuthTokenName, response.AuthToken);
				DM.localStorageSet(window.app.localStorageUserIDName, response.UserID);

				this.baseStore.setAuthentication();
				this.baseStore.user = response;

				event('login', {
					'user_id': response.UserID,
				});

				if (response.IsTempPassword) {
					eventBus.$emit('showAppModal', this, 'usersPasswordEditModal', {
						callback: (data) => {
							DM.localStorageSet(window.app.localStorageAuthTokenName, data.AuthToken);
							DM.localStorageSet(window.app.localStorageUserIDName, data.UserID);

							this.baseStore.setAuthentication();
							this.baseStore.user = data;

							this.$router.push({ name: 'sensors' });
						}
					});
				} else {
					this.$router.push({ name: 'sensors' });
				}
			});
		},
	},
	created() { }
}
</script>

<style>
.login-page {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	flex-direction: column;
}
</style>