<template>
  <div class="dm-flex-column dm-flex-1 dm-padding-sm history-table">
    <h3>Transmissions</h3>
    <dm-table ref="grdSensorReadings" :table="{
      grdTableItems: grdSensorReadingsItems,
      grdTableFields: grdSensorReadingsFields,
      grdTableActions: grdSensorReadingsActions,
    }" :allowPaging="true" :pageSettings="{ pageSize: 10, pageCount: 10 }" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBaseStore, useLocationsStore } from '../../js/store';
import hasPhoto from '../../images/svg/has-photo.svg';
import exportPhoto from '../../images/svg/export-photo.svg';
import exportWhite from '../../images/svg/export-white.svg';
import exportText from '../../images/svg/export-text.svg';
import photoInfo from '../../images/svg/photo.svg';
import infoIcon from '../../images/svg/info.svg';

import { convertFahrenheitToCelcius, formatChartDate, formatDate, formatDecimal, getTransmissionDateTimeLocal } from '../../js/utils';

export default {
  name: 'sensors-history-table',
  props: [
    "history",
    "roadConditionsHistory",
    "temperatureUnit",
  ],
  data: function () {
    return {
      infoIcon: infoIcon,
      photoInfo: photoInfo,
      exportText: exportText,
      exportWhite: exportWhite,
      hasPhoto: hasPhoto,
      exportPhoto: exportPhoto,
      lblTitle: '',
      componentKey: 0,
      grdSensorRequestFields: [],
      grdSensorReadingsFields: [],
      grdSensorReadingsActions: [
        {
          text: 'Edit'
        },
        {
          text: 'Delete'
        }
      ],
      selectedRow: null,
      grdActions: [
        {
          text: 'Export'
        },
      ],
      showDiagnosticCodes: false,
      sensorHistoryData: null,
      requestWorkData: null,
    }
  },
  methods: {
    mnuChartsOnClick() { },
    mnuReadingsOnClick() { },
    grdActionOnClick(args) {
      if (args.item.text === 'Edit') {

      }
    },
    defineColumns() {
      this.grdSensorReadingsFields = [
        {
          key: 'chartDate',
          label: 'Time (' + this.baseStore?.timeZone + ')',
          type: 'custom',
          width: '160px'
        },
        {
          key: 'AirTemp',
          label: 'Air Temp (' + this.tempUnit + ')',
          width: '140px',
          type: 'custom',
          format: { format: "####.#'°'" },
          textAlign: 'Right'
        },
        {
          key: 'SurfaceTemp',
          label: 'Surface Temp (' + this.tempUnit + ')',
          width: '140px',
          type: 'custom',
          format: { format: "####.#'°'" },
          textAlign: 'Right'
        },
        {
          key: 'DewPoint',
          label: 'Dew Point (' + this.tempUnit + ')',
          width: '140px',
          type: 'custom',
          format: { format: "####.#'°'" },
          textAlign: 'Right'
        },
        {
          key: 'Humidity',
          label: 'Humidity',
          width: '110px',
          type: 'custom',
          format: { format: "####.#'%'" },
          textAlign: 'Right'
        },
        {
          key: 'Photo',
          label: 'Photo',
          allowSorting: false,
          type: 'template',
          template: () => {
            return {
              template: Vue.component('columnTemplate', {
                template: `<div v-if="this.showDownloadImageIcon" class="dm-flex-row dm-align-items-center">
                                            <i @click="btnDownloadImageOnClick" class="fa fa-download dm-padding-left-sm dm-pointer"></i>
                                        </div>`,
                methods: {
                  btnDownloadImageOnClick() {
                    const { ID, TransmissionDateTimeUTC, ProcessedCameraImageURL } = this.data;


                    let fileName = `${formatDate(TransmissionDateTimeUTC)}.jpg`;
                    fileName = fileName.replaceAll(' ', '-').replaceAll(',', '').replaceAll(':', '');
                    axios({
                      method: 'GET',
                      url: ProcessedCameraImageURL,
                      responseType: 'blob'
                    }).then(response => {
                      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
                      let a = document.createElement("a");
                      document.body.appendChild(a);
                      a.href = objectUrl;
                      a.download = fileName;
                      a.click();
                      document.body.removeChild(a);
                    });
                  },
                },
                computed: {
                  image: function () {
                    return this.data.ProcessedCameraImageURL ? this.data.ProcessedCameraImageURL : null;
                  },
                  showDownloadImageIcon: function () {
                    return this.data.ProcessedCameraImageURL ? true : false;
                  }
                }
              }),
            }
          },
          width: '90px',
        },
      ];

    },
  },
  computed: {
    ...mapStores(useBaseStore, useLocationsStore),
    isUserSuperAdmin() {
      return this.baseStore.user?.IsSuperAdmin;
    },
    grdSensorReadingsItems() {
      return this.history.filter(r => !r.IsForecast)
    },
    tempUnit() {
      return this.temperatureUnit;
    },
  },
  created() {
    this.defineColumns();
  },
  mounted() {
    let defineColumns;
    window.onresize = () => {
      clearTimeout(defineColumns);
      defineColumns = setTimeout(this.defineColumns, 100);
    };
  },
}
</script>

<style>
.history-table .e-grid {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}
</style>