export default {
  props: ['modalRefGuid'],
  data() {
    return {
      ref: null,
      sfTarget: document.getElementById('app'),
      modalRef: null
    }
  },
  methods: {
    destroy() {
      this.$destroy()
    }
  },
  watch: {
    modalRef: function() {
      this.modalRef.show()

      this.modalRef.$on('close', () => {
        eventBus.$emit('closeAppModal', {
          modalRefGuid: this.modalRefGuid,
          modalName: this.modalName
        })
      })
    }
  },
  created() {},
  mounted() {
    try {
      this.savedInitialData = {}
      for (let property in this.$data) {
        if (
          !(
            this.$data[property] &&
            typeof this.$data[property] === 'object' &&
            // eslint-disable-next-line no-prototype-builtins
            this.$data[property].hasOwnProperty('innerHTML')
          )
        ) {
          this.savedInitialData[property] = this.$data[property]
        }
      }
    } catch (error) {
      console.log(error)
    }

    this.modalRef = this.$refs[this.modalName]
  }
}
