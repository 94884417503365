<template>
	<div :id="sensor.ID"
			 :class="`sidebar-notif sibebar-tooltip-${sensor.ID}`">
		<ejs-tooltip ref="tooltip"
								 opensOn="Custom"
								 :cssClass="`sidebar-notif-cont sidebar-popup-${sensor.ID}`"
								 :showTipPointer="false"
								 :isSticky="false"
								 :offsetX="offsetX"
								 :offsetY="-13"
								 :content="content"
								 :created="created"
								 :position="position"
								 :animation="animation">
			<div class="bell-container"
					 @click="handleBellClick">
				<img :src="bellIcon"
						 alt="Notification" />
				<div v-if="hasNotification"
						 class="notif-counter"></div>
			</div>
		</ejs-tooltip>
	</div>
</template>

<script>
import Vue from "vue";
import { mapStores } from "pinia";
import moment from "moment-timezone";
import { useBaseStore } from "../../js/store";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import bellIcon from "../../images/svg/notification-bell.svg";

export default {
	name: "sidebar-notification",
	props: ["sensor"],
	data: function () {
		const self = this;

		return {
			bellIcon: bellIcon,
			content: function () {
				return {
					template: Vue.component("sidebarNotificationPopup", {
						template: `
                            <div class="sidebar-notifs" :class="loadingClass">
                                <h3>Active Requests</h3>
                                <div class="loader" v-if="isLoading">
                                    <clip-loader :loading="true"></clip-loader>
                                </div>

                                <div class="empty-notif" v-if="!isLoading && !hasNotification">
                                    <h3 style="margin: 0;">No active requests</h3>
                                </div>

                                <div class="notif-content"  v-if="!isLoading && hasNotification">
                                    <div v-for="(notif, index) in notifications">
                                        <h4>{{ notif.DisplayName }}</h4>
                                        <span style="color: #6D6E71;">{{ notif.description }}</span>
                                        <span style="color: #B5B5B5; text-align: right; font-size: 12px;">{{ notif.dateTime }}</span>
                                    </div>
                                </div>
                            </div>
                        `,
						components: {
							ClipLoader,
						},
						data() {
							return {
								sensor: self.sensor,
								isLoading: false,
								notifications: [],
							};
						},
						methods: {
							parseDescription(notif) {
								switch (notif.RequestTypeCode) {
									case "DEVICE_REQUEST_PHOTO":
										if (notif.IsComplete) return "Photo has been received.";
										else return "Photo has been requested.";
									case "EVENT_REQUEST_WORK":
										if (notif.IsComplete) return "Proof of work report is complete.";
										else return "Proof of work has been started.";
									case "DEVICE_REQUEST_DEFROST":
										return "Defrost has been initiated.";
									case "DEVICE_REQUEST_STORM":
										return "Storm mode is on.";
								}
							},
							fetchActiveRequests() {
								this.isLoading = true;
								return DM.http(
									{
										method: "GET",
										url: `/devices/${this.sensor.ID}/activeRequests`,
									},
									true,
									true,
								).then((response) => {
									this.isLoading = false;
									if (response.length > 0) {
										this.notifications = response?.map((e) => {
											e.dateTime = moment
												.utc(e.StartDateTimeUTC)
												.tz(this.sensor.TimeZone)
												.fromNow();

											e.description = this.parseDescription(e);
											return e;
										});
									} else {
										this.notifications = [];
									}
								});
							},
						},
						computed: {
							...mapStores(useBaseStore),
							loadingClass() {
								return this.isLoading ? "loading" : "";
							},
							hasNotification() {
								return this.notifications.length > 0;
							},
						},
						created() {
							this.fetchActiveRequests();
						},
					}),
				};
			},
			animation: {
				open: { effect: "FadeIn", duration: 50, delay: 0 },
				close: { effect: "FadeOut", duration: 50, delay: 0 },
			},
		};
	},
	computed: {
		...mapStores(useBaseStore),
		hasNotification() {
			return true;
		},
		isMobile() {
			return window.innerWidth < 666;
		},
		position() {
			return this.isMobile ? "BottomLeft" : "BottomRight";
		},
		offsetX() {
			return this.isMobile ? 4 : -4;
		},
	},
	methods: {
		handleBellClick(args) {
			if (args.target.getAttribute('data-tooltip-id')) {
				this.$refs.tooltip.close();
			} else {
				this.$refs.tooltip.open(args.target);
			}
		},
		created() {
			const app = document.getElementById("app");
			const sidebar = document.querySelector(".app-sidebar");

			if (app) {
				app.addEventListener("click", this.onClick);
			}

			if (sidebar) {
				sidebar.addEventListener("scroll", this.onScroll);
				if (this.isMobile) sidebar.addEventListener("touchmove", this.onScroll);
			}
		},
		onClick(args) {
			if (args && !args.target?.parentNode?.parentNode?.parentNode?.classList.contains(`sibebar-tooltip-${this.sensor.ID}`)) {
				if (this.$refs.tooltip && document.querySelector(`.sidebar-popup-${this.sensor.ID}`)) {
					this.$refs.tooltip.close();
				}
			}
		},
		onScroll() {
			if (this.$refs.tooltip && document.querySelector(`.sidebar-popup-${this.sensor.ID}`)) {
				this.$refs.tooltip.close();
			}
		},
	},
	beforeDestroy() {
		const app = document.getElementById("app");
		const sidebar = document.querySelector(".app-sidebar");

		if (app) {
			app.removeEventListener("click", this.onClick);
		}
		if (sidebar) {
			sidebar.removeEventListener("scroll", this.onScroll);
			if (this.isMobile) sidebar.removeEventListener("touchmove", this.onScroll);
		}
	}
};
</script>

<style>
.sidebar-notif .bell-container {
	position: relative;
	display: flex;
	cursor: pointer;
}

.sidebar-notif .bell-container img {
	width: 17px;
	height: 17px;
}

.sidebar-notif .notif-counter {
	position: absolute;
	top: 1px;
	right: 1px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #03beca;
}

.sidebar-notif-cont.e-tooltip-wrap.e-popup {
	filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.25));
	background: #d4d4d4;
	border: none;
	opacity: 1;
}

.sidebar-notif-cont.e-tooltip-wrap .e-tip-content {
	color: black;
	font-size: 12px;
	line-height: 20px;
	padding: 0 !important;
}

.sidebar-notif-cont .sidebar-notifs {
	display: flex;
	flex-direction: column;
	gap: 1px;
	max-height: 300px;
}

.sidebar-notif-cont .sidebar-notifs h3 {
	background: #fff;
	margin: 0;
	padding: 10px 24px;
}

.sidebar-notif-cont .notif-content {
	display: flex;
	flex-direction: column;
	gap: 1px;
	width: 250px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.sidebar-notif-cont .notif-content>div {
	padding: 16px 24px;
	background-color: #ffffff;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.sidebar-notif-cont .sidebar-notifs h4 {
	margin: 0;
}

.sidebar-notif-cont .sidebar-notifs.loading {
	height: 134px;
	width: 250px;
}

.sidebar-notif-cont .sidebar-notifs.loading .loader {
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;

	padding: 16px 24px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
}

.sidebar-notif-cont .sidebar-notifs .empty-notif {
	display: flex;
	flex-direction: column;
	padding: 24px 24px;
	background: #fff;
	overflow: hidden;
	justify-content: center;
	text-align: center;
}
</style>
