import { reduce, round, forEach, add, isObject, isArray, clamp } from "lodash";
import * as Yup from "yup";

import {
  convertCelciusToFahrenheit,
  convertFahrenheitToCelcius,
  convertMilimetersToInches,
  convertWindSpeed,
  formatChartDate,
  formatDecimal,
  convertPrecipRate,
  formatPrecip,
  formatWindDirection,
  parseRoadCondition,
  parseWindSpeedUnit,
} from "./utils";
import { computerVisionMap } from "./constants";
import {
  dataTransmissionsSchema,
  forecastItemSchema,
  mostRecentDataSchema,
} from "./schemas";

const snowDepthReferenceAndInches = (distanceMm, obj, { data }) => {
  const refDepthMm = data.ReferenceDepthMm;
  const measuredHeightMm = clamp(distanceMm - data.ReferenceDepthMm, 0, 999999);
  obj.MeasuredHeightMm = round(measuredHeightMm, 0);
  obj.DistanceMm = round(distanceMm, 0);
  obj.ReferenceDepthMm = round(refDepthMm, 0);

  obj.DistanceInches = round(convertMilimetersToInches(distanceMm), 1);
  obj.MeasuredHeightInches = round(
    convertMilimetersToInches(measuredHeightMm),
    1
  );
  obj.ReferenceDepthInches = round(convertMilimetersToInches(refDepthMm), 1);
  return obj;
};

const addBatteryPercentage = (mv, obj) => {
  // 3600 mV is 100%. 3400 is 75%, 3100 is 50%. 3000 is 30%. 2800 or below is 10% and needs replacing
  const percentage = Math.round(((mv - 2800) / 800) * 100);
  obj.batteryPercentage = percentage;
  return obj;
};

const addImageCaptureDateTimeLocal = (utc, obj, { timeZone }) => {
  obj.ImageCaptureDateTimeUTC = utc;
  obj.imageCaptureDateTimeLocal = formatChartDate(utc);
  return obj;
};

const addTransmissionDateTimeLocal = (utc, obj, { timeZone }) => {
  obj.TransmissionDateTimeUTC = utc;
  obj.chartDate = formatChartDate(utc);
  return obj;
};

// This is used for road conditions and forecasts both
const addForecastDateTimeLocal = (utc, obj, { timeZone }) => {
  obj.IsForecast = obj.IsForecast === false ? false : true;
  obj.TransmissionDateTimeUTC = utc;
  obj.chartDate = formatChartDate(utc);
  return obj;
};

const formatCV = (val, obj, { key }) => {
  if (val > 0.5) {
    obj[key] = round(val * 100, 0);
  } else {
    delete obj[key];
  }
  return obj;
};

const convertWindValues = (val, obj, { data }) => {
  if (data.WindSpeed) {
    obj.WindSpeed = convertWindSpeed(data.WindSpeed, data.IsMetric);
  }
  if (data.WindDirection) {
    obj.WindDirection = formatWindDirection(data.WindDirection);
  }
  if (typeof obj.WindSpeed === "number" && obj.WindDirection) {
    obj.Wind = `${obj.WindSpeed} ${parseWindSpeedUnit(data.IsMetric)} ${obj.WindDirection}`;
  }
  return obj;
};

const convertPrecipRateV2 = (val, obj, { isMetric, data, key }) => {
  if (!val) {
    obj[key] = val;
    return obj;
  }
  if (!isMetric) {
    obj[key] = formatPrecip(val);
    return obj;
  }
  obj[key] = formatPrecip(val / 0.3937);
  return obj;
};

const handleTemps = (val, obj, { key, tempUnit, data }) => {
  // Check if this is a forecast object
  if (data.ForecastDateTimeUTC) {
    // Forecast needs to be converted from Celcius to Fahrenheit
    obj[key] = formatDecimal(convertCelciusToFahrenheit(val, tempUnit));
  } else {
    // Data transmission is already in Fahrenheit
    obj[key] = formatDecimal(val);
  }
  return obj;
};

const addParsedRoadCondition = (val, obj) => {
  obj.RoadCondition = val;
  obj.ParsedRoadCondition = parseRoadCondition(val);
  return obj;
};

const transformerMap = {
  // Readings
  SurfaceTemp: handleTemps,
  AirTemp: handleTemps,
  DewPoint: handleTemps,
  Humidity: formatDecimal,
  // Snow Depth
  BatteryMv: addBatteryPercentage,
  DistanceMm: snowDepthReferenceAndInches,
  // Date Times
  ImageCaptureDateTimeUTC: addImageCaptureDateTimeLocal,
  TransmissionDateTimeUTC: addTransmissionDateTimeLocal,
  ForecastDateTimeUTC: addForecastDateTimeLocal,
  // Computer Vision
  ClearPavement: formatCV,
  Cloudy: formatCV,
  DaySnowing: formatCV,
  IcedLens: formatCV,
  Night: formatCV,
  NightSnowing: formatCV,
  PartialSnowOnRoad: formatCV,
  Raining: formatCV,
  SnowOnRoad: formatCV,
  Snowing: formatCV,
  Sunny: formatCV,
  WetPavement: formatCV,
  // Forecast
  WindSpeed: convertWindValues,
  SnowRate: convertPrecipRateV2,
  RainRate: convertPrecipRateV2,
  MixedRate: convertPrecipRateV2,
  RoadCondition: addParsedRoadCondition,
};

const validateData = async (schema, data) => {
  try {
    // Validate the data against the schema
    const validatedData = await schema.validate(data, { stripUnknown: true });
    return validatedData;
  } catch (error) {
    console.error("Data transformation error:", error);
    return null;
  }
};

const transformData = (data, transformer, options) => {
  // Check if the input data is an object or an array
  if (isObject(data) || isArray(data)) {
    return reduce(
      data,
      (acc, val, key) => {
        // Check if the current key has a transformer defined
        if (transformer.hasOwnProperty(key)) {
          // Apply the transformation for the current key
          const t = transformer[key](val, acc, { ...options, key, data });
          if (isObject(t) || isArray(t)) {
            acc = t;
          } else {
            acc[key] = t;
          }
        } else if (isObject(val) || isArray(val)) {
          // Recursively call transformData for nested objects or arrays
          acc[key] = transformData(val, transformer, { ...options, key, data });
        } else {
          // If no transformation is defined, retain the original value
          acc[key] = val;
        }
        return acc;
      },
      isArray(data) ? [] : {} // Use an empty array or object as the initial accumulator based on input type
    );
  }

  // If the input data is not an object or array, return it as-is
  return data;
};

export const transformMostRecentData = async (data, options) => {
  const validated = await validateData(mostRecentDataSchema, data);
  if (!validated) {
    return null;
  }
  const transformed = await transformData(validated, transformerMap, options);
  return transformed;
};

export const transformForecastData = async (forecastData, options) => {
  const validated = await validateData(forecastItemSchema, forecastData);
  if (!validated) {
    return null;
  }
  const transformed = await transformData(validated, transformerMap, options);
  return transformed;
};

export const transformDataTransmissions = async (transmissionData, options) => {
  const validated = await validateData(
    dataTransmissionsSchema,
    transmissionData
  );
  if (!validated) {
    return null;
  }
  const transformed = await transformData(validated, transformerMap, options);
  return transformed;
};

export const transformTransmissionOrRoadCondition = (
  transmission,
  { tempUnit, currentCondition },
  isPhotosOnly = false
) => {
  const r = { ...transmission };
  const isMetric = tempUnit !== "Fahrenheit";
  r.SurfaceGrip = Math.round((r?.SurfaceGrip ?? 0) * 100) / 100;

  if (r.hasOwnProperty("AirTemp") && r.AirTemp !== 0) {
    r.AirTemp = r.AirTemp;
  }
  if (r.hasOwnProperty("AirTempt")) {
    r.AirTemp = r.AirTempt;
  }
  r.SurfaceTemp = r.SurfaceTemp;
  if (r.Humidity) {
    r.Humidity = formatDecimal(r?.Humidity);
  }
  if (r.DewPoint) {
    r.DewPoint = r.DewPoint;
  }
  if (currentCondition) {
    r.WindSpeed = convertWindSpeed(currentCondition.WindSpeed, isMetric);
    r.WindDirection = formatWindDirection(currentCondition.WindDirection);
  } else {
    r.WindSpeed = convertWindSpeed(r.WindSpeed, isMetric);
  }

  if (r.hasOwnProperty("ComputerVision")) {
    r.ComputerVision = cleanComputerVisionData(r.ComputerVision);
  }

  r.SnowRate = convertPrecipRate(r.SnowRate, isMetric);
  r.RainRate = convertPrecipRate(r.RainRate, isMetric);
  r.MixedRate = convertPrecipRate(r.MixedRate, isMetric);
  if (r.hasOwnProperty("ImageCaptureDateTimeUTC")) {
    r.ImageCaptureLocal = formatChartDate(r.ImageCaptureDateTimeUTC);
  }
  if (r.hasOwnProperty("ForecastDateTimeUTC")) {
    r.ForecastDateTimeLocal = formatChartDate(r.ForecastDateTimeUTC);
    r.chartDate = formatChartDate(r.ForecastDateTimeUTC);
  }
  if (r.TransmissionDateTimeUTC) {
    r.chartDate = formatChartDate(r.TransmissionDateTimeUTC);
  }
  return r;
};

export const transformRequestData = (requestData, { timeZone }) => {
  const tz = timeZone || "America/Chicago";
  const r = { ...requestData };
  if (r.hasOwnProperty("StartDateTimeUTC")) {
    r.startDateTimeFormatted = formatChartDate(r.StartDateTimeUTC);
  }
  if (r.hasOwnProperty("EndDateTimeUTC")) {
    if (r.EndDateTimeUTC === null) {
      r.endDateTimeFormatted = "--";
    } else {
      r.endDateTimeFormatted = formatChartDate(r.EndDateTimeUTC);
    }
  }
  return r;
};

export const cleanComputerVisionData = (cv) => {
  const ret = reduce(
    cv,
    (acc, val, key) => {
      if (val < 0.5) {
        return acc;
      }
      acc[key] = round(val * 100, 1);
      return acc;
    },
    {}
  );
  return ret;
};

export const transformDateTimeMissingZ = (dateString) => {
  return moment(dateString, true).utc(true).tz("GMT").toDate();
};
