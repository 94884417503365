<template>
  <div class="forecast-tiles dm-flex-column dm-padding-sm">
    <div class="dm-flex-row"
         style="gap: 10px">
      <forecast-container :id="location.ID"
                          label="Current"
                          :isTablet="isTablet"
                          :weatherContent="parseTextWeatherConditionIcon(currentWeather, weatherHours, currentCondition?.ForecastMinutes, false)"
                          :weatherIcon="parseWeatherConditionIcon(currentWeather, weatherHours)"
                          :roadContent="parseRoadTextConditionIcon(currentCondition)"
                          :roadIcon="parseRoadConditionIcon(currentCondition?.RoadCondition)"
                          :loading="loading"></forecast-container>
      <forecast-container :id="location.ID"
                          label="Forecast"
                          :isTablet="isTablet"
                          :weatherContent="parseTextWeatherConditionIcon(forecastWeather, weatherHours, weatherCondition?.ForecastMinutes, true)"
                          :weatherIcon="parseWeatherConditionIcon(forecastWeather, weatherHours, true)"
                          :weatherForecastTime="parseHoursMinHover(weatherCondition?.ForecastMinutes).text"
                          :roadContent="parseRoadTextConditionIcon(roadCondition)"
                          :roadIcon="parseRoadConditionIcon(roadCondition?.RoadCondition)"
                          :roadForecastTime="parseHoursMinHover(roadCondition?.ForecastMinutes).text"
                          :precipAccumulation="parsePrecipAccumulation(precipSummary, isMetric)"
                          :loading="loading"></forecast-container>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import {
  parseRoadConditionIcon,
  parseRoadTextConditionIcon,
  parseWeatherConditionIcon,
  parseTextWeatherConditionIcon,
  parsePrecipAccumulation,
  parseHoursMinHover,
  getHours,
} from '../js/utils';

import forecastContainer from "./forecastContainer.vue";

export default {
  name: "forecast-tiles",
  props: [
    "location",
    "currentCondition",
    "currentWeather",
    "roadCondition",
    "weatherCondition",
    "precipSummary",
    "forecastWeather",
    "timeZone",
    "loading",
    "isTablet",
    "isMetric"
  ],
  components: {
    forecastContainer,
  },
  data: function () {
    return {
    };
  },
  computed: {
    weatherHours() {
      return parseHoursMinHover(this.currentWeather?.ForecastDateTimeUTC, this.timeZone);
    },
    roadHours() {
      return getHours(this.roadCondition?.ForecastDateTimeUTC, this.timeZone);
    },
  },
  methods: {
    parseRoadConditionIcon,
    parseRoadTextConditionIcon,
    parseWeatherConditionIcon,
    parseTextWeatherConditionIcon,
    parsePrecipAccumulation,
    parseHoursMinHover,
    getHours,
    onCustomOpen(refs, args) {
      const target = args.target.closest('.tooltip-cont-mobile');
      if (target.getAttribute('data-tooltip-id')) {
        refs[0].close();
      } else {
        eventBus.$emit('removeForecastTooltip');
        refs[0].open(target);
      }
    },
  },
  mounted() {
    eventBus.$on('removeForecastTooltip', () => {
      Object.values(this.$refs).forEach((ref) => {
        ref?.forEach((tooltip) => {
          if (typeof tooltip.close === 'function') {
            tooltip.close();
          }
        })
      });
    })
  },
  beforeDestroy() {
    eventBus.$off('removeForecastTooltip');
  }
};
</script>
