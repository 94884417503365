// Computer Vision Constants
export const weatherMap = {
  Snowing: "Snowing",
  Raining: "Raining",
  Sunny: "Sunny",
  Cloudy: "Cloudy",
};
export const surfaceMap = {
  SnowOnRoad: "Snow",
  PartialSnowOnRoad: "Partial Snow",
  ClearPavement: "Clear",
  WetPavement: "Wet",
};
export const otherMap = {
  Night: "Night",
  IcedLens: "Iced Lens",
};

export const computerVisionMap = {
  ...weatherMap,
  ...surfaceMap,
  ...otherMap,
};
