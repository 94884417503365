<template>
	<div>
		<div v-observe-visibility="{
			callback: displayChart,
			once: true,
		}" :style="{ height: chartHeight, display: 'flex', flexDirection: 'column', overflow: 'hidden' }" width="100%">
			<ejs-chart background="transparent" ref="chartJS" :height="chartHeight" :id="chartKey + sensor?.ID"
				:key="chartKey + sensor?.ID" width="100%" :primaryXAxis="primaryXAxis" :tooltip="tooltip"
				:crosshair="crosshair" :axes="axes" :palettes="palettes" :tooltipRender="tooltipRender"
				:chartMouseLeave="beforeTooltipClose" :legendSettings="legendSettings || { visible: false }"
				v-if="display">
				<e-series-collection>
					<!-- CoF -->
					<e-series :dataSource="roadChartData" type="Scatter" xName="chartDate" yName="SurfaceGrip"
						:enableTooltip="true" yAxisName="yAxisSurfaceGrip" tooltipMappingName="chartDate"
						tooltipFormat="CoF: <b>${point.y}</b>" />
					<!-- Wind Speed -->
					<e-series :dataSource="roadChartData" type="Scatter" xName="chartDate" yName="WindSpeed"
						:enableTooltip="true" yAxisName="yAxisWindSpeed" tooltipMappingName="WindDirection"
						:tooltipFormat="windSpeedTooltip" />

					<!-- Precipitation Rate -->
					<template v-if="hasSnowRate">
						<e-series :dataSource="roadChartData" type="SplineArea" xName="chartDate" yName="SnowRate"
							name="Snow Rate" yAxisName="yAxisPrecipRate" tooltipMappingName="chartDate" />
					</template>
					<template v-if="hasRainRate">
						<e-series :dataSource="roadChartData" type="SplineArea" xName="chartDate" yName="RainRate"
							name="Rain Rate" yAxisName="yAxisPrecipRate" tooltipMappingName="chartDate" />
					</template>
					<template v-if="hasMixedRate">
						<e-series :dataSource="roadChartData" type="SplineArea" xName="chartDate" yName="MixedRate"
							name="Mixed Rate" yAxisName="yAxisPrecipRate" tooltipMappingName="chartDate" />
					</template>

					<!-- Temperature -->
					<e-series :dataSource="chartData" type="Spline" splineType="Monotonic" xName="chartDate"
						yName="SurfaceTemp" name="Surface" width="3" yAxisName="yAxisTemperature"
						tooltipMappingName="chartDate" />
					<e-series :dataSource="chartData" type="Spline" splineType="Monotonic" xName="chartDate"
						yName="AirTemp" name="Air" width="3" yAxisName="yAxisTemperature"
						tooltipMappingName="chartDate" />

					<!-- Road Condition -->
					<e-series v-if="dry.show" :dataSource="dry.chart" type="StackingColumn" name="Road: Dry"
						xName="chartDate" yName="value" yAxisName="yAxisRoadCondition" tooltipMappingName="chartDate"
						tooltipFormat="<b>Dry</b>" :columnWidth="roadConditionWidth" :columnSpacing="0" />
					<e-series v-if="wet.show" :dataSource="wet.chart" type="StackingColumn" name="Road: Wet"
						xName="chartDate" yName="value" yAxisName="yAxisRoadCondition" tooltipMappingName="chartDate"
						tooltipFormat="<b>Wet</b>" :columnWidth="roadConditionWidth" :columnSpacing="0" />
					<e-series v-if="slush.show" :dataSource="slush.chart" type="StackingColumn" name="Road: Slush"
						xName="chartDate" yName="value" yAxisName="yAxisRoadCondition" tooltipMappingName="chartDate"
						tooltipFormat="<b>Slush</b>" :columnWidth="roadConditionWidth" :columnSpacing="0" />
					<e-series v-if="snow.show" :dataSource="snow.chart" type="StackingColumn" name="Road: Snow"
						xName="chartDate" yName="value" yAxisName="yAxisRoadCondition" tooltipMappingName="chartDate"
						tooltipFormat="<b>Snow</b>" :columnWidth="roadConditionWidth" :columnSpacing="0" />
					<e-series v-if="mixed.show" :dataSource="mixed.chart" type="StackingColumn" name="Road: Icy"
						xName="chartDate" yName="value" yAxisName="yAxisRoadCondition" tooltipMappingName="chartDate"
						tooltipFormat="<b>Icy</b>" :columnWidth="roadConditionWidth" :columnSpacing="0" />
					<e-series v-if="black.show" :dataSource="black.chart" type="StackingColumn" name="Road: Black Ice"
						xName="chartDate" yName="value" yAxisName="yAxisRoadCondition" tooltipMappingName="chartDate"
						tooltipFormat="<b>Black Ice</b>" :columnWidth="roadConditionWidth" :columnSpacing="0" />
				</e-series-collection>
			</ejs-chart>
		</div>
		<fixed-tooltip :highlighted="tooltipValues" :items="tooltipItems" />
	</div>
</template>

<script>
import {
	Category,
	ChartAnnotation,
	Tooltip,
	DateTime,
	Zoom,
	Legend,
	Logarithmic,
	Crosshair,
	LineSeries,
	SplineSeries,
	StripLine,
	AreaSeries,
	ScatterSeries,
	SplineAreaSeries,
	StackingColumnSeries,
	AccumulationTooltip,
} from "@syncfusion/ej2-vue-charts";
import { mapStores } from "pinia";
import moment from "moment-timezone";
import { capitalize } from 'lodash';

import fixedTooltip from "./fixedTooltip";

import { formatDecimal, formatWindDirection, isTabletScreen, parseRoadConditionChart } from "../js/utils";
import { useBaseStore } from "../js/store";

export default {
	name: "sensor-chart",
	props: [
		'chartKey',
		"sensor",
		"chartHeight",
		"dataTransmissions",
		"roadConditionsHistory",
		"forecastData",
		'legendSettings',
		'forceUpdate',
		"mostRecentTransmission",
		"showGridView",
		"showSensorView",
	],
	components: {
		fixedTooltip,
	},
	provide: {
		chart: [
			LineSeries,
			Tooltip,
			DateTime,
			StripLine,
			SplineSeries,
			SplineAreaSeries,
			Logarithmic,
			Crosshair,
			AreaSeries,
			ScatterSeries,
			AccumulationTooltip,
			StackingColumnSeries,
			Legend,
		],
	},
	data: function () {
		return {
			crosshair: { enable: true, lineType: "Vertical", line: { width: 0.5, color: "#5fb1f6" } },
			tooltip: {
				enable: true,
				shared: true,
				enableAnimation: false,
				header: "${point.tooltip}",
				css: "hidden",
			},
			display: false,
			tooltipDate: "",
			nowValue: "",
			tooltipItems: [
				{
					title: 'Air Temperature',
					key: 'AirTemp',
					abbr: 'Air',
				},
				{
					title: 'Surface Temperature',
					key: 'SurfaceTemp',
					abbr: 'Sur',
				},
				{
					title: 'Humidity',
					key: 'Humidity',
					abbr: 'H',
				},
				{
					title: 'Dew Point',
					key: 'DewPoint',
					abbr: 'D',
				},
				{
					title: 'Wind Speed and Direction',
					key: 'Wind',
					abbr: 'W',
				},
				{
					title: 'Coeficient of Friction',
					key: 'SurfaceGrip',
					abbr: 'COF',
				},
				{
					title: 'Road Condition',
					key: 'ParsedRoadCondition',
					abbr: 'Cond',
				},
				{
					title: 'Snow Rate',
					key: 'SnowRate',
					abbr: 'Snow',
				},
				{
					title: 'Rain Rate',
					key: 'RainRate',
					abbr: 'Rain',
				},
				{
					title: 'Mixed Rate',
					key: 'MixedRate',
					abbr: 'Mixed',
				}
			]
		};
	},
	methods: {
		beforeTooltipClose() {
			this.tooltipDate = "";
		},
		tooltipRender(args) {
			this.tooltipDate = args.headerText ? args.headerText : args.point.tooltip;
			// Hide tooltip but keep crosshair
			args.cancel = true;
		},
		displayChart(isVisible) {
			if (isVisible) {
				this.display = true;
			}
		},
		findNowValue() {
			const nowMoment = moment()
			let diff = 999999
			let tempDate = ''
			this.chartData.forEach((item) => {
				const itemMoment = moment(item.chartDate, "MMM D, YY h:mm A")
				const d = Math.abs(nowMoment.diff(itemMoment, 'minutes'))
				if (d < diff) {
					diff = d
					tempDate = item.chartDate
				}
				if (d > diff) {
					this.nowValue = tempDate
					return false
				}
			})
		},
		findDataPointWithin(data, chartDate, minutes) {
			const selectedMoment = moment(chartDate, "MMM D, YY h:mm A")
			return data.find((e) => {
				if (e.chartDate === chartDate) {
					return e
				}
				const chartDateMoment = moment.utc(e.TransmissionDateTimeUTC)
				const minutesDiff = Math.abs(selectedMoment.diff(chartDateMoment, 'minutes'))
				if (minutesDiff <= minutes) {
					return e
				}
			})
		}
	},
	computed: {
		...mapStores(useBaseStore),
		tooltipValues() {
			// It should return the now data which is essential the most recent data
			const chartDate = this.tooltipDate === '' ? this.nowValue : this.tooltipDate
			if (chartDate === '') return


			let chartData = this.findDataPointWithin(this.chartData, chartDate, 1)
			if (!chartData) {
				chartData = this.findDataPointWithin(this.chartData, chartDate, 5)
			}
			if (!chartData) {
				chartData = this.findDataPointWithin(this.chartData, chartDate, 15)
			}
			if (!chartData) {
				chartData = this.findDataPointWithin(this.chartData, chartDate, 30)
			}
			if (!chartData) {
				chartData = this.findDataPointWithin(this.chartData, chartDate, 60)
			}

			let roadData = this.findDataPointWithin(this.roadChartData, chartDate, 1)
			if (!roadData) {
				roadData = this.findDataPointWithin(this.roadChartData, chartDate, 5)
			}
			if (!roadData) {
				roadData = this.findDataPointWithin(this.roadChartData, chartDate, 15)
			}
			if (!roadData) {
				roadData = this.findDataPointWithin(this.roadChartData, chartDate, 30)
			}
			if (!roadData) {
				roadData = this.findDataPointWithin(this.roadChartData, chartDate, 60)
			}
			const windUnit = this.tempUnit === 'F' ? 'mph' : 'km/hr'

			const AirTemp = chartData?.AirTemp ? `${chartData.AirTemp}°${this.tempUnit}` : null;
			const SurfaceTemp = chartData?.SurfaceTemp ? `${chartData.SurfaceTemp}°${this.tempUnit}` : null;
			const Humidity = chartData?.Humidity ? `${chartData.Humidity}%` : null;
			const DewPoint = chartData?.DewPoint ? `${chartData.DewPoint}°${this.tempUnit}` : null;
			const SurfaceGrip = roadData?.SurfaceGrip ? roadData.SurfaceGrip : null;
			const Wind = roadData?.WindSpeed !== undefined ? `${roadData?.WindSpeed}${windUnit}` : null;
			const ParsedRoadCondition = capitalize(roadData?.ParsedRoadCondition) ?? null;
			const values = {
				chartDate: chartData?.chartDate ? chartData.chartDate + ' ' + this.baseStore.timeZone : roadData?.chartDate + ' ' + this.baseStore.timeZone,
				AirTemp,
				SurfaceTemp,
				Humidity,
				DewPoint,
				SurfaceGrip,
				Wind,
				ParsedRoadCondition,
				SnowRate: roadData?.SnowRate ? `${roadData.SnowRate} ${this.precipRateUnit}` : null,
				RainRate: roadData?.RainRate ? `${roadData.RainRate} ${this.precipRateUnit}` : null,
				MixedRate: roadData?.MixedRate ? `${roadData.MixedRate} ${this.precipRateUnit}` : null,
			}
			// Clear out any null values
			Object.keys(values).forEach((key) => {
				if (!values[key]) delete values[key];
			});
			return values;
		},
		roadConditionWidth() {
			const dt = this.dataTransmissions.filter((e) => e.TransmissionData)
			if (dt.length === 0) return 1;
			return 61;
		},
		isMobile() {
			return isTabletScreen(true)
		},
		tempUnit() {
			return this.sensor?.GroupEmbedded?.TemperatureUnits === "Celsius" ? "C" : "F";
		},
		timeZone() {
			return this.sensor.TimeZone;
		},
		palettes() {
			let palettes = ["transparent", "transparent",];

			if (this.hasSnowRate) {
				palettes.push("#d8d2f8");
			}
			if (this.hasRainRate) {
				palettes.push("#a6d8eb");
			}
			if (this.hasMixedRate) {
				palettes.push("#caf4f6");
			}

			palettes = [...palettes, "black", "#319cf4"];

			if (this.dry.show) {
				palettes.push("#666971");
			}
			if (this.wet.show) {
				palettes.push("#c2d689");
			}
			if (this.slush.show) {
				palettes.push("#c8efb6");
			}
			if (this.snow.show) {
				palettes.push("#fababa");
			}
			if (this.mixed.show) {
				palettes.push("#ffd597");
			}
			if (this.black.show) {
				palettes.push("#e98f8f");
			}

			return palettes;
		},
		primaryXAxis() {
			const now = moment()
			const nowPlusTen = moment().add(10, 'minutes')
			const startDate = moment().startOf('day')
			const endDate = moment().startOf('day').add(10, 'minutes')
			const firstMidnight = moment().startOf('day').add(1, 'day')
			const firstMidnightPlusTen = moment().startOf('day').add(1, 'day').add(10, 'minutes')
			const secondMidnight = moment().startOf('day').add(2, 'day')
			const secondMidnightPlusTen = moment().startOf('day').add(2, 'day').add(10, 'minutes')
			const secondMidnightText = moment().startOf('day').add(2, 'day').format("MMM D")
			const thirdMidnight = moment().startOf('day').add(3, 'day')
			const thirdMidnightPlusTen = moment().startOf('day').add(3, 'day').add(10, 'minutes')
			const thirdMidnightText = moment().startOf('day').add(3, 'day').format("MMM D")
			return {
				visible: true,
				color: "#5fb1f6",
				valueType: "DateTime",
				labelFormat: "h a",
				intervalType: "Hours",
				minimum: moment().subtract(4, 'hours'),
				labelStyle: {
					size: "12px",
				},
				title: false,
				crosshairTooltip: { enable: false },
				majorGridLines: { color: "transparent" },
				majorTickLines: { color: "transparent" },
				stripLines: [
					{
						start: now,
						end: nowPlusTen,
						color: "#26a172",
						visible: true,
						sizeType: "Minutes",
						zIndex: "Over",
						rotation: 0,
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
					{
						start: startDate,
						end: endDate,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: "Today",
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},

					{
						start: firstMidnight,
						end: firstMidnightPlusTen,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: "Tomorrow",
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
					{
						start: secondMidnight,
						end: secondMidnightPlusTen,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: secondMidnightText,
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
					{
						start: thirdMidnight,
						end: thirdMidnightPlusTen,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: thirdMidnightText,
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
				],
			};
		},
		axes() {
			if (!this.roadChartData) return [];
			const snowRate = this.roadChartData.map((r) => r.SnowRate);
			const rainRate = this.roadChartData.map((r) => r.RainRate);
			const mixedRate = this.roadChartData.map((r) => r.MixedRate);

			const surfaceTemps = this.chartData.map((r) => r.SurfaceTemp);
			const airTemps = this.chartData.map((r) => r.AirTemp);

			const rates = [...snowRate, ...rainRate, ...mixedRate].map((e) => {
				if (!e) e = 0;
				return e;
			});
			const temps = [...surfaceTemps, ...airTemps].map((e) => {
				if (!e) e = 0;
				return e;
			});

			const max = Math.max(...rates);

			let stripLines = [
				{
					start: this.sensor?.GroupEmbedded?.IsMetric ? 0.76 : 0.3,
					end: this.sensor?.GroupEmbedded?.IsMetric ? 0.760001 : 0.300001,
					color: "#6290d4",
					visible: true,
					text: "Heavy",
					zIndex: "Over",
					rotation: 0,
					textStyle: {
						size: 14,
						color: "#26a172",
					},
					horizontalAlignment: "End",
				},
			];

			if (max <= 1) {
				stripLines = [
					...stripLines,
					{
						start: this.sensor?.GroupEmbedded?.IsMetric ? 0.13 : 0.05,
						end: this.sensor?.GroupEmbedded?.IsMetric ? 0.1300001 : 0.05000001,
						color: "#6290d4",
						visible: true,
						text: "Light",
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						horizontalAlignment: "End",
					},
				];
			}

			return [
				{
					visible: true,
					opposedPosition: true,
					labelFormat: `{value} ${this.precipRateUnit}`,
					name: "yAxisPrecipRate",
					labelStyle: {
						color: "transparent",
						size: 0,
					},
					roundPadding: "Additional",
					minimum: -0.03,
					maximum: max < 0.3 ? 0.5 : max + 0.1,
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
					stripLines: stripLines,
				},
				{
					visible: true,
					labelFormat: `{value}° ${this.tempUnit}`,
					name: "yAxisTemperature",
					title: false,
					roundPadding: "Additional",
					minimum: Math.trunc(Math.min(...temps) - 5),
					maximum: Math.trunc(Math.max(...temps) + 5),
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
				{
					visible: false,
					name: "yAxisRoadCondition",
					roundPadding: "Additional",
					minimum: 0,
					maximum: .5,
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
				{
					visible: false,
					name: "yAxisSurfaceGrip",
					roundPadding: "Additional",
					minimum: 0,
					maximum: 1,
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
				{
					visible: false,
					name: "yAxisWindSpeed",
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
			];
		},
		filteredData() {
			const now = moment().utc()
			const startDate = moment().utc().subtract(4, 'hours')
			const chartLimit = this.baseStore.preferences.chartLimit || 72
			const endDate = moment().utc().add(chartLimit, 'hours')
			let transmissions = []
			this.roadConditionsHistory.forEach((rc) => {
				transmissions.push({
					...rc,
					AirTemp: null,
					SurfaceTemp: null,
				});
			});
			this.dataTransmissions.forEach((dt) => {
				if (!dt.TransmissionData) return
				transmissions.push({
					...dt.TransmissionData,
					...dt,
					TransmissionDateTimeUTC: dt.TransmissionDateTimeUTC,
					chartDate: dt.chartDate,
				});
			});
			transmissions = transmissions.concat(this.forecastData)
			//Remove all chart data except 4 hours before now and 72 after now
			transmissions = transmissions.map((e) => {
				const datetime = moment(e.TransmissionDateTimeUTC, true).utc()
				// Forecasts should not be included before now
				if (e.IsForecast && datetime.isBefore(now)) {
					return {
						...e,
						AirTemp: null,
						SurfaceTemp: null,
					}
				} else
					if (datetime.isBetween(startDate, endDate)) {
						return e
					}
			})
				.filter(Boolean)
			transmissions = transmissions.sort((a, b) =>
				moment.utc(a.TransmissionDateTimeUTC).diff(b.TransmissionDateTimeUTC)
			)
			return transmissions
		},
		chartData() {
			const now = moment()
			const transmissions = this.filteredData.reduce((arr, e, index) => {
				if (e.AirTemp === null && e.SurfaceTemp === null) {
					return arr
				}
				if (now.diff(moment(e.chartDate, "MMM D, YY h:mm A"), 'minute') < 10) {
					this.initialIndex = index
				}
				arr.push({
					chartDate: e.chartDate,
					AirTemp: e.AirTemp ?? null,
					SurfaceTemp: e.SurfaceTemp ?? null,
					Humidity: e.Humidity ?? null,
					DewPoint: e.DewPoint ?? null,
					ParsedRoadCondition: e.ParsedRoadCondition ?? null,
					TransmissionDateTimeUTC: e.TransmissionDateTimeUTC,
				})

				return arr
			}, [])
			return transmissions
		},
		roadChartData() {
			let transmissions = []
			this.roadConditionsHistory.forEach((rc) => {
				transmissions.push({
					...rc,
					AirTemp: null,
					SurfaceTemp: null,
				});
			});
			transmissions = transmissions.concat(this.forecastData)
			//Remove all chart data except 4 hours before now and the limit after now
			const chartLimit = this.baseStore.preferences.chartLimit || 72
			transmissions = transmissions.map((e) => {
				const datetime = moment.utc(e.TransmissionDateTimeUTC, true)
				if (datetime.isBefore(moment().add(chartLimit, 'hours'))) {
					return e
				}
			})
				.filter(Boolean)
			transmissions = transmissions.reduce((acc, e, i, arr) => {
				// If this matches any others, skip it
				const a = acc.filter((t) => (t.chartDate === e.chartDate))
				if (a.length > 0) return acc

				let rc = { ...e }
				// Precip Rate
				const next = arr[i + 1]
				const prev = arr[i - 1]
				if (!rc.SnowRate || rc.SnowRate <= 0) {
					if (next?.SnowRate > 0 || prev?.SnowRate > 0) rc.SnowRate = 0
					else rc.SnowRate = null
				}
				if (!rc.RainRate || rc.RainRate <= 0) {
					if (next?.RainRate > 0 || prev?.RainRate > 0) rc.RainRate = 0
					else rc.RainRate = null
				}
				if (!rc.MixedRate || rc.MixedRate <= 0) {
					if (next?.MixedRate > 0 || prev?.MixedRate > 0) rc.MixedRate = 0
					else rc.MixedRate = null
				}

				// Road Conditions chart height
				rc.value = 0.03

				acc.push({
					chartDate: rc.chartDate,

					// Precip Rate Data
					SnowRate: rc.SnowRate,
					RainRate: rc.RainRate,
					MixedRate: rc.MixedRate,

					// Road Condition Data
					SurfaceGrip: rc.SurfaceGrip,
					ParsedRoadCondition: rc.ParsedRoadCondition,
					value: rc.value,

					// Wind Speed
					WindSpeed: formatDecimal(rc.WindSpeed),
					WindDirection: formatWindDirection(rc.WindDirection),

					TransmissionDateTimeUTC: rc.TransmissionDateTimeUTC,
				})

				return acc
			}, [])
			return transmissions
		},
		// Precip 
		hasMixedRate() {
			return this.roadChartData.filter((e) => e.MixedRate > 0).length > 0;
		},
		hasSnowRate() {
			return this.roadChartData.filter((e) => e.SnowRate > 0).length > 0;
		},
		hasRainRate() {
			return this.roadChartData.filter((e) => e.RainRate > 0).length > 0;
		},
		// Road Conditions
		dry() {
			return parseRoadConditionChart(this.roadChartData, 'dry');
		},
		wet() {
			return parseRoadConditionChart(this.roadChartData, 'wet');
		},
		slush() {
			return parseRoadConditionChart(this.roadChartData, 'slush');
		},
		mixed() {
			return parseRoadConditionChart(this.roadChartData, 'mixed');
		},
		snow() {
			return parseRoadConditionChart(this.roadChartData, 'snow');
		},
		black() {
			return parseRoadConditionChart(this.roadChartData, 'black');
		},

		precipRateUnit() {
			return this.sensor.GroupEmbedded.IsMetric ? "cm/hr" : "in/hr"
		},
		windSpeedTooltip() {
			const unit = this.sensor.GroupEmbedded.IsMetric ? "km/hr" : "mph";
			return "Wind: <b>${point.y} " + unit + " ${point.tooltip}</b>";
		},
	},
	watch: {
		chartData: {
			handler() {
				this.findNowValue()
			},
			deep: true,
		},
		roadChartData: {
			handler() {
				this.findNowValue()
			},
			deep: true,
		},
		showSensorView() {
			// TODO: Maybe move these to a single place rather than each one firing it off
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 1000);
		},
		showGridView() {
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 1000);
		},
	},
	mounted() {
		// HACK: This is the only way I have been able to find to get the chart to resize
		// after any shifting of the page on load. This can be removed when we change the
		// chart library or figure out how to resize the chart properly.
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 2000);
	},
};
</script>
