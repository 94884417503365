<template>
    <div class="flex-column space-between flex-1-1-auto">
        <div class="flex-column">
            <router-link :to="routerLink" class="device-title" exactActiveClass="text-charcoal">
                <h3 class="text-lg">{{ location.Name }}</h3>
            </router-link>
            <div class="flex flex-row flex-wrap">
                <div v-if="snowDepth" class="forecast-section">
                    <div class="forecast-block">
                        <img class="forecast-tile-icon" :src="snowDepthIcon" alt="Snow Depth Icon" />
                        <div class="forecast-text-block">
                            <span>Snow Depth</span>
                            <span>{{ snowDepth.snowDepthText }}</span>
                        </div>
                    </div>
                    <div class="mt-2">
                        <span class="uppercase mr-2">Read:</span>
                        <span class="bold right">{{ snowDepth.timeSinceRead }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-column">
            <div class="preview-image-wrapper">
                <image-with-overlay :data-transmission="mostRecent?.MostRecentDataTransmission"
                    :computer-vision="mostRecent?.MostRecentComputerVision" :alt="location.Name + ' preview'"
                    :temperature-unit="temperatureunit" :is-metric="isMetric" :offline="location.IsOffline"
                    :hide-readings="true" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from "pinia";
import { useBaseStore } from "../js/store";
import { RouterLink } from "vue-router";

import { isTabletScreen } from "../js/utils";
import imageWithOverlay from "./imageWithOverlay.vue";
import snowDepthIcon from "../images/snow-depth-icon.svg";
import moment from "moment";

export default {
    name: "grid-sensor-item",
    props: [
        "location",
        "mostRecent",
        "showGridView"
    ],
    components: {
        imageWithOverlay,
        RouterLink,
    },
    data: function () {
        return {
            storeLocations: [],
            loading: false,
            snowDepthIcon,
            forceUpdate: Date.now(),
        };
    },
    methods: {
        openSensor() {
            if (this.location.ID.toString() === this.$route.query.sensorId) {
                return;
            }
            this.$router.push({ query: { ...this.$route.query, sensorId: this.location.ID } })
        },
    },
    computed: {
        ...mapStores(useBaseStore),
        isUserSuperAdmin() {
            return this.baseStore.user?.IsSuperAdmin
        },
        routerLink() {
            const query = { ...this.$route.query, sensorId: this.location.ID }
            return {
                query,
            }
        },
        isTablet() {
            return isTabletScreen(true)
        },
        temperatureunit() {
            return this.location?.GroupEmbedded?.TemperatureUnits == "Fahrenheit" ? "°F" : "°C";
        },
        isMetric() {
            return this.location?.GroupEmbedded?.IsMetric;
        },
        snowDepth() {
            const mostRecentSnowDepth = this.mostRecent?.MostRecentSnowDepthReading;
            if (!mostRecentSnowDepth || Object.keys(mostRecentSnowDepth).length === 0) {
                return null;
            }
            const sd = this.isMetric ? mostRecentSnowDepth?.MeasuredHeightMm + ' mm' : mostRecentSnowDepth?.MeasuredHeightInches + ' in';
            const timeSinceRead = moment(mostRecentSnowDepth?.TransmissionDateTimeUTC).fromNow();
            return {
                snowDepthText: sd,
                timeSinceRead,
            }
        }
    },
};
</script>