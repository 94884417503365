<template>
    <ejs-dialog :id="modalName" :target="sfTarget" :ref="modalName" :header="lblTitle" isModal="true"
        showCloseIcon="true" :width="'500px'" visible="false" allowDragging="true" cssClass="scrollable">

        <div class="dm-flex-column dm-overflow-y-auto dm-padding-md">

            <div class="dm-flex-row dm-margin-bottom-md">

                <qrcode-stream @decode="onDecode" @init="onInit">
                </qrcode-stream>

            </div>

        </div>

        <div class="dm-flex-row dm-justify-content-flex-end dm-margin-md">
            <div>
                <ejs-button @click.native="btnCancelOnClick">
                    Cancel
                </ejs-button>
            </div>
        </div>

    </ejs-dialog>
</template>

<script>
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
    name: 'qr-code-scan-modal',
    mixins: [dmModalMixin],
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    },
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'qrCodeScanModal' + DM.getRandomGuid(),
            lblTitle: 'Scan QR Code',
            emailAddress: '',
        }
    },
    methods: {
        btnCancelOnClick() {
            this.modalRef.hide();
        },
        onDecode(decodedString) {
            // alert(decodedString);
            this.modalData.callback(decodedString);
            this.modalRef.hide();
        },
        async onInit(promise) {
            // show loading indicator
            try {
                const { capabilities } = await promise
                // alert(JSON.stringify(capabilities));
                // successfully initialized
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    // user denied camera access permisson
                    alert('user denied camera access permisson');
                } else if (error.name === 'NotFoundError') {
                    // no suitable camera device installed
                } else if (error.name === 'NotSupportedError') {
                    // page is not served over HTTPS (or localhost)
                } else if (error.name === 'NotReadableError') {
                    // maybe camera is already in use
                } else if (error.name === 'OverconstrainedError') {
                    // did you requested the front camera although there is none?
                } else if (error.name === 'StreamApiNotSupportedError') {
                    // browser seems to be lacking features
                }
            } finally {
                // hide loading indicator
            }
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>
