<template>
  <div
       class="login-page dm-page-container dm-flex-column dm-padding-10 dm-justify-content-center dm-align-items-center app-sign-in-bg">
    <div class="app-sign-in-container dm-flex-column dm-align-items-center dm-bg-color-white"
         style="width: 500px;">
      <div class="dm-flex-column dm-padding-right-20 e-control e-css dm-margin-top-lg dm-margin-bottom-lg"
           style="width: 250px;">
        <div class="dm-flex-column dm-align-items-center">
          <img class="app-header-logo"
               src="../images/frost-logo-horizontal-black-new.png"
               style="width: 280px;" />
        </div>

        <form class="dm-flex-column"
              @submit="sendNewPassword">
          <h3 class="dm-text-center">Forgot Password</h3>
          <p class="dm-text-center">Enter the email address associated with your account</p>

          <div class="dm-margin-bottom-md dm-margin-top-md">
            <ejs-textbox v-model="emailAddress"
                         floatLabelType="Auto"
                         placeholder="Email Address"
                         required>
            </ejs-textbox>
          </div>

          <ejs-button type="submit"
                      is-primary="true">
            SEND RESET EMAIL
          </ejs-button>

          <div class="dm-flex-row dm-justify-content-center dm-margin-top-lg">
            <a href="/login">Back</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBaseStore } from '../js/store';

export default {
  name: 'sign-in-page',
  data: function () {
    return {
      emailAddress: '',
    }
  },
  computed: {
    ...mapStores(useBaseStore)
  },
  methods: {
    sendNewPassword(e) {
      e.preventDefault();
      DM.http({
        method: "POST",
        url: "/users/password-reset",
        params: {
          email: this.emailAddress.trim(),
        }
      })
    }
  },
}
</script>