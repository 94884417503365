export default {
  signedInUser: null,
  localStorageAuthTokenName: 'signIn.FrostDevicePortalAuthToken',
  localStorageUserIDName: 'signIn.FrostDevicePortalUserID',
  localStoragePreferencesName: 'user.FrostDevicePortalPreferences',
  userID: null,
  DASHBOARD_REFRESH_INTERVAL_SECONDS: 600,
  LATEST_IMAGE_REFRESH_INTERVAL_SECONDS: 60,
  MAX_RETRY_COUNT: 5,
  RETRY_DELAY: 1000,
  getUserPosition(resolve, reject) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords)
        },
        (error) => {
          reject({
            error:
              'We could not find your location. You may need to allow location services for your browser or application.'
          })
        },
        {
          timeout: 10000
        }
      )
    } else {
      DM.alertShow('Geolocation is not enabled on this browser')
      reject({
        error: 'Geolocation is not enabled on this browser.'
      })
    }
  },
  openRequestPhotoModal(getRequestID, cellData, scope) {
    const deviceRequestApi = DM.http(
      {
        method: 'GET',
        url: `/device-requests/${getRequestID}`
      },
      false,
      true
    )

    Promise.all([deviceRequestApi]).then(([response]) => {
      if (!response.message) {
        eventBus.$emit('showAppModal', scope, 'sensorsPhotoModal', {
          mode: 'view',
          sensor: response,
          cellData: cellData,
          callback: () => {}
        })
      } else {
        DM.alertShow(data.message, 'Details')
      }
    })
  }
}
