<template>
  <form class="search-form" @submit="searchOnClick">
    <div class="e-input-group">
      <ejs-textbox floatLabelType="Auto" showClearButton="true" class="e-small" placeholder="Search"
        :value=locationsStore.searchText @change="onClear"></ejs-textbox>
      <ejs-button type="submit" class="e-input-btn">
        <span class="fa fa-search"></span>
      </ejs-button>
    </div>

  </form>
</template>

<script setup>
import Vue from 'vue';

import { useLocationsStore } from '../js/store';

const locationsStore = useLocationsStore();

// I can't find a way to catch the clear event so I am just checking if the value is empty
const onClear = (val) => {
  if (locationsStore.searchText !== '' && val.value === '') {
    locationsStore.searchText = '';
    locationsStore.getLocations();
  }
};

const searchOnClick = (event) => {
  event.preventDefault();
  const searchText = event.target[0].value;
  locationsStore.searchText = searchText;
  locationsStore.getLocations();
};

</script>