<template>
  <div class="dm-relative w-full" @click="toggleOverlay">
    <div v-if="!hideOverlay" class="datetime-overlay bg-charcoal transparent-80 text-white text-center"
      style="min-width:115px">
      <span>{{ dateTime || '-' }}</span>
    </div>
    <frost-vision-overlay v-if="!hideOverlay" :computer-vision="computerVision" size="lg"
      :image-url="combinedImages[0]" />
    <div v-if="sensorReadingsVisible" class="overlay-wrapper">
      <div v-if="!offline" class="sensor-details-overlay bg-charcoal transparent-80">
        <span class="no-wrap">
          Air:
          <span class="dm-bold mr-2">{{
            airTemp || '--.-' }}{{ temperatureUnit || '°-' }} </span>
        </span>
        <span class="no-wrap">
          Sur:
          <span class="dm-bold mr-2">{{
            surfaceTemp || '--.-' }}{{ temperatureUnit || '°-' }}</span>
        </span>
        <span class="no-wrap">
          H:
          <span class="dm-bold mr-2">{{
            humidity || '--' }}%</span>
        </span>
        <span class="no-wrap">
          D: <span class="dm-bold mr-2">{{
            dewPoint || '--.-' }}{{ temperatureUnit || '°-' }}</span>
        </span>
        <span class="no-wrap">
          W:
          <span class="dm-bold mr-2">
            {{ windSpeed || '--.-' }}{{ windUnit }} {{ windDirection }}
          </span>
        </span>
      </div>
      <div v-else class="sensor-details-overlay bg-charcoal transparent-80">
        <span class="no-wrap">
          Sensor Offline
        </span>
      </div>
    </div>
    <fallback-image :images="combinedImages" class="selected-image" height="500" width="808" />
  </div>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue'
import frostVisionOverlay from './frostVisionOverlay.vue'
import fallbackImage from './fallbackImageComponent.vue'
import { convertWindSpeed, formatWindDirection, parseWindSpeedUnit } from '../js/utils'

const props = defineProps({
  dataTransmission: Object,
  computerVision: Object,
  currentCondition: Object,
  temperatureUnit: String,
  isMetric: Boolean,
  alt: String,
  images: {
    type: Array,
    default: () => [],
  },
  offline: {
    type: Boolean,
    default: false,
  },
  hideReadings: {
    type: Boolean,
    default: false,
  },
})

const hideOverlay = ref(false)
const timeoutHandler = ref(null)

const toggleOverlay = () => {
  hideOverlay.value = !hideOverlay.value
  if (timeoutHandler.value) {
    clearTimeout(timeoutHandler.value)
  }
  timeoutHandler.value = setTimeout(() => {
    hideOverlay.value = false;
  }, 3000)
}

const airTemp = computed(() => props.dataTransmission?.AirTemp)
const surfaceTemp = computed(() => props.dataTransmission?.SurfaceTemp)
const humidity = computed(() => props.dataTransmission?.Humidity)
const dewPoint = computed(() => props.dataTransmission?.DewPoint)

const windSpeed = computed(() => {
  let ws = convertWindSpeed(props.dataTransmission?.WindSpeed, props.isMetric)
  if (props.currentCondition?.WindSpeed) {
    ws = convertWindSpeed(props.currentCondition?.WindSpeed, props.isMetric)
  }
  return ws
})

const windDirection = computed(() => {
  if (props.dataTransmission?.WindDirection) {
    return formatWindDirection(props.dataTransmission?.WindDirection)
  } else {
    return formatWindDirection(props.currentCondition?.WindDirection)
  }
})
const windUnit = computed(() => parseWindSpeedUnit(props.isMetric))

const dateTime = computed(() => {
  let dt = props.dataTransmission?.chartDate
  if (props.dataTransmission?.ImageCaptureLocal) {
    dt = props.dataTransmission?.ImageCaptureLocal
  }
  return dt
})

const computerVision = computed(() => props.computerVision || props.dataTransmission?.ComputerVision)
// Prioritize images passed in, then computer vision, then data transmission
const combinedImages = computed(() => [...props.images, computerVision?.ProcessedCameraImageURL, props.dataTransmission?.ProcessedCameraImageURL].filter(Boolean))

const sensorReadingsVisible = computed(() => {
  if (props.hideReadings === true) {
    return false
  }
  return !hideOverlay.value
})

</script>