<template><ejs-dialog :id="modalName" :target="sfTarget" :ref="modalName" :header="lblTitle" isModal="true"
        showCloseIcon="true" :width="'500px'" visible="false" allowDragging="true" cssClass="scrollable">
        <div id="file-add-modal" class="dm-flex-column dm-overflow-y-auto dm-padding-md">
            <ejs-uploader ref="filesUpload" id="filesUpload" name="filesUpload" :asyncSettings="path"
                :autoUpload="false" :uploading="uploadBefore" :success="uploadSuccess" :multiple="true"
                maxFileSize="209715200">
            </ejs-uploader>
        </div>
    </ejs-dialog>
</template>

<script>
import Vue from 'vue'
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'files-add-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        // TODO: Update API when API is ready
        return {
            modalName: 'filesAddModal' + DM.getRandomGuid(),
            lblTitle: 'Add Files',
            dropElement: '#droparea',
            path: {
                saveUrl: process.env.VUE_APP_API_ROOT + '/Location/CreateAttachments',
            },
            success: false
        }
    },
    methods: {
        uploadSuccess() {
            this.success = true;
        },
        uploadBefore(event, file, name) {
            event.customFormData = [{ 'LocationID': this.modalData.locationID }];
            event.currentRequest.setRequestHeader('Authorization', 'Bearer ' + DM.authToken);

            event.currentRequest.onreadystatechange = function (e) {
                if (event.currentRequest.readyState === XMLHttpRequest.DONE) {
                    var status = event.currentRequest.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                        // console.log('success');
                    } else {
                        console.log(e);
                        DM.alertShow(JSON.parse(e.currentTarget.response).Message, 'Error');
                    }
                }
            }
        }
    },
    created() {

    },
    mounted() {
        this.$refs[this.modalName].$on("close", () => {
            if (this.success) {
                this.modalData.callback();
            }
        });
    }
}
</script>
