<template>
    <BaseModal v-if="modalStore.activeModal === 'addDevice'">
        <div class="max-w-500">
            <span class="subtitle">Device Not Found In System</span>
            <p>
                There was an issue adding your device. Please contact support.
            </p>
            <p>
                Call us at <a href="tel:312-767-9423" class="bold">312-767-9423</a>, <span>Option 0</span>
                </br>
                Email us at <a href="mailto:support@frostsolutions.io" class="bold">support@frostsolutions.io</a>
            </p>
            <div class="bg-lighter p-2">
                <h3 class="mt-0 mb-1">Device Information</h3>

                <div>
                    Device ID: <span class="bold">{{ modalStore.modalProps.id }}</span>
                </div>
                <div>
                    Device Type: <span class="bold">{{ modalStore.modalProps.devicetype || 'Unknown' }}</span>
                </div>
            </div>
        </div>
    </BaseModal>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useModalStore } from '../../js/store';
import BaseModal from './baseModal.vue';

const modalStore = useModalStore();


</script>