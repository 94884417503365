<template>
    <ejs-dialog isModal="true" showCloseIcon="true" visible="false" allowDragging="true" cssClass="scrollable"
        :id="modalName" :target="sfTarget" :ref="modalName" :header="lblTitle" :width="'500px'"
        style="max-height: 100%">
        <div class="dm-flex-column dm-overflow-y-auto dm-padding-md">
            <div class="dm-flex-1 dm-margin-bottom-md">
                <ejs-textbox v-model="name" floatLabelType="Auto" placeholder="Name" />
            </div>

            <div class="dm-flex-1 dm-margin-bottom-md">
                <ejs-textbox v-model="apiKey" floatLabelType="Auto" placeholder="API Key" disabled />
            </div>

            <div class="dm-flex-1 dm-margin-bottom-md">
                <ejs-textbox v-model="address" floatLabelType="Auto" placeholder="Address" />
            </div>

            <div class="dm-flex-1 dm-flex-row dm-margin-bottom-md">
                <div class="dm-flex-1 dm-margin-right-md">
                    <ejs-textbox v-model="city" floatLabelType="Auto" placeholder="City" />
                </div>

                <div class="dm-flex-1">
                    <ejs-textbox v-model="state" floatLabelType="Auto" placeholder="State/Province" />
                </div>
            </div>

            <div class="dm-flex-1 dm-flex-row dm-margin-bottom-md">
                <div class="dm-flex-1 dm-margin-right-md">
                    <ejs-textbox v-model="zip" floatLabelType="Auto" placeholder="Zip Code" autocomplete="off" />
                </div>

                <div class="dm-flex-1">
                    <ejs-textbox v-model="country" floatLabelType="Auto" placeholder="Country" autocomplete="off" />
                </div>
            </div>

            <div class="dm-flex-1 dm-flex-row dm-margin-bottom-md">
                <div class="dm-flex-1">
                    <ejs-dropdownlist v-model="timezone" :dataSource="timezoneOptions" :allowFiltering="true"
                        :fields="{ text: 'text', value: 'value' }" floatLabelType="Auto" placeholder="Time Zone"
                        :ref="'timezoneRef'" :open="() => addSearchIcon($refs.timezoneRef)" />
                </div>
            </div>

            <div class="dm-flex-1 dm-flex-row dm-margin-bottom-md">
                <div class="dm-flex-1 dm-margin-right-md">
                    <ejs-dropdownlist v-model="tempUnits" floatLabelType="Auto" placeholder="Temperature Units"
                        :dataSource="tempUnitsOptions" :allowFiltering="true" :ref="'tempRef'"
                        :open="() => addSearchIcon($refs.tempRef)" />
                </div>

                <div class="dm-flex-1">
                    <ejs-dropdownlist v-model="isMetric" floatLabelType="Auto" placeholder="Measurement Unit"
                        :dataSource="measureUnitOptions" :allowFiltering="true" :ref="'unitRef'"
                        :open="() => addSearchIcon($refs.unitRef)" />
                </div>
            </div>
        </div>

        <div class="dm-flex-row dm-justify-content-space-between dm-margin-md">
            <div>
                <ejs-checkbox v-model="isActive" label="Active" />
            </div>

            <div>
                <ejs-button @click.native="btnSaveOnClick" is-primary="true">
                    Save
                </ejs-button>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
import { useBaseStore } from '../../js/store';

export default {
    name: 'groups-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'groupsEditModal' + DM.getRandomGuid(),
            lblTitle: 'Add Group',
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            tempUnits: 'Fahrenheit',
            tempUnitsOptions: [
                {
                    text: 'Celsius',
                    value: 'Celsius',
                },
                {
                    text: 'Fahrenheit',
                    value: 'Fahrenheit',
                }
            ],
            isMetric: 'Imperial System',
            measureUnitOptions: [
                {
                    text: 'Imperial System',
                    value: 'Imperial System',
                },
                {
                    text: 'Metric System',
                    value: 'Metric System',
                }
            ],
            timezone: 'Eastern Standard Time',
            timezoneOptions: [],
            isActive: true,
            apiKey: ''
        }
    },
    computed: {
        ...mapStores(useBaseStore),
    },
    methods: {
        saveGroup() {
            let isEdit = false;
            let data = DM.copy(this.modalData.group);

            if (this.modalData.mode === 'edit') {
                event('edit_group_click', {
                    'edit_group_id': this.modalData.group.ID
                });

                data.GroupID = this.modalData.group.ID;
                isEdit = true;
            }
            else {
                event('add_group_click');
            }

            Object.assign(data, {
                Name: this.name,
                Address: this.address,
                City: this.city,
                State: this.state,
                ZipCode: this.zip,
                Country: this.country,
                TimeZone: this.timezone || 'Eastern Standard Time',
                TemperatureUnits: this.tempUnits,
                IsFrostTechInternal: false,
                IsActive: this.isActive,
                IsMetric: this.isMetric === 'Metric System' ? 1 : 0,
            });

            return DM.http({
                method: !isEdit ? "POST" : "PUT",
                url: !isEdit ? "/groups" : `/groups/${this.modalData.group.ID}`,
                data: data
            });
        },
        btnSaveOnClick() {
            this.saveGroup()
                .then(() => {
                    this.modalData.callback();
                    this.modalRef.hide();
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        },
        getTimezones() {
            DM.http({
                method: 'GET',
                url: '/groups/time-zones'
            }).then(response => {
                this.timezoneOptions = response.map(t => {
                    return {
                        text: t.Display,
                        value: t.Value
                    }
                });
            });
        },
        addSearchIcon(ref) {
            if (ref) {
                DM.addSearchIconDropDown(ref);
            }
        }
    },
    created() {
        this.getTimezones();

        let group = this.modalData.group;
        this.mode = this.modalData.mode;

        if (this.modalData.mode === 'edit') {
            this.lblTitle = 'Edit Group: ' + this.modalData.group.Name;
            this.name = group.Name;
            this.address = group.Address;
            this.city = group.City;
            this.state = group.State;
            this.zip = group.ZipCode;
            this.country = group.Country;
            this.timezone = group.TimeZone;
            this.tempUnits = group.TemperatureUnits;
            this.isActive = group.IsActive;
            this.apiKey = group.APIKey;
            this.isMetric = group.IsMetric ? "Metric System" : "Imperial System";
        }
    },
}
</script>
