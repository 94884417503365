import VueRouter from "vue-router";
import DashboardPage from "../components/pages/dashboardPage";
import AlertsPage from "../components/pages/alertsPage";
import SettingsPage from "../components/pages/settingsPage";
import SignInPage from "../components/signInPage";
import ForgotPasswordPage from "../components/forgotPasswordPage";

import GroupsPage from "../components/pages/groupsPage";
import LocationsPage from "../components/pages/locationsPage";
import UsersPage from "../components/pages/usersPage";

import app from "./app";
import { useBaseStore } from "./store";
import { set } from "vue-gtag";

const authGuard = async (to, _from, next) => {
  let authToken = DM.localStorageGet(app.localStorageAuthTokenName);
  let userID = DM.localStorageGet(app.localStorageUserIDName);

  set({ user_id: userID });

  if (authToken && userID) {
    const store = useBaseStore();
    store.setAuthentication();

    store.getUserByID(userID).then(() => {
      if (to.name === "login") {
        next({ name: "sensors" });
      } else {
        next();
      }
    });
  } else if (to.name === "login") {
    next();
  } else if (to.name === "forgot-password") {
    next();
  } else {
    next("/login");
  }
};

const permissionGuard = (to, from, next) => {
  const store = useBaseStore();
  let isAdmin = store.user?.Groups?.find((element) => element?.Permission);
  if ((store.user && store.user.IsSuperAdmin) || (store.user && isAdmin)) {
    next();
  } else {
    next({ name: "sensors" });
  }
};

const router = new VueRouter({
  mode: "history",
  hash: false,
  routes: [
    {
      path: "/",
      name: "sensors",
      meta: (route) => {
        if (route.query.sensorId) {
          return {
            title: "Sensor View",
          };
        }
        return {
          title: "Dashboard",
        };
      },
      beforeEnter: authGuard,
      component: DashboardPage,
      props: (route) => ({
        sensorId: route.query.sensorId,
        gridView: route.query.gridView === "true",
        groupId: route.query.groupId,
      }),
    },
    {
      path: "/alerts",
      name: "alerts",
      meta: {
        title: "Alerts",
      },
      beforeEnter: authGuard,
      component: AlertsPage,
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        title: "Settings",
      },
      beforeEnter: authGuard,
      component: SettingsPage,
      children: [
        {
          path: "groups",
          name: "groups",
          meta: {
            title: "Settings: Groups",
          },
          component: GroupsPage,
          beforeEnter: permissionGuard,
        },
        {
          path: "locations",
          name: "locations",
          meta: {
            title: "Settings: Locations",
          },
          component: LocationsPage,
          beforeEnter: permissionGuard,
          props: (route) => ({
            id: route.query.id,
            devicetype: route.query.devicetype || route.query.producttype,
          }),
        },
        {
          path: "users",
          name: "users",
          meta: {
            title: "Settings: Users",
          },
          component: UsersPage,
          beforeEnter: permissionGuard,
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      meta: {
        title: "Login",
      },
      beforeEnter: authGuard,
      component: SignInPage,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      meta: {
        title: "Forgot Password",
      },
      beforeEnter: authGuard,
      component: ForgotPasswordPage,
    },
    {
      path: "/setup",
      name: "setup",
      redirect: "/settings/locations",
    },
  ],
});
router.beforeEach((to, from, next) => {
  let meta = to.meta;
  if (typeof to.meta === "function") {
    meta = to.meta(to);
  }
  document.title = meta.title + " | Frost Solutions" || "Frost Solutions";
  next();
});

router.afterEach((to, _from) => {
  window.awsRum.recordPageView(to.fullPath);
});

export default router;
