<template>
    <div class="dm-page-container dm-flex-column dm-height-100">
        <dm-table ref="grdUsers" :table="{
            grdTableItems: grdUsersItems,
            grdTableRowOnClick: grdUsersRowOnClick,
            grdTableActionOnClick: grdUsersActionOnClick,
            grdTableSortBy: grdUsersSortBy,
            grdTableFields: grdUsersFields,
            class: grdUsersClass
        }" :allowPaging="true" />
    </div>
</template>

<script>
import Vue from 'vue'
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import { useBaseStore } from '../../js/store';
import { camelPad } from '../../js/utils';

export default {
    name: 'users-page',
    data: function () {
        return {
            searchParams: {},
            grdUsersFields: [],
            grdUsersItems: [],
            grdUsersSortBy: {
                columns: [
                    {
                        field: 'LastName',
                        direction: 'Ascending'
                    },
                ]
            },
            grdUsersClass: '',
            searchUserType: '',
            userTypeOptions: [],
            searchCounty: '',
            countyOptions: [],
            searchOrganization: '',
            organizationOptions: [],
            grdUsersActions: [
                {
                    text: 'Edit'
                },
                {
                    text: 'Resend Invite'
                }
            ],
            selectedRow: null,
        }
    },
    computed: {
        ...mapStores(useBaseStore)
    },
    methods: {
        getUsers(params) {
            DM.http({
                method: 'GET',
                url: '/users',
                params: params
            }).then(response => {
                response.forEach(u => {
                    u.Name = u.LastName + ', ' + u.FirstName;
                });
                this.users = response;
                this.grdUsersItems = response;
            });
        },
        grdUsersRowOnClick(args) {
            this.selectedRow = args;
        },
        addUserOnClick() {
            eventBus.$emit('showAppModal', this, 'usersEditModal', {
                mode: "new",
                user: {},
                userTypeOptions: this.userTypeOptions,
                callback: () => this.getUsers(this.searchParams)
            });
        },
        grdUsersActionOnClick(args) {
            if (args.item.text === 'Edit') {
                DM.http({
                    method: 'GET',
                    url: `/users/${this.selectedRow.rowData.ID}`,
                }).then(response => {
                    eventBus.$emit('showAppModal', this, 'usersEditModal', {
                        mode: "edit",
                        user: response,
                        userTypeOptions: this.userTypeOptions,
                        callback: () => this.getUsers(this.searchParams)
                    });
                });
            }

            if (args.item.text === 'Resend Invite') {
                this.resendInvite();
            }
        },
        resendInvite() {
            event('resend_invite', {
                'invite_user_id': this.selectedRow.rowData.ID
            });

            DM.http({
                method: "POST",
                url: "/users/password-reset",
                params: {
                    email: this.selectedRow.rowData.Email,
                }
            }).then(() => {
                DM.alertShow('Invite email sent.');
            });
        },
        deleteUser(userId) {
            // TODO: Update API when new API is done
            DM.http({
                method: 'POST',
                url: '/User/Delete',
                params: {
                    id: userId,
                }
            }).then(response => {
                this.getUsers();
            });
        },
        searchUsersOnClick() {
            this.getUsers();
        },
        defineColumns() {
            let self = this;

            if (window.innerWidth < 666) {
                this.grdUsersFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                            :items="grdUsersActions"
                                            :select="select"
                                            iconCss="e-icons e-ellipsis-vert"
                                            cssClass= "e-caret-hide"
                                        />`,
                                    data: function () {
                                        return {
                                            grdUsersActions: self.grdUsersActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdUsersActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: "Name",
                        label: "Name"
                    },
                    {
                        key: "IsActive",
                        label: "Active",
                        width: '100px',
                        formatter: (field, data) => {
                            return data.IsActive ? 'Y' : 'N';
                        },
                        width: '70px',
                        textAlign: 'Center',
                    },
                ];
            } else {
                this.grdUsersFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                            :items="grdUsersActions"
                                            :select="select"
                                            iconCss="e-icons e-ellipsis-vert"
                                            cssClass= "e-caret-hide"
                                        />`,
                                    data: function () {
                                        return {
                                            grdUsersActions: self.grdUsersActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdUsersActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: "LastName",
                        label: "Last Name",
                        width: '130px',
                    },
                    {
                        key: "FirstName",
                        label: "First Name",
                        width: '120px',
                    },
                    {
                        key: "PhoneNumber",
                        label: "Phone",
                        width: '120px',
                    },
                    {
                        key: "Email",
                        label: "Email",
                    },
                    {
                        key: "Access",
                        label: "Access",
                        formatter: (field, data) => {
                            let access = [];
                            if (data.IsSuperAdmin) {
                                access.push('Super Admin');
                            }

                            if (data.Groups) {
                                data.Groups.forEach(g => {
                                    access.push(g.GroupEmbedded.Name + ' ' + camelPad(g.Permission));
                                });
                            }

                            return access.join(' | ');
                        },
                    },
                    {
                        key: "IsActive",
                        label: "Active",
                        formatter: (field, data) => {
                            return data.IsActive ? 'Y' : 'N';
                        },
                        width: '100px',
                        textAlign: 'Center',
                    },
                ];
            }
        },
    },
    created() {
        this.getUsers({ includeInactive: false });

        this.defineColumns();

        eventBus.$on('openAddUser', this.addUserOnClick);
        eventBus.$on('searchItems', (params) => {
            this.searchParams = params;
            this.getUsers(params);
        });
    },
    mounted() {
        let defineColumns;
        window.onresize = () => {
            clearTimeout(defineColumns);
            defineColumns = setTimeout(this.defineColumns, 100);
        };
    },
    beforeDestroy() {
        eventBus.$off('openAddUser', this.addUserOnClick);
        eventBus.$off('searchItems', (params) => {
            this.searchParams = params;
            this.getUsers(params);
        });
    }
}
</script>
