<template>
    <span>
        <i :class="`fa ${batteryClass()} ${batteryColor()}`"></i>
        <span v-if="includePercentage" :class="batteryColor()">{{ percentage }}%</span>
    </span>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    percentage: {
        type: Number,
        required: false
    },
    includePercentage: {
        type: Boolean,
        default: false
    }
});

const batteryColor = () => {
    if (props.percentage >= 50) {
        return 'text-green';
    } else if (props.percentage >= 25) {
        return 'text-yellow';
    } else if (props.percentage >= 0) {
        return 'text-red';
    } else {
        return 'text-gray';
    }
};

const batteryClass = () => {
    if (props.percentage >= 75) {
        return 'fa-battery-full';
    } else if (props.percentage >= 50) {
        return 'fa-battery-three-quarters';
    } else if (props.percentage >= 25) {
        return 'fa-battery-half';
    } else if (props.percentage >= 0) {
        return 'fa-battery-quarter';
    } else {
        return 'fa-battery-empty';
    }
};

</script>