<template>
    <ejs-dialog visible="false" allowDragging="true" cssClass="scrollable" isModal="true" showCloseIcon="true"
        :id="modalName" :target="sfTarget" :ref="modalName" :width="'50%'" :height="'90%'" @overlayClick="overlayClick">
        <div class="dm-flex-column dm-overflow-y-auto dm-padding-left-md dm-padding-right-md dm-padding-bottom-md dm-height-100"
            style="padding-top: 30px; gap: 10px">
            <!-- <div @click="btnCloseModalOnClick" class="app-close-sensors-view-modal-btn e-btn-icon e-icon-dlg-close e-icons dm-pointer float-end"></div> -->
            <div class="app-image-container dm-flex-row app-background-contain dm-align-items-center dm-justify-content-space-between dm-flex-1 dm-position-relative"
                :style="{ 'background-image': backgroundImage, 'height': '200px' }">

                <span v-if="!selectedReading"
                    class="mdi mdi-arrow-left-circle dm-pointer app-opacity-6 dm-pointer-events-none"> </span>

                <span v-if="selectedReading && !selectedReading.ProcessedCameraImageURL">
                    No Image
                </span>

                <span v-if="!selectedReading"
                    class="mdi mdi-arrow-right-circle dm-pointer app-opacity-6 dm-pointer-events-none"> </span>

                <div v-if="selectedReading && selectedReading.ProcessedCameraImageURL" @click="btnDownloadImageOnClick"
                    class="app-download-image-btn dm-pointer">
                    <i class="fa fa-download"></i>
                </div>
            </div>

            <h2 class="dm-margin-top-0 dm-margin-bottom-0">Photo Details:</h2>
            <h3 class="dm-margin-bottom-0 dm-text-ellipsis" style="margin-top: 5px">{{ sensor.Name }}</h3>
            <div v-if="selectedReading && selectedReading.AirTemp">
                <p class="dm-padding-left-md dm-margin-top-0">Air: <b>{{ selectedReading.AirTemp }}&deg;{{ tempUnit
                        }}</b>
                </p>
                <p class="dm-padding-left-md dm-margin-top-sm dm-margin-bottom-sm">Surface: <b>{{
                    selectedReading.SurfaceTemp }}&deg;{{ tempUnit }}</b></p>
                <p class="dm-padding-left-md dm-margin-top-sm dm-margin-bottom-sm">Dew Point: <b>{{
                    selectedReading.DewPoint }}&deg;{{ tempUnit }}</b></p>
                <p class="dm-padding-left-md dm-margin-top-sm dm-margin-bottom-0">Humidity: <b>{{
                    selectedReading.Humidity }}%</b></p>
                <p class="dm-padding-left-md dm-margin-top-sm dm-margin-bottom-0">Start Time: <b>{{
                    formatDateTime(selectedReading.StartDateTimeLocal) }}</b></p>
                <p class="dm-padding-left-md dm-margin-top-sm dm-margin-bottom-0">End Time: <b>{{
                    formatDateTime(selectedReading.EndDateTimeLocal) }}</b></p>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
import { mapStores } from 'pinia';
import { useBaseStore, useGroupsStore } from '../../js/store';

import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin';
import { formatDate } from '../../js/utils';

export default {
    name: 'sensors-view-modal',
    mixins: [dmModalMixin],
    props: ['modalData'],
    data: function () {
        return {
            selectedReading: null,
            modalName: 'sensorsPhotoModal' + DM.getRandomGuid(),
            sensor: {},
            cellData: {}
        };
    },
    methods: {
        btnCloseModalOnClick() {
            this.modalRef.hide();
        },
        btnDownloadImageOnClick() {
            const { ID, ProcessedCameraImageURL } = this.selectedReading;
            let fileName = `${ID}_${formatDate(ProcessedCameraImageURL)}.jpg`;

            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = ProcessedCameraImageURL;
            a.download = fileName;
            a.click();
            document.body.removeChild(a);
        },
        overlayClick() {
            this.modalRef.hide();
        },
        formatDateTime(date) {
            const { TimeZone } = this.groupsStore.selectedGroup;
            return formatDate(date, TimeZone)
        }
    },
    computed: {
        ...mapStores(useBaseStore, useGroupsStore),
        backgroundImage() {
            if (this.sensor.ProcessedCameraImageURL) {
                return `url('${this.sensor.ProcessedCameraImageURL}')`;
            }
            return 'none';
        },
        tempUnit() {
            const { TemperatureUnits } = this.groupsStore.selectedGroup;
            return TemperatureUnits?.charAt(0);
        }
    },
    async created() {
        this.sensor = this.modalData.sensor;
        this.cellData = this.modalData.cellData
        this.selectedReading = this.sensor
    }
};
</script>