<template>
  <div class="grid-wrapper" :class="{ 'sidebar-displayed': !showGridView && mainAppWidth <= 700 || showSensorView }"
    :style="gridWrapperStyle">
    <template v-if="mainAppWidth >= 500">
      <slot></slot>
    </template>
    <div class="flex-col">
      <template v-if="mainAppWidth < 500">
        <slot></slot>
      </template>
      <grid-section v-for="(locations, deviceTypeGroup) in locationsStore.groupedLocations" :title="deviceTypeGroup"
        :count="locations.length" :key="deviceTypeGroup" grid-class="device-grid">
        <div v-for="location in locations" :key="location.ID" class="sensor-item">
          <grid-snow-depth-item v-if="location.DeviceType === 'Snow Depth Sensor'" :location="location"
            :most-recent="locationsStore.mostRecentTransmissions[location.ID] || {}"
            :data-transmissions="locationsStore.dataTransmissions[location.ID] || []" :show-grid-view="showGridView"
            :show-sensor-view="showSensorView"></grid-snow-depth-item>
          <grid-sensor-item v-if="location.DeviceType === 'Mini RWIS'" :location="location"
            :most-recent="locationsStore.mostRecentTransmissions[location.ID] || {}"
            :data-transmissions="locationsStore.dataTransmissions[location.ID] || []"
            :forecast="forecastsStore.forecasts[location.ID]" :show-grid-view="showGridView"
            :show-sensor-view="showSensorView"></grid-sensor-item>
          <grid-frost-vision-item v-if="location.DeviceType === 'FrostVision Camera'" :location="location"
            :most-recent="locationsStore.mostRecentTransmissions[location.ID] || {}" :show-grid-view="showGridView"
            :show-sensor-view="showSensorView"></grid-frost-vision-item>
        </div>
      </grid-section>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";

import gridSection from "./gridSection";
import gridSensorItem from "./gridSensorItem";
import gridSnowDepthItem from "./gridSnowDepthItem.vue";
import gridFrostVisionItem from "./gridFrostVisionItem.vue";

import { useLocationsStore, useForecastsStore } from "../js/store";

const locationsStore = useLocationsStore();
const forecastsStore = useForecastsStore();

const props = defineProps({
  mainAppWidth: {
    type: Number,
    required: true
  },
  gridHeight: {
    type: Number,
    required: true
  },
  showGridView: {
    type: Boolean,
    required: true
  },
  showSensorView: {
    type: Boolean,
    required: true
  }
});

const gridItemWidth = computed(function () {
  const width =
    props.showGridView === true ? props.mainAppWidth : props.mainAppWidth / 2;
  const threeFifty = Math.floor(width / 350);
  const fourTwenty = Math.floor(width / 400);
  if (threeFifty > fourTwenty && threeFifty < 3) {
    return 350;
  } else {
    return 400;
  }
});

const gridWrapperStyle = computed(function () {
  if (props.showSensorView === true && props.mainAppWidth < 768) {
    return { display: "none" };
  } else if (props.showGridView === true && props.showSensorView === false) {
    return { display: 'grid', width: "100%", gridTemplateColumns: 'auto' };
  }

  const width = props.mainAppWidth / 2;
  if (gridItemWidth.value === 350 && width >= 350) {
    const w = Math.floor(width / 350) * 350 || 350;
    return { gridTemplateColumns: `repeat(auto-fill, minmax(${w}px, fr))` };
  } else if (gridItemWidth.value === 400 && width >= 400) {
    const w = Math.floor(width / 400) * 400 || 400;
    return { gridTemplateColumns: `repeat(auto-fill, minmax(${w}px, fr))` };
  } else {
    return { width: "100%", gridTemplateColumns: 'auto' };
  }
});
const deviceGridStyle = computed(function () {
  return {
    gridTemplateColumns: `repeat(auto-fill, minmax(${gridItemWidth.value}px, auto))`,
  };
});

</script>
