<template>
    <div class="grid-section">
        <h2 class="grid-section-title" :id="idSafeTitle">
            <a :href="'#' + idSafeTitle">{{ title }}</a> <span class="count">({{ count }})</span>
        </h2>
        <hr>
        <div :style="gridStyle" :class="gridClass">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { snakeCase } from 'lodash';
const props = defineProps({
    title: String,
    count: Number,
    gridStyle: Object,
    gridClass: String
});

const idSafeTitle = computed(() => {
    return snakeCase(props.title);
});

</script>