import { defineStore } from "pinia";
import { cloneDeep, map, round } from "lodash";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment-timezone";

import app from "../app";
import api from "../api";
import {
  transformRequestData,
  transformTransmissionOrRoadCondition,
} from "../transformers";
import { exportToCSV, formatChartDate } from "../utils";
import { useGroupsStore } from "./groups";

const API = new api();

// History store
export const useHistoryStore = defineStore("history", {
  state: () => ({
    transmissions: {},
    roadConditionsHistory: {},
    historyRetries: 0,
    historyDelay: app.RETRY_DELAY,
    fetchingHistory: false,
    historyError: null,
    requests: {},
    requestsRetries: 0,
    requestsDelay: app.RETRY_DELAY,
    fetchingRequests: false,
    requestsError: null,
    start: moment().subtract(1, "week").toISOString(),
    end: moment().toISOString(),
    interval: 10,
    minInterval: 5,

    photos: { metadata: [], pages: {} },

    fetchingPhotos: false,
    photosError: null,
    photosRetries: 0,
    photosDelay: app.RETRY_DELAY,

    fetchingPhotosMetadata: false,
    photosMetadataError: null,
    photosMetadataRetries: 0,
    photosMetadataDelay: app.RETRY_DELAY,
  }),
  actions: {
    updateStartWithTimezone(timezone) {
      this.start = moment(this.start).toISOString();
      this.end = moment(this.end).toISOString();
    },
    updateStart(dateTime) {
      this.start = moment(dateTime).toISOString();
    },
    updateEnd(dateTime) {
      this.end = moment(dateTime).toISOString();
    },
    getHistory(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const { selectedGroup } = groupStore;
      const timeZone = selectedGroup.TimeZone;
      const tempUnit = selectedGroup.TemperatureUnits;
      this.fetchingHistory = true;
      const args = {
        start: this.start,
        end: this.end,
        minutesInterval: 5,
      };
      API.getLocation(deviceId, args)
        .then((response) => {
          this.fetchingHistory = false;
          // Removing forecasts from transmissions
          const history = response.DataTransmissions.filter(
            (t) => !t.IsForecast
          )
            .map((t) => {
              return transformTransmissionOrRoadCondition(t, {
                timeZone,
                tempUnit,
              });
            })
            // Sorting them by reverse chronological order
            .sort((a, b) =>
              moment(b.TransmissionDateTimeUTC).diff(a.TransmissionDateTimeUTC)
            );

          const transmissions = cloneDeep(this.transmissions);
          const roadConditionsHistory = cloneDeep(this.roadConditionsHistory);
          this.transmissions = {
            ...transmissions,
            [deviceId]: history,
          };
          this.roadConditionsHistory = {
            ...roadConditionsHistory,
            [deviceId]: response.RoadConditionsHistory,
          };
          this.historyDelay = app.RETRY_DELAY;
          this.historyRetries = 0;
        })
        .catch((error) => {
          console.log("getHistory", error);
          if (error.status === 404 || error.status === 500) {
            this.historyError = "Failed to fetch data. Please try again later.";
            this.fetchingHistory = false;
            return;
          }

          this.historyRetries++;
          this.historyDelay = this.historyDelay * 2;
          if (this.historyRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getHistory(deviceId);
            }, this.historyDelay);
          } else {
            this.fetchingHistory = false;
            this.historyError = error;
            this.historyRetries = 0;
            this.historyDelay = app.RETRY_DELAY;
          }
        });
    },
    getPhotosPage(deviceId, offset = 0, limit = 10) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const { selectedGroup } = groupStore;
      const timeZone = selectedGroup.TimeZone || "America/New_York";
      const tempUnit = selectedGroup.TemperatureUnits || "F";
      this.fetchingPhotos = true;

      const start = moment(this.start).utc().toISOString();
      const end = moment(this.end).utc().toISOString();

      return API.getPhotos(deviceId, {
        start,
        end,
        limit,
        offset,
        version: 2,
      })
        .then((response) => {
          response = response.map((r) => {
            const t = transformTransmissionOrRoadCondition(r, {
              timeZone,
              tempUnit,
            });
            t.ComputerVision = map(
              t.ComputerVision,
              (v, k) => `<div>${k}: ${round(v, 1)}%</div>`
            ).join("");
            return t;
          });

          const newData = { ...this.photos };
          newData.pages[offset] = response;
          this.photos = { ...newData };

          this.fetchingPhotos = false;
        })
        .catch((error) => {
          this.photosRetries++;
          this.photosDelay = this.photosDelay * 2;
          if (this.photosRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getPhotosPage(deviceId, offset, limit);
            }, this.photosDelay);
          } else {
            this.fetchingPhotos = false;
            this.photosError = error;
            this.photosRetries = 0;
            this.photosDelay = app.RETRY_DELAY;
          }
        });
    },
    getPhotosMetadata(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const { selectedGroup } = groupStore;
      const timeZone = selectedGroup.TimeZone;
      this.fetchingPhotosMetadata = true;

      const start = moment(this.start).utc().toISOString();
      const end = moment(this.end).utc().toISOString();

      return API.getPhotosMetadata(deviceId, {
        start,
        end,
      })
        .then((response) => {
          this.fetchingPhotosMetadata = false;
          this.photos = {
            ...this.photos,
            metadata: response,
          };
        })
        .catch((error) => {
          this.photosMetadataRetries++;
          this.photosMetadataDelay = this.photosMetadataDelay * 2;
          if (this.photosMetadataRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getPhotosMetadata(deviceId);
            }, this.photosMetadataDelay);
          } else {
            this.fetchingPhotosMetadata = false;
            this.photosMetadataError = error;
            this.photosMetadataRetries = 0;
            this.photosMetadataDelay = app.RETRY_DELAY;
          }
        });
    },
    resetPhotos() {
      this.photos = { metadata: [], pages: {} };
    },
    getRequests(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const timeZone = groupStore.selectedGroup.TimeZone;
      this.fetchingRequests = true;
      API.getRequests(deviceId)
        .then((response) => {
          this.fetchingRequests = false;
          this.requests = {
            ...this.requests,
            [deviceId]: response.map((r) =>
              transformRequestData(r, { timeZone })
            ),
          };
          this.requestsDelay = app.RETRY_DELAY;
          this.requestsRetries = 0;
        })
        .catch((error) => {
          this.requestsRetries++;
          this.requestsDelay = this.requestsDelay * 2;
          if (this.requestsRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getRequests(deviceId);
            }, this.requestsDelay);
          } else {
            this.fetchingRequests = false;
            this.requestsError = error;
            this.requestsRetries = 0;
            this.requestsDelay = app.RETRY_DELAY;
          }
        });
    },
    exportRequests(deviceId) {
      const requestData = this.requests[deviceId];
      if (!requestData) {
        return;
      }

      const doc = new jsPDF();
      autoTable(doc, {
        head: [
          [
            "Start Date Time (Group)",
            "CreatedByUser",
            "DeviceID",
            "Type",
            "Request ID",
          ],
        ],
        body: requestData.map((r) => [
          r.startDateTimeFormatted,
          r.CreatedByUser,
          r.DeviceID,
          r.DisplayName,
          r.ID,
        ]),
      });
      const now = new Date();
      const date = now.toJSON().slice(0, 10);
      doc.save(`${date}-request-data-sensor-${deviceId}.pdf`);
    },
    exportHistory(deviceId) {
      const groupsStore = useGroupsStore();
      const timeZone = groupsStore.selectedGroup.TimeZone;
      const abbreviation = moment.tz(timeZone).format("z");
      let history = this.transmissions[deviceId];
      const roadConditions = this.roadConditionsHistory[deviceId];

      roadConditions.forEach((rc) => {
        const index = history.findIndex(
          (e) =>
            // Match the date if its within 5 minutes
            moment(e.TransmissionDateTimeUTC).diff(
              rc.TransmissionDateTimeUTC,
              "minutes"
            ) < 5
        );

        const condition = {
          SurfaceGrip: rc.SurfaceGrip,
          RoadCondition: rc.RoadCondition,
          PrecipType: rc.PrecipType,
          PrecipRate: rc.PrecipRate,
          SnowRate: rc.SnowRate,
          RainRate: rc.RainRate,
          MixedRate: rc.MixedRate,
          WindDirection: rc.WindDirection,
          WindSpeed: rc.WindSpeed,
          IsForecast: rc.IsForecast,
        };

        if (index > -1) {
          history[index] = {
            ...history[index],
            ...condition,
          };
        }
      });
      history = history.sort((a, b) =>
        moment(a.TransmissionDateTimeUTC).diff(b.TransmissionDateTimeUTC)
      );

      const start = moment(this.start).format("YYYY-MM-DD_HH:mm");
      const end = moment(this.end).format("YYYY-MM-DD_HH:mm");

      const arr = history.reduce((a, e) => {
        if (e.IsForecast) return a;

        a.push({
          TransmissionDateTimeUTC: e.TransmissionDateTimeUTC,
          TransmissionDateTimeGroup: formatChartDate(
            e.TransmissionDateTimeUTC,
            timeZone
          ),
          SurfaceTemp: e.SurfaceTemp,
          AirTemp: e.AirTemp,
          DewPoint: e.DewPoint,
          Humidity: e.Humidity,
          ProcessedCameraImageURL: e.ProcessedCameraImageURL,
          SurfaceGrip: e.SurfaceGrip,
          RoadCondition: e.RoadCondition,
          PrecipType: e.PrecipType,
          PrecipRate: e.PrecipRate,
          SnowRate: e.SnowRate,
          RainRate: e.RainRate,
          MixedRate: e.MixedRate,
          WindDirection: e.WindDirection,
          WindSpeed: e.WindSpeed,
          IsForecast: e.IsForecast,
        });

        return a;
      }, []);
      const columnNames = [
        "TransmissionDateTimeUTC",
        "TransmissionDateTime (" + abbreviation + ")",
        "Surface Temp",
        "Air Temp",
        "Dew Point",
        "Humidity",
        "Image URL",
        "Surface Grip",
        "Road Condition",
        "Precip Type",
        "Precip Rate",
        "Snow Rate",
        "Rain Rate",
        "Mixed Rate",
        "Wind Direction",
        "Wind Speed",
        "Is Forecast",
      ];
      exportToCSV(
        arr,
        `sensor_ID_${deviceId}_history_(${start}_${end})`,
        columnNames
      );
    },
  },
});
