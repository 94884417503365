<template>
  <div class="dm-flex-column dm-flex-1 dm-padding-sm dm-overflow-hidden history-table">
    <h3>Requests</h3>
    <dm-table ref="grdSensorRequests" :table="{
      grdTableItems: requests,
      grdTableFields: grdSensorRequestFields,
    }" :allowPaging="true" :pageSettings="{ pageSize: 10, pageCount: 10 }" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBaseStore, useLocationsStore } from '../../js/store';
import moment from 'moment';
import hasPhoto from '../../images/svg/has-photo.svg';
import exportPhoto from '../../images/svg/export-photo.svg';
import exportWhite from '../../images/svg/export-white.svg';
import exportText from '../../images/svg/export-text.svg';
import photoInfo from '../../images/svg/photo.svg';
import infoIcon from '../../images/svg/info.svg';

export default {
  name: 'sensors-requests-table',
  props: [
    "requests",
    "deviceId",
  ],
  data: function () {
    return {
      infoIcon: infoIcon,
      photoInfo: photoInfo,
      exportText: exportText,
      exportWhite: exportWhite,
      hasPhoto: hasPhoto,
      exportPhoto: exportPhoto,
      lblTitle: '',
      componentKey: 0,
      grdSensorRequestFields: [],
      selectedRow: null,
      indicators: [{
        type: 'AccumulationDistribution',
        field: 'Close',
        yAxisName: 'y',
        fill: '#6063ff',
        period: 3,
        animation: { enable: true }
      }],
      grdActions: [
        {
          text: 'Export'
        },
      ],
      showDiagnosticCodes: false,
      sensorHistoryData: null,
      requestWorkData: null,
    }
  },
  methods: {
    defineColumns() {
      this.grdSensorRequestFields = [
        {
          key: 'startDateTimeFormatted',
          label: 'Start Time (' + this.baseStore.timeZone + ')',
          type: 'custom',
          width: '150px',
          textAlign: 'center'
        },
        {
          key: 'endDateTimeFormatted',
          label: 'End Time (' + this.baseStore.timeZone + ')',
          type: 'custom',
          width: '150px',
          textAlign: 'center',
        },
        {
          key: 'DisplayName',
          label: 'Request Type',
          type: 'custom',
          width: '170px',
          textAlign: 'center'
        },
        {
          key: 'ReadingDateTimeUTC',
          label: 'Details',
          width: '100px',
          textAlign: 'center',
          allowSorting: false,
          type: 'template',
          template: () => {
            return {
              data: { parentSensor: this.sensor },
              template: Vue.component('columnTemplate', {
                template: `<div>
                                            <div v-if="hasIconType">
                                                <img v-if="iconType" @click="showModal()" style="width: 20px; hight: 20px; cursor: pointer;" :src="photoInfo"></img>
                                                <img v-else="!iconType" style="width: 20px; hight: 20px; cursor: pointer;" :src="infoIcon"></img>
                                            </div>
                                        </div>`,
                computed: {
                  iconType: function () {
                    return this.data.RequestTypeCode == 'DEVICE_REQUEST_PHOTO' ? true : false;
                  },
                  hasIconType: function () {
                    return this.data.ShowIcon != 0 ? true : false;
                  }
                },
                methods: {
                  showModal: function () {
                    app.openRequestPhotoModal(this.getType("DEVICE_REQUEST_PHOTO"), this.data, this);
                  },
                  getType: function (type) {
                    const { ID, RequestTypeCode } = this.data
                    return RequestTypeCode == type ? ID : 0;
                  }
                },
                data: function () {
                  return {
                    photoInfo: photoInfo,
                    infoIcon: infoIcon,
                  }
                },
              }),
            }
          },
        },
        {
          key: 'CreatedByUser',
          label: 'Requestor',
          type: 'custom',
          width: '150px',
          textAlign: 'center'
        },
        {
          key: 'ID',
          label: 'Export',
          width: '100px',
          textAlign: 'center',
          allowSorting: false,
          type: 'template',
          template: () => {
            return {
              template: Vue.component('columnTemplate', {
                template: `<ejs-dropdownbutton v-if="this.getRequestID > 0" :select='btnExportData' :items='items' cssClass='e-caret-hide export-style' iconCss= 'e-image'></ejs-dropdownbutton>`,
                data: function () {
                  return {
                    items: [
                      {
                        text: 'PDF'
                      }]
                  }
                },
                methods: {
                  async btnExportData(args) {
                    const { text } = args.item
                    if (text == "PDF" && this.getRequestID > 0) {
                      const data = await DM.http({
                        method: 'GET',
                        url: `/device-requests/${this.getRequestID}?format=pdf`,
                      }, false, true);

                      if (!data.message) {
                        const byteString = window.atob(data);
                        const arrayBuffer = new ArrayBuffer(byteString.length);
                        const int8Array = new Uint8Array(arrayBuffer);
                        for (let i = 0; i < byteString.length; i++) {
                          int8Array[i] = byteString.charCodeAt(i);
                        }
                        const blob = new Blob([int8Array], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);

                        const downloadLink = document.createElement("a");
                        downloadLink.style.display = "none";
                        document.body.appendChild(downloadLink);
                        downloadLink.href = url;
                        downloadLink.download = `Proof_of_Work_${this.getRequestID}.pdf`;
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        window.URL.revokeObjectURL(url);
                      } else {
                        DM.alertShow("Proof of work report is still being generated, Please check back in a few minutes.", 'Details');
                      }
                    }
                  },
                },
                computed: {
                  getRequestID: function () {
                    const { ID, RequestTypeCode } = this.data
                    return RequestTypeCode == "EVENT_REQUEST_WORK" ? ID : 0;
                  }
                }
              }),
            }
          },
        },
      ]

      if (this.isUserSuperAdmin) {
        this.grdSensorRequestFields.push(
          {
            key: 'ID',
            label: 'Request ID',
            type: 'custom',
            width: '100px',
            textAlign: 'center'
          },
          {
            key: 'DeviceID',
            label: 'Device ID',
            type: 'custom',
            width: '100px',
            textAlign: 'center'
          },
          {
            key: 'DeviceID',
            label: 'Debug',
            width: '90px',
            textAlign: 'center',
            allowSorting: false,
            type: 'template',
            template: () => {
              return {
                template: Vue.component('columnTemplate', {
                  template: `<div>
                                            <div>
                                                <img style="width: 20px; hight: 20px; cursor: pointer;" :src="infoIcon"></img>
                                            </div>
                                        </div>`,
                  data: function () {
                    return {
                      infoIcon: infoIcon
                    }
                  }
                }),
              }
            },
          },
          {
            key: 'ResultCode',
            label: 'Result Code',
            type: 'custom',
            width: '90px',
            textAlign: 'left'
          },
        )
      }
    },
  },
  computed: {
    ...mapStores(useBaseStore, useLocationsStore),
    isUserSuperAdmin() {
      return this.baseStore.user?.IsSuperAdmin;
    },
    sensor() {
      return this.locationsStore[this.deviceId];
    },
  },
  watch: {
    showDiagnosticCodes: function (newVal) {
      if (newVal) {
        this.graphs = this.graphs.filter(g => g === 'Diagnostic Code');
        this.selectedGraphs = [];
      }

      this.componentKey++;
    },
  },
  created() {
    this.defineColumns();
  },
  mounted() {
    let defineColumns;
    window.onresize = () => {
      clearTimeout(defineColumns);
      defineColumns = setTimeout(this.defineColumns, 100);
    };
  },
}
</script>

<style></style>