<template>
  <div class="tool-button" @click="triggerAction">
    <span>{{ props.heading }}</span>
    <p class="p-0 m-0">{{ props.content }}</p>
    <div class="flex flex-row align-center uppercase bold">
      <img :src="icon" :alt="label" />
      {{ props.label }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps([
  'label',
  'content',
  'icon',
  'action',
  'heading',
])

const triggerAction = () => {
  props.action();
}

const labels = computed(() => props.label.split(' '))

</script>
