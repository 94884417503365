<template>
  <v-select :clearable="false" class="glance-selector" :filterable="false" :searchable="false" :value="selected"
    :options="options" @input="onChange" placeholder="Select an option"></v-select>
</template>
<script>
export default {
  name: "at-a-glance-selector",
  props: {
    selected: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: [{ label: 'loading...', value: 'loading...' }],
    },
    onChange: {
      type: Function,
      default: () => { },
    },
  },
};
</script>