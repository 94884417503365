import { defineStore } from "pinia";
import { cloneDeep } from "lodash";

import app from "../app";
import api from "../api";
import { transformForecastData } from "../transformers";
import { useGroupsStore } from "./groups";
import { useLocationsStore } from "./locations";

const API = new api();

// Locations store
export const useForecastsStore = defineStore("forecasts", {
  state: () => ({
    delay: app.RETRY_DELAY,
    forecasts: {}, // {[deviceID]: [forecasts for device]}
    forecastsRetries: 0,
    forecastsDelay: app.RETRY_DELAY,
    fetchingForecasts: false,
    forecastsError: null,
  }),
  getters: {},
  actions: {
    // Passing in deviceIds will only fetch forecasts for those devices
    // otherwise it grabs all devices for the selected group
    // The group ID is required by the API for now
    getForecasts(deviceIds = []) {
      const groupsStore = useGroupsStore();
      const locationsStore = useLocationsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }

      let ids = deviceIds;
      if (ids.length === 0) {
        ids = locationsStore.filteredLocations.map((d) => d.ID);
      }
      if (ids.length === 0) {
        return;
      }
      this.fetchingForecasts = true;
      const {
        TemperatureUnits: tempUnit,
        TimeZone: timeZone,
        isMetric,
      } = groupsStore.selectedGroup;
      API.getForecasts(ids, groupsStore.selectedGroupId)
        .then(async (response) => {
          const forecasts = cloneDeep(this.forecasts);
          this.fetchingForecasts = false;
          for (const deviceId in response) {
            forecasts[deviceId] = await transformForecastData(
              response[deviceId],
              {
                tempUnit,
                timeZone,
                isMetric,
              }
            );
          }
          this.forecasts = forecasts;
        })
        .catch((error) => {
          console.error("getForecasts", error);
          this.fetchingForecasts = false;
          this.forecastsError = error;
          this.forecastsRetries++;
          this.forecastsDelay = this.forecastsDelay * 2;
          if (this.forecastsRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getForecasts();
            }, this.forecastsDelay);
          } else {
            this.fetchingForecasts = false;
            this.forecastsError = error;
            this.forecastsRetries = 0;
            this.forecastsDelay = app.RETRY_DELAY;
          }
        });
    },
  },
});
