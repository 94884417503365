<template>
    <ejs-dialog :id="modalName" :target="sfTarget" :ref="modalName" :header="lblTitle" isModal="true"
        showCloseIcon="true" :width="'500px'" visible="false" allowDragging="true" cssClass="scrollable">
        <div class="dm-flex-column dm-overflow-y-auto dm-padding-md">
            <div class="dm-flex-row" style="height: 300px;">
                <dm-table ref="grd" :table="{
                    grdTableItems: grdItems,
                    grdTableRowOnClick: grdRowOnClick,
                    grdTableActionOnClick: grdActionOnClick,
                    grdTableSortBy: grdSortBy,
                    grdTableFields: grdFields,
                    class: grdClass
                }" />
            </div>
        </div>
    </ejs-dialog>

</template>

<script>
import Vue from 'vue'
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'grid-list-view-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'gridListViewModal' + DM.getRandomGuid(),
            lblTitle: '',
            grdFields: [],
            grdItems: [],
            grdSortBy: {
                columns: [
                    { field: 'Name', direction: 'Ascending' },
                ]
            },
            grdClass: ''
        }
    },
    methods: {
        grdRowOnClick(data) {

        },
        grdActionOnClick(data) {

        },
    },
    created() {
        this.lblTitle = this.modalData.title;
        this.grdFields = this.modalData.fields;
        this.grdItems = this.modalData.items;
    },
    mounted() {

    }
}
</script>
