<template>
  <BaseModal v-if="modalStore.activeModal === 'improve'">
    <div class="max-w-500">
      <span class="subtitle">Classify images to improve Frost Vision results for your sensors</span>
      <div class="selected-image mt-2 mb-2">
        <img :src="modalStore.modalProps.imageUrl" alt="Improve this image" />
      </div>
      <form @submit="handleSubmit">
        <div class="form-group">
          <span class="group-label">Road Conditions</span>
          <span class="subtitle">Select all that you see in the image above</span>
          <div class="checkbox-group">
            <label for="clear_pavement">
              <input type="checkbox" id="clear_pavement" name="clear_pavement" value="clear_pavement" />
              Clear/Dry</label>
            <label for="wet">
              <input type="checkbox" id="wet" name="wet" value="wet" />
              Wet</label>
            <label for="partial_snow">
              <input type="checkbox" id="partial_snow" name="partial_snow" value="partial_snow" />
              Partial Snow</label>
            <label for="icy">
              <input type="checkbox" id="icy" name="icy" value="icy" />
              Icy</label>
            <label for="slushy">
              <input type="checkbox" id="slushy" name="slushy" value="slushy" />
              Slushy</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Was the road plowed?</span>
          <div class="radio-group">
            <input type="radio" id="plowed-yes" name="plowed" value="yes" />
            <label for="plowed-yes">
              Yes</label>
            <input type="radio" id="plowed-no" name="plowed" value="no" />
            <label for="plowed-no">
              No</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Weather Conditions</span>
          <span class="subtitle">Select all that you see in the image above</span>
          <div class="checkbox-group">
            <label for="clear">
              <input type="checkbox" id="clear" name="clear" value="clear" />
              Clear</label>
            <label for="cloudy">
              <input type="checkbox" id="cloudy" name="cloudy" value="cloudy" />
              Cloudy</label>
            <label for="rain">
              <input type="checkbox" id="rain" name="rain" value="rain" />
              Raining</label>
            <label for="snowing">
              <input type="checkbox" id="snowing" name="snowing" value="snowing" />
              Snowing</label>
            <label for="mixed">
              <input type="checkbox" id="mixed" name="mixed" value="mixed" />
              Mixed Rain/Snow</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Is it night?</span>
          <div class="radio-group">
            <label for="night-yes">
              <input type="radio" id="night-yes" name="night" value="yes" />
              Yes</label>
            <label for="night-no">
              <input type="radio" id="night-no" name="night" value="no" />
              No</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Is the lense iced-over?</span>
          <div class="radio-group">
            <label for="iced-yes">
              <input type="radio" id="iced-yes" name="iced" value="yes" />
              Yes</label>
            <label for="iced-no">
              <input type="radio" id="iced-no" name="iced" value="no" />
              No</label>
          </div>
        </div>
        <div class="flex">
          <button class="btn btn-primary text-uppercase ml-auto"><i class="fa fa-save mr-1"></i>Confirm</button>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script setup>
import { useModalStore } from '../../js/store';
import BaseModal from './baseModal.vue';

const modalStore = useModalStore();

const imageUrl = modalStore.modalProps.imageUrl;

const a = { "clear_pavement": { "N": "0" }, "cloudy": { "N": "0" }, "partial_snow_on_road": { "N": "0" }, "snow_on_road": { "N": "0" }, "night": { "N": "0" }, "iced_lens": { "N": "1" }, "raining": { "N": "0" }, "snowing": { "N": "0" }, "sunny": { "N": "0" }, "wet_pavement": { "N": "0" } }

function handleSubmit(e) {
  e.preventDefault();
  const formData = new FormData(e.target);
  const data = {
    clear_pavement: formData.getAll('clear_pavement').length > 0 ? '1' : '0',
    wet_pavement: formData.getAll('wet').length > 0 ? '1' : '0',
    partial_snow_on_road: formData.getAll('partial_snow').length > 0 ? '1' : '0',
    snow_on_road: formData.getAll('icy').length > 0 ? '1' : '0',
    night: formData.get('night') === 'yes' ? '1' : '0',
    iced_lens: formData.get('iced') === 'yes' ? '1' : '0',
    raining: formData.getAll('rain').length > 0 ? '1' : '0',
    snowing: formData.getAll('snowing').length > 0 ? '1' : '0',
    sunny: formData.getAll('clear').length > 0 ? '1' : '0',
    cloudy: formData.getAll('cloudy').length > 0 ? '1' : '0',
    imageUrl,
  };
  // TODO: Add userId to data in the store action
  modalStore.closeModal();
}

</script>