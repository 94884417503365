<template>
    <ejs-dialog
        :id="modalName"
        :target="sfTarget"
        :ref="modalName"
        :header="title"
        :isModal="true"
        :visible="false"
        :allowDragging="true"
        :width="'300px'">

        <div class="flex-column">
            <div v-html="message"></div>

            <div class="dm-flex-row dm-justify-content-flex-end dm-margin-top-md">
                <ejs-button
                    @click.native="btnClose_OnClick">
                    Close
                </ejs-button>
            </div>
        </div>

    </ejs-dialog>
</template>

<script>
    import dmModalMixin from './dmModalMixin'

    export default {
        name: 'dm-alert-modal',
        mixins: [dmModalMixin],
        props: [
            'modalData',
        ],
        data: function () {
            return {
                modalName: 'dmAlertModal' + DM.getRandomGuid(),
                title: this.modalData.title,
                message: this.modalData.message,
            }
        },
        methods: {
            btnClose_OnClick() {
                if (this.modalData.callback) {
                    this.modalData.callback();
                }
                this.modalRef.hide();
            }
        },
    }
</script>
