<template>
    <ejs-dialog :id="modalName" :target="sfTarget" :ref="modalName" :header="lblTitle" isModal="true"
        showCloseIcon="true" :width="'250px'" visible="false" cssClass="scrollable">

        <div class="dm-flex-column dm-overflow-y-auto dm-padding-md">

            <div class="dm-padding-bottom-md">
                <ejs-textbox v-model="currentPassword" type="text" onfocus="this.type='password'" floatLabelType="Auto"
                    placeholder="Current Password">
                </ejs-textbox>
            </div>

            <div class="dm-padding-bottom-md">
                <ejs-textbox v-model="newPassword" type="text" onfocus="this.type='password'" floatLabelType="Auto"
                    placeholder="New Password">
                </ejs-textbox>
            </div>

            <div class="">
                <ejs-textbox v-model="newPasswordConfirm" type="text" onfocus="this.type='password'"
                    floatLabelType="Auto" placeholder="Confirm New Password">
                </ejs-textbox>
            </div>
        </div>

        <div class="dm-flex-row dm-justify-content-flex-end dm-margin-md">
            <div>
                <ejs-button @click.native="btnSaveOnClick" is-primary="true">
                    Save
                </ejs-button>
            </div>
        </div>

    </ejs-dialog>
</template>

<script>
import { mapStores } from 'pinia';
import { useBaseStore } from '../../js/store';

import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'users-password-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'usersPasswordEditModal' + DM.getRandomGuid(),
            lblTitle: 'Change Password',
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
        }
    },
    computed: {
        ...mapStores(useBaseStore)
    },
    methods: {
        savePassword() {
            return DM.http({
                method: "POST",
                url: `/users/${this.baseStore.userID}/password`,
                data: {
                    OldPassword: this.currentPassword,
                    NewPassword: this.newPassword,
                }
            });
        },
        btnSaveOnClick() {
            if (this.newPassword !== this.newPasswordConfirm) {
                DM.alertShow('New Password and Confirm New Password do not match.', 'Error');
                return;
            }

            this.savePassword()
                .then((response) => {
                    if (this.modalData.callback) {
                        this.modalData.callback(response);
                    }
                    this.modalRef.hide();
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        },
    },
    created() { },
    mounted() { }
}
</script>