<template>
  <div class="app-map-container">
    <slot></slot>
    <div id="app-dashboard-map"></div>

    <canvas hidden id="iconCanvas"></canvas>

    <div v-if="mapLayerType === 'precip'" @click="toggleAnimation(true)" class="app-animate-precip-btn dm-pointer">
      <i v-if="animatedPrecipOverlayAction === 'play'" class="fa fa-play"> </i>

      <i v-if="animatedPrecipOverlayAction === 'pause'" class="fa fa-pause"> </i>
    </div>

    <div class="app-overlay-timestamp" v-if="overlayTimestamp">
      {{ overlayTimestamp }} {{ TimeZoneAbbr }}
    </div>

    <div v-if="mapLayerType === 'precip' || mapLayerType === 'precip'" class="app-overlay-time-slider">
      <ejs-slider id="animatedPrecipSlider" v-model="animatedPrecipSliderValue" :step="animatedPrecipSliderStep" min="0"
        max="13" width="185px" />
    </div>

    <div v-if="zoomLevel > minZoomLevelForOverlay"
      :style="{ transform: mapLayerType === 'precip' ? '' : 'translateX(-92px)' }" class="app-overlay-not-available">
      Zoom out to view radar
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapStores, mapState, mapActions } from 'pinia';
import moment from 'moment-timezone';
import L from 'leaflet';

import { useBaseStore, useGroupsStore, useLocationsStore } from '../js/store';
import { formatDecimal } from '../js/utils';

import carsImage from "../images/cars-dark.png";
import roadImage from "../images/road-dark.png";
import bridgeImage from "../images/bridge-dark.png";
import iconPin from "../images/mm_20_blue.png";

import 'leaflet/dist/leaflet.css';

export default {
  data: () => ({
    layerOptions: [
      {
        text: "Precipitation",
        iconCss: "fa fa-check-square",
      },
      {
        text: "Wind Speed & Direction",
        iconCss: "far fa-square",
      },
      {
        text: "Humidity",
        iconCss: "far fa-square",
      },
      {
        text: "Dew Point",
        iconCss: "far fa-square",
      },
    ],
    map: null,
    mapLayerType: "precip",
    imageMap: {
      Other: roadImage,
      Unknown: roadImage,
      "Parking Lot": carsImage,
      Road: roadImage,
      Bridge: bridgeImage,
      Sidewalk: roadImage,
    },
    markers: [],

    animatedPrecipTimestamps: [],
    animatedPrecipLayers: [],
    animatedPrecipLayerIndex: 1,
    animatedPrecipTimestampIndex: 0,
    animatedPrecipTimer: null,

    animatedPrecipOverlayAction: "play",
    animatedPrecipSliderTicks: { placement: "After", largeStep: 1 },
    animatedPrecipSliderValue: 1,
    animatedPrecipSliderStep: 1,

    hoursIntoFuture: 1,
    zoomLevel: 9,
    minZoomLevelForOverlay: 12,

    // Animation timeouts
    animationInterval: 1000,

    // Layer interval
    mapType: null,
    layerInterval: null,
    layerFrame: [],

    newLat: 0,
    newLng: 0,
    selectedGroupTimezone: null,
    TimeZoneAbbr: "CT",
    locationCallResponse: null,
    maxLayer: 13,
    iconPin: iconPin,
    firstLocationRender: false,
  }),
  computed: {
    ...mapStores(useBaseStore, useGroupsStore, useLocationsStore),
    ...mapActions(useLocationsStore, {
      getStoreLocations: "getLocations",
    }),
    ...mapState(useLocationsStore, ["filteredLocations", "mostRecentTransmissions"]),
    // convert current timestamp timezone (GMT) to 
    overlayTimestamp() {
      const index = this.animatedPrecipSliderValue;
      const currentTime = this.animatedPrecipTimestamps[index];
      if (!currentTime) {
        return null;
      }

      let date = moment.utc(currentTime, "YYYYMMDDHHmm");
      date = this.selectedGroupTimezone ? date.tz(this.selectedGroupTimezone) : date.tz("America/Chicago");

      return date.format("MMM DD, YYYY h:mm A");
    }
  },
  mounted() {
    setTimeout(() => {
      this.handleShowMap();
    }, 1);
    // Using this to make sure we have the map loaded and locations before trying to draw them
    this.locationsStore.$subscribe((mutation, state) => {
      if (!this.firstLocationRender && this.map && mutation.type === "direct" && state.locations.length > 0) {
        this.drawLocations();
        this.firstLocationRender = true;
      }
    });


    window.onresize = () => {
      this.initializeMapView();
      this.drawLocations();
    }
  },
  created() {
    eventBus.$on("panMapToLocation", (sensor) => {
      if (this.map) {
        this.map.setCenter(new window.google.maps.LatLng(sensor.Latitude, sensor.Longitude), 13);
      }
    });

    eventBus.$on("updateMapByZonePreferences", (groupID, zone) => {
    });
  },
  beforeDestroy() {
    clearTimeout(this.animatedPrecipTimer);
    clearInterval(this.refreshTimer);
    clearInterval(this.animatedPrecipTimer);
    clearInterval(this.scrollTimer);
    eventBus.$off('panMapToLocation');
    eventBus.$off('updateMapByZonePreferences');
    eventBus.$off("mapIsInitialized");
    this.groupsStore.clearActiveAlerts()
    this.clearGroupMarkers();
    this.destroyMap();
  },
  methods: {
    handleShowMap() {
      this.initializeMapView()
      this.drawLocations();

      clearTimeout(this.showMapTimeout)
      this.showMapTimeout = setTimeout(() => {
        this.showMap = true;
      }, 100);
    },
    getMapPinColor(locationID) {
      const result = this.groupsStore.activeAlerts?.filter(function (alert) {
        return alert.DeviceID === locationID;
      });
      return result.length > 0 ? result[0].MapPinColor : '#595AFF'
    },
    iconLoad(hex, isMobile, location, mostRecent, map, markers, callback) {
      let icon = this.imageMap[location.Type];
      if (!icon) {
        icon = this.imageMap.Road;
      }

      let iconUrl = this.iconPin

      const tempUnits = location.GroupEmbedded.TemperatureUnits === "Celsius" ? "°C" : "°F"
      const metricUnits = location.GroupEmbedded.TemperatureUnits === "Celsius" ? true : false
      let airTemp = "";
      let surfaceTemp = "";
      let cv = '';
      let sd = '';
      if (mostRecent && mostRecent.MostRecentDataTransmission) {
        const { AirTemp, SurfaceTemp } = mostRecent?.MostRecentDataTransmission || {};
        if (AirTemp !== null && AirTemp !== undefined) {
          airTemp = 'Air: ' + AirTemp + tempUnits + '<br/>';

        }
        if (SurfaceTemp !== null && SurfaceTemp !== undefined) {
          surfaceTemp = 'Surf: ' + SurfaceTemp + tempUnits + '<br/>';
        }
      }
      if (mostRecent && mostRecent.MostRecentComputerVision) {
        const ComputerVision = mostRecent.MostRecentComputerVision;
        if (ComputerVision) {
          // TODO: Move parsing to store or utilitiy to make it easier to grab the values
        }
      }
      if (mostRecent && mostRecent.MostRecentSnowDepthReading) {
        const SnowDepthReading = mostRecent.MostRecentSnowDepthReading;
        if (SnowDepthReading) {
          const reading = metricUnits ? SnowDepthReading.DistanceMm : SnowDepthReading.DistanceInches;
          if (reading >= 0) {
            const units = metricUnits ? 'mm' : 'in';
            sd = 'Snow Depth: ' + reading + units + '<br/>';
          }
        }
      }

      const surfaceDetails = `<div style="height: auto; width: auto; text-align: center; ">
        <center>
          <img src=${icon} style="width: 50px; "/>
          <br/>
          <b>${location.Name}</b>
        </center>
        ${airTemp}
        ${surfaceTemp}
        ${cv}
        ${sd}
      </div>`
      callback(hex, isMobile, iconUrl, surfaceDetails, map, markers);

      return;
    },
    drawLocations(isRefresh) {
      this.clearGroupMarkers();
      const draw = () => {
        const bounds = new L.LatLngBounds();
        let isMobile = this.isMobile
        const that = this;
        this.filteredLocations.forEach((location) => {
          const latLng = {
            lat: location.Latitude,
            lng: location.Longitude,
          };
          if (latLng.lat !== undefined && latLng.lng !== undefined) {

            bounds.extend(latLng);

            const mostRecent = this.locationsStore.mostRecent(location.ID);

            const hex = this.getMapPinColor(location.ID)
            this.iconLoad(hex, isMobile, location, mostRecent, this.map, this.markers, function (hex, isMobile, iconUrl, surfaceDetails, map, markers) {
              const pinColor = hex ? hex : '#595AFF';
              const icon = L.divIcon({
                html: `
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.42 40.23">
                      <path d="M14.21,1C8.11,1,1,4.73,1,14.21c0,6.43,10.16,20.66,13.21,24.38C16.92,34.87,27.42,21,27.42,14.21,27.42,4.73,20.3,1,14.21,1Z" fill="${pinColor}" stroke="#000" stroke-miterlimit="10" stroke-width="2" />
                    </svg>
                  `,
                className: 'svg-icon',
                iconSize: [28, 40]
              });
              if (!map) {
                return;
              }
              const marker = L.marker(latLng, { icon }).addTo(map);
              marker.bindPopup(surfaceDetails, { offset: [0, -15], closeButton: false });

              marker.on('mouseover', () => {
                marker.openPopup();
              });

              marker.on('mouseout', () => {
                marker.closePopup();
              });

              marker.on('click', () => {
                if (isMobile) {
                  marker.openPopup();
                } else {
                  that.$router.push({ query: { sensorId: location.ID } })
                }
              });

              marker.on('dblclick', () => {
                that.$router.push({ query: { sensorId: location.ID } })
              });

              // marker.groupID = group.GroupID;
              // marker.zoneName = zone.ZoneName;

              markers.push(marker);
            });
          }
        });

        if (this.map && bounds.isValid() && this.filteredLocations.length !== 0 && !isRefresh) {
          this.map.fitBounds(bounds);
          if (this.map.getZoom() > this.minZoomLevelForOverlay) {
            this.map.setZoom(this.minZoomLevelForOverlay);
          }
        }
      };

      if (this.map) {
        draw();
      } else {
        eventBus.$on("mapIsInitialized", draw);
      }
    },
    layerSelected(data) {
      data.item.controlParent.items.forEach((l) => {
        Vue.set(l, "iconCss", "far fa-square");
      });

      if (data.item.text === "Precipitation") {
        if (this.mapLayerType === "precip") {
          this.mapLayerType = "";
        } else {
          this.mapLayerType = "precip";
          data.item.iconCss = "fa fa-check-square";
        }
      }

      if (data.item.text === "Wind Speed & Direction") {
        if (this.mapLayerType === "wind") {
          this.mapLayerType = "";
        } else {
          this.mapLayerType = "wind";
          data.item.iconCss = "fa fa-check-square";
        }
      }

      if (data.item.text === "Humidity") {
        if (this.mapLayerType === "relhum") {
          this.mapLayerType = "";
        } else {
          this.mapLayerType = "relhum";
          data.item.iconCss = "fa fa-check-square";
        }
      }

      if (data.item.text === "Dew Point") {
        if (this.mapLayerType === "dewpoint") {
          this.mapLayerType = "";
        } else {
          this.mapLayerType = "dewpoint";
          data.item.iconCss = "fa fa-check-square";
        }
      }

      if (!this.mapLayerType) {
        return;
      }
    },
    generateLayers() {
      clearTimeout(this.animatedPrecipTimer);
      this.clearGroupMarkers()

      this.layerFrame.splice(0, this.layerFrame.length);
      this.animatedPrecipOverlayAction = "pause";

      // this.createWeather();
      this.startAnimation();
    },
    startAnimation() {
      clearTimeout(this.animatedPrecipTimer);
      this.animatedPrecipTimer = setTimeout(
        this.createAnimation,
        this.animationInterval
      );
    },
    toggleAnimation() {
      let self = this;
      if (self.animatedPrecipOverlayAction === "play") {
        self.animatedPrecipOverlayAction = "pause";

        this.startAnimation();

      } else if (self.animatedPrecipOverlayAction === "pause") {
        // Stop animation
        self.animatedPrecipOverlayAction = "play";
        clearTimeout(self.animatedPrecipTimer);
      }
    },
    async createWeather() {
      // get all radar layers
      return await fetch('https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows?service=wms&version=1.3.0&request=GetCapabilities')
        .then(res => res.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(xml => {
          // grab the latest 14 available timestamps from the XML
          const times = xml.getElementsByTagName('Dimension')[0].textContent.split(',').slice(-14);
          this.animatedPrecipTimestamps = times;

          // create 2 layers used to animate between them
          const url = 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows?';
          const args = {
            layers: 'conus_bref_qcd',
            transparent: true,
            format: 'image/png',
            opacity: 0.5,
            version: '1.3.0',
            zIndex: 3,
          }

          const wms1 = L.tileLayer.wms(url, Object.assign({}, args, {
            time: times[0],
            className: `noaa-radar-layer noaa-radar-layer-1`
          })).addTo(this.map);

          const wms2 = L.tileLayer.wms(url, Object.assign({}, args, {
            time: times[1],
            className: `noaa-radar-layer noaa-radar-layer-2`
          })).addTo(this.map);

          this.animatedPrecipLayers.push(wms1, wms2);
        });
    },
    createAnimation() {
      // the animateion steps consists of 2 layers
      // prevLayer = currently shown layer as part of the animation process
      // nextLayer = hidden layer used to preload the images as part of the next step of the animation process
      const prevLayer = this.animatedPrecipLayerIndex === 0 ? this.animatedPrecipLayers[0] : this.animatedPrecipLayers[1];
      const nextLayer = this.animatedPrecipLayerIndex === 0 ? this.animatedPrecipLayers[1] : this.animatedPrecipLayers[0];
      this.animatedPrecipLayerIndex = this.animatedPrecipLayerIndex === 0 ? 1 : 0;

      // show the next layer
      if (prevLayer) {
        prevLayer.setOpacity(0);
      }
      if (nextLayer) {
        nextLayer.setOpacity(.5);
      }

      // update the slider step
      this.animatedPrecipSliderValue = this.animatedPrecipTimestampIndex;

      // start time setup for next animation step
      this.animatedPrecipTimestampIndex++;

      // we reached the end, loop back to the first timestamp
      if (!this.animatedPrecipTimestamps[this.animatedPrecipTimestampIndex]) {
        this.animatedPrecipTimestampIndex = 0;
      }

      // figure out what timestamp to load next
      let nextTimeIndex = this.animatedPrecipTimestampIndex + 1
      if (!this.animatedPrecipTimestamps[nextTimeIndex]) {
        nextTimeIndex = 0;
      }

      prevLayer.setParams({ time: this.animatedPrecipTimestamps[nextTimeIndex] });

      this.animatedPrecipTimer = setTimeout(
        () => this.createAnimation(),
        this.animationInterval
      );
    },
    async initializeMapView() {
      let initMap = async () => {
        let map = null;
        if (this.map) {
          map = this.map;
        } else {
          const appDashboardMap = document.getElementById("app-dashboard-map");
          if (!appDashboardMap) {
            return;
          }

          map = L.map('app-dashboard-map', {
            center: [Number(process.env.VUE_APP_LAT), Number(process.env.VUE_APP_LNG)],
            zoom: this.zoomLevel,
            zoomControl: false, // disable left zoom buttons (we are readding them below)
            minZoom: 3,
            attributionControl: false,
            fadeAnimation: false
          });

          this.map = map;

          // Add the Google Maps tile layer
          const googleStreetsLayer = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          }).addTo(map);

          const googleSatelliteLayer = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          }).addTo(map);

          this.createWeather();

          L.control.zoom({ position: 'bottomright' }).addTo(map);

          L.control.layers({
            'Map': googleStreetsLayer,
            // 'Hybrid': googleHybridLayer,
            'Satellite': googleSatelliteLayer,
            // 'Terrain': googleTerrainLayer,
          }, {}, {
            position: 'topleft',
            collapsed: false
          })
            .addTo(map);
        }

        this.map.on('zoomend', (e) => {
          this.zoomLevel = map.getZoom();
        });

        // Initially we need to load layers for our range slider
        let self = this
        self.generateLayers();
        eventBus.$emit("mapIsInitialized");

      };

      if (!window.google) {
        window.mapScript = document.createElement("script");
        window.mapScript.src =
          "https://maps.google.com/maps/api/js?v=3.58&key=" +
          process.env.VUE_APP_GOOGLE_MAP_API_KEY +
          "&amp;libraries=places,geometry,drawing";
        document.body.appendChild(window.mapScript);
        window.mapScript.addEventListener("load", () => {
          initMap();
        });
      } else {
        initMap();
      }
    },
    clearGroupMarkers() {
      this.markers.forEach((marker) => {
        marker.remove();
      });
      this.markers = [];
    },
    destroyMap() {
      if (!this.map) {
        return
      }
      this.map.remove();
      this.map = null;
    }
  },
  watch: {
    map: function () {
      if (this.filteredLocations.length > 0) {
        this.drawLocations();
      }
    },
    'filteredLocations': {
      // If we update locations, we need to redraw them
      handler: function () {
        this.drawLocations();
      },
    },
    'mostRecentTransmissions': {
      // If we update locations, we need to redraw them
      handler: function () {
        this.drawLocations();
      },
    },
  },
}
</script>
