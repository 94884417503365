<template>
    <div class="dm-page-container dm-flex-column dm-height-100">
        <dm-table ref="grdLocations" :table="{
            grdTableItems: grdLocationsItems,
            grdTableRowOnClick: grdLocationsRowOnClick,
            grdTableActionOnClick: grdLocationsActionOnClick,
            grdTableSortBy: grdLocationsSortBy,
            grdTableFields: grdLocationsFields,
        }" :allowPaging="true" />
    </div>

</template>

<script>
import Vue from 'vue'
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import { useBaseStore, useModalStore } from '../../js/store';

export default {
    name: 'locations-page',
    data: function () {
        return {
            searchParams: {},
            grdLocationsFields: [],
            grdLocationsItems: [],
            grdLocationsSortBy: {
                columns: [
                    { field: 'Name', direction: 'Ascending' },
                ]
            },
            grdLocationsClass: 'dm-bg-color-black',
            searchLocationType: '',
            locationTypeOptions: [],
            searchText: '',
            grdLocationsActions: [
                {
                    text: 'Edit'
                },
            ],
            selectedRow: null,
            includeInactive: false,
            locations: [],

        }
    },
    computed: {
        ...mapStores(useBaseStore, useModalStore),
    },
    methods: {
        getLocations(params) {
            DM.http({
                method: 'GET',
                url: '/locations',
                params: params
            }).then(response => {
                this.locations = response;
                this.grdLocationsItems = response;
            });
        },
        grdLocationsRowOnClick(args) {
            this.selectedRow = args;
        },
        addLocationOnClick() {
            eventBus.$emit('showAppModal', this, 'locationsEditModal', {
                mode: "new",
                location: {},
                locationTypeOptions: this.locationTypeOptions,
                callback: () => this.getLocations(this.searchParams)
            });
        },
        openEditLocationModal(id) {
            DM.http({
                method: 'GET',
                url: `/devices/${id}`,
            }).then(response => {
                delete response.DataTransmissions;

                eventBus.$emit('showAppModal', this, 'locationsEditModal', {
                    mode: "edit",
                    location: response,
                    callback: () => this.getLocations(this.searchParams)
                });
            });
        },
        grdLocationsActionOnClick(args) {
            if (args.item.text === 'Edit') {
                this.openEditLocationModal(this.selectedRow.rowData.ID);
            }

            if (args.item.text === 'File Attachments') {
                event('location_attachment_click', {
                    'edit_location_id': this.selectedRow.rowData.ID
                });

                DM.http({
                    method: 'GET',
                    url: `/locations/${this.selectedRow.rowData.ID}/attachments`
                }).then(response => {
                    eventBus.$emit('showAppModal', this, 'fileAttachmentsModal', {
                        mode: "edit",
                        location: this.selectedRow.rowData,
                        files: response, //[{FileName: 'file.png', Url: '/attachments/file.png'}],
                        callback: () => { }
                    });
                });
            }

            if (args.item.text === 'Delete') {
            }
        },
        deleteLocation(locationId) {
            DM.http({
                method: 'POST',
                url: '/Sensors/Delete',
                data: {
                    LocationID: locationId,
                }
            }).then(response => {
                this.getLocations();
            });
        },
        defineColumns() {
            let self = this;

            if (window.innerWidth < 666) {
                this.grdLocationsFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                            :items="grdLocationsActions"
                                            :select="select"
                                            iconCss="e-icons e-ellipsis-vert"
                                            cssClass= "e-caret-hide"
                                        />`,
                                    data: function () {
                                        return {
                                            grdLocationsActions: self.grdLocationsActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdLocationsActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: 'Name',
                        label: 'Name'
                    },
                    {
                        key: "IsActive",
                        label: "Active",
                        formatter: (field, data) => {
                            return data.IsActive ? 'Y' : 'N';
                        },
                        width: '70px',
                        textAlign: 'Center',
                    },
                ];
            } else {
                this.grdLocationsFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                                        :items="grdLocationsActions"
                                                        :select="select"
                                                        iconCss="e-icons e-ellipsis-vert"
                                                        cssClass= "e-caret-hide"
                                                    />`,
                                    data: function () {
                                        return {
                                            grdLocationsActions: self.grdLocationsActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdLocationsActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: 'Name',
                        label: 'Name'
                    },
                    {
                        key: 'GroupEmbedded.Name',
                        label: 'Group'
                    },
                    {
                        key: 'SensorSerialNumber',
                        label: 'Sensor Serial Number'
                    },
                    {
                        key: 'FiPySerialNumber',
                        label: 'FiPy Serial Number'
                    },
                    {
                        key: 'Type',
                        label: 'Type',
                    },
                    {
                        key: "IsActive",
                        label: "Active",
                        formatter: (field, data) => {
                            return data.IsActive ? 'Y' : 'N';
                        },
                        width: '70px',
                        textAlign: 'Center',
                    },
                ];
            }
        },
    },
    created() {
        this.getLocations({ includeInactive: false });

        this.defineColumns();

        eventBus.$on('openAddLocation', this.addLocationOnClick);

        eventBus.$on('searchItems', (params) => {
            this.searchParams = params;
            this.getLocations(params);
        });


    },
    mounted() {
        let defineColumns;
        window.onresize = () => {
            clearTimeout(defineColumns);
            defineColumns = setTimeout(this.defineColumns, 100);
        };


    },
    beforeDestroy() {
        eventBus.$off('openAddLocation', this.addLocationOnClick);
        eventBus.$off('searchItems', (params) => {
            this.searchParams = params;
            this.getLocations(params);
        });
    },
    watch: {
        locations: function () {
            const { id, devicetype, producttype } = this.$route.query;
            if ((!id && !devicetype) || (!id && !producttype)) {
                return;
            }
            // Try to find the device and open the edit device modal
            const device = this.locations.find(d => d.SensorSerialNumber === id);
            if (device) {
                this.openEditLocationModal(device.ID);
            } else {
                this.modalStore.openModal("addDevice", {
                    title: "Add Device",
                    id,
                    devicetype: devicetype || producttype,
                });
            }
            // Remove the query params
            this.$router.replace({ query: {} });
        }
    },
}
</script>
